import { defineStore } from 'pinia'
import { scrollToElement } from 'utils'
import useStarsRatingStore from 'components/common/ReviewPage/stores/useStarsRatingStore'
import useReviewPageDataStore from 'components/common/ReviewPage/stores/useReviewPageDataStore'

/**
 * Содержит данные для работы со Stepper
 * */

const useSteppersStore = defineStore('steppersStore', {
  state: () => ({
    currentStep: 1,
    maxSteps: 2,
  }),
  actions: {
    slideNext() {
      if (this.currentStep >= this.maxSteps) {
        return
      }

      this.currentStep++
    },
    slidePrev() {
      if (this.currentStep <= 1) {
        return
      }

      this.currentStep--
    },
    slidePrevWithScroll() {
      this.slidePrev()

      const reviewPageDataStore = useReviewPageDataStore()

      setTimeout(() => {
        scrollToElement(reviewPageDataStore.reviewPageNode)
      })
    },
    slideNextWithScroll() {
      this.slideNext()

      const reviewPageDataStore = useReviewPageDataStore()

      setTimeout(() => {
        scrollToElement(reviewPageDataStore.reviewPageNode)
      })
    },
    async slideNextWithValidationAndScroll() {
      const starsRatingStore = useStarsRatingStore()

      starsRatingStore.validateStarsRating()

      if (starsRatingStore.isValidStarsRating) {
        this.slideNextWithScroll()
      } else {
        scrollToElement(starsRatingStore.invalidStarRatingRef.$el)
      }
    },
  },
})

export default useSteppersStore
