var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "review-page-layout",
      attrs: { "data-qa": "review_page_layout" },
    },
    [
      _c(
        "div",
        {
          staticClass: "review-page-layout__main",
          attrs: { "data-qa": "review_page_layout_main" },
        },
        [_c("VCard", { attrs: { elevation: "1" } }, [_vm._t("main")], 2)],
        1
      ),
      _vm._v(" "),
      _vm.$slots.aside
        ? _c(
            "div",
            {
              staticClass: "review-page-layout__aside",
              attrs: { "data-qa": "review_page_layout_aside" },
            },
            [_c("VCard", { attrs: { elevation: "1" } }, [_vm._t("aside")], 2)],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }