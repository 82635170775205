<template>
  <component
    :is="datePickerMenuComponent"
    v-model="isVisibleDatePicker"
    color="primary"
    z-index="200"
    max-width="300"
    nudge-bottom="56"
    :retain-focus="false"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <div :data-qa="dataQa">
        <VTextField
          :value="selectedDateFormatted"
          class="date-picker-month-field"
          readonly
          outlined
          :data-qa="`${dataQa}_input`"
          append-icon="ui-icon-calendar-none"
          :persistent-placeholder="isVisibleDatePicker"
          v-bind="{ ...attrs, ...textFieldProps }"
          v-on="on"
          @keydown.enter="handleOpenDatePicker($event, on)"
          @click.native="handleOpenDatePicker($event, on)"
        />
      </div>
    </template>
    <VDatePicker
      :value="selectedDate"
      no-title
      full-width
      scrollable
      type="month"
      color="primary"
      :data-qa="`${dataQa}_picker`"
      v-bind="datePickerProps"
      @input="handleSelectedDate"
    />
  </component>
</template>

<script>
import { VDialog, VMenu } from 'vuetify/lib'
import { ru } from 'date-fns/locale'
import { format, parseISO } from 'date-fns'
import { getFirstLetterUpper } from 'utils'

export default {
  name: 'DatePickerMonth',
  model: {
    prop: 'selectedDate',
    event: 'date-picker:change',
  },
  props: {
    useDialog: {
      type: Boolean,
      default: false,
    },
    selectedDate: {
      type: String,
      default: null,
    },
    textFieldProps: {
      type: Object,
      default: () => ({}),
    },
    datePickerProps: {
      type: Object,
      default: () => ({}),
    },
    dataQa: {
      type: String,
      default: 'date_picker_month',
    },
  },
  data: () => ({
    isVisibleDatePicker: false,
  }),
  computed: {
    datePickerMenuComponent() {
      return this.useDialog ? VDialog : VMenu
    },
    selectedDateFormatted() {
      if (!this.selectedDate) {
        return this.selectedDate
      }

      const parsedDate = parseISO(this.selectedDate)
      const formattedDate = format(parsedDate, 'LLLL yyyy', { locale: ru })

      return getFirstLetterUpper(formattedDate)
    },
  },
  methods: {
    /**
         * Вызывает обработчик `on.click` при клике на всё поле <VTextField>,
         * а не на его отдельный элемент <input>
         * */
    handleOpenDatePicker(event, on) {
      // Если произвели клик на <label for="...">, то ничего не делаем,
      // т.к. следующим за ним произойдёт событие клика на <input id="...">
      if (event.target.closest('.v-label')) {
        return
      }

      if (event.target.closest('.v-input__slot')) {
        on.click(event)
      }
    },
    handleSelectedDate(date) {
      this.$emit('date-picker:change', date)

      this.isVisibleDatePicker = false
    },
  },
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/variables';

.date-picker-month-field .v-input__slot {
  cursor: pointer !important;
}

.v-date-picker-table button {
  text-transform: uppercase;
}

.v-date-picker-header__value button {
  font-weight: $weight-medium !important;
}
</style>
