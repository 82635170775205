import { getElementOffsetTop, scrollTo } from 'utils'

/**
 * Содержит высоту, на которую будет произведено смещение от нижней части textarea.
 * Значение было подобрано опытным путем, таким образом, чтобы помещался пустой textarea.
 * */
const OFFSET_VISIBLE_AREA = 124
const OFFSET_VISIBLE_AREA_BIG = 268

/**
 * @description Производит скролл к textarea таким образом,
 * чтобы тултип с прогрессом заполнения отзыва был виден на экране.
 *
 * (Учитывается экранная клавиатура и размер textarea в зависимости от кол-ва текста)
 *
 * @param { HTMLTextAreaElement } textareaElement - textarea, к которому будет произведён скролл
 * @param { Boolean } isTextareaBig - является ли textarea увеличенным
 * */

function scrollToTextarea({ textareaElement, isTextareaBig }) {
  const offsetBottom = getElementOffsetTop(textareaElement) + textareaElement.offsetHeight
  const offsetVisibleArea = isTextareaBig ? OFFSET_VISIBLE_AREA_BIG : OFFSET_VISIBLE_AREA

  scrollTo(offsetBottom - offsetVisibleArea, {
    duration: 500,
  })
}

export default scrollToTextarea
