<template>
  <div>
    <div :class="isMobile ? 'px-2' : ''">
      <div
        v-for="({ title, text }, index) in entries"
        :key="`${title}-${index}`"
        data-qa="review_modal_personal_history_item"
      >
        <div
          class="ui-text ui-text_subtitle-1 ui-kit-color-text mt-4 mb-2"
          v-text="title"
        />
        <div
          class="ui-text ui-body-1 ui-kit-color-text"
          v-text="text"
        />
      </div>
    </div>
    <VDivider
      v-if="!isLastEntry"
      class="mt-4"
    />
  </div>
</template>

<script>
export default {
  name: 'ReviewPersonalHistoryItem',
  props: {
    entry: {
      type: Object,
      required: true,
    },
    isLastEntry: {
      type: Boolean,
      default: false,
    },
  },
  data: vm => ({
    isMobile: window.MOBILE_VERSION,
    entries: Object.values(vm.entry).filter(({ text }) => text),
  }),
}
</script>
