var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VBottomSheet",
    {
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "div",
                { attrs: { "data-qa": _vm.dataQa } },
                [
                  _c(
                    "VSelect",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            outlined: "",
                            "append-icon": "ui-icon-arrow-down",
                            disabled: _vm.disabled,
                            value: _vm.selectedItem,
                            items: _vm.items,
                            "menu-props": {
                              offsetY: true,
                              maxWidth: 472,
                              maxHeight: 480,
                              disabled: _vm.useBottomSheet,
                              ..._vm.selectProps.menuProps,
                            },
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$emit("select:change", $event)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              !_vm.useBottomSheet
                                ? {
                                    key: "item",
                                    fn: function ({
                                      item,
                                      attrs: itemAttrs,
                                      on: itemOn,
                                    }) {
                                      return [
                                        _c(
                                          "VListItem",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                style: { minHeight: "48px" },
                                                attrs: {
                                                  "data-qa": `${_vm.dataQa}_item`,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clickListItem(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              "VListItem",
                                              itemAttrs,
                                              false
                                            ),
                                            itemOn
                                          ),
                                          [
                                            _c(
                                              "VListItemContent",
                                              [
                                                _c(
                                                  "VListItemTitle",
                                                  {
                                                    staticClass:
                                                      "ui-text ui-text_body-1",
                                                    attrs: {
                                                      "data-qa": `${_vm.dataQa}_item_title`,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          item[_vm.itemText]
                                                        ) +
                                                        "\n              "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                item[_vm.itemTextSecondary]
                                                  ? _c(
                                                      "VListItemSubtitle",
                                                      {
                                                        staticClass:
                                                          "ui-text ui-text_body-2",
                                                        attrs: {
                                                          "data-qa": `${_vm.dataQa}_item_subtitle`,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                " +
                                                            _vm._s(
                                                              item[
                                                                _vm
                                                                  .itemTextSecondary
                                                              ]
                                                            ) +
                                                            "\n              "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  }
                                : null,
                              _vm.textSecondaryInPreview
                                ? {
                                    key: "selection",
                                    fn: function () {
                                      return [
                                        _c("div", { staticClass: "w-100" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "ui-text ui-text_body-1 ui-text_truncate",
                                            },
                                            [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm.selectedItemObject[
                                                      _vm.itemText
                                                    ]
                                                  ) +
                                                  "\n            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.selectedItemObject[
                                            _vm.itemTextSecondary
                                          ]
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ui-text ui-text_body-2 ui-kit-color-icon-secondary",
                                                  style: {
                                                    whiteSpace: "initial",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n              " +
                                                      _vm._s(
                                                        _vm.selectedItemObject[
                                                          _vm.itemTextSecondary
                                                        ]
                                                      ) +
                                                      "\n            "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  }
                                : null,
                            ],
                            null,
                            true
                          ),
                        },
                        "VSelect",
                        { ...attrs, ..._vm.$attrs, ..._vm.selectProps },
                        false
                      ),
                      _vm.useBottomSheet ? on : null
                    )
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isVisibleBottomSheet,
        callback: function ($$v) {
          _vm.isVisibleBottomSheet = $$v
        },
        expression: "isVisibleBottomSheet",
      },
    },
    [
      _vm._v(" "),
      _vm.useBottomSheet
        ? _c(
            "VList",
            _vm._b(
              {
                staticClass: "overflow-y-auto",
                attrs: { "data-qa": `${_vm.dataQa}_list` },
              },
              "VList",
              {
                maxHeight: 480,
                ..._vm.bottomSheetListProps,
              },
              false
            ),
            _vm._l(_vm.items, function (item, index) {
              return _c(
                "VListItem",
                {
                  key: index,
                  style: !_vm.wideItems
                    ? { maxHeight: "48px" }
                    : { minHeight: "56px" },
                  attrs: { "data-qa": `${_vm.dataQa}_item` },
                  on: {
                    click: function ($event) {
                      return _vm.clickListItem(item)
                    },
                  },
                },
                [
                  _c(
                    "VListItemContent",
                    [
                      _c(
                        "VListItemTitle",
                        {
                          staticClass: "ui-text ui-text_body-1",
                          attrs: { "data-qa": `${_vm.dataQa}_item_title` },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(item[_vm.itemText]) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      item[_vm.itemTextSecondary]
                        ? _c(
                            "VListItemSubtitle",
                            {
                              staticClass: "ui-text ui-text_body-2",
                              style: { whiteSpace: "initial" },
                              attrs: {
                                "data-qa": `${_vm.dataQa}_item_subtitle`,
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item[_vm.itemTextSecondary]) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "VListItemIcon",
                    { staticClass: "align-self-auto" },
                    [
                      !_vm.checkIcon
                        ? _c("VIcon", [
                            _vm._v("\n          ui-icon-arrow-right\n        "),
                          ])
                        : item[_vm.itemValue] === _vm.selectedItem
                        ? _c("VIcon", { attrs: { color: "primary" } }, [
                            _vm._v("\n          ui-icon-check\n        "),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }