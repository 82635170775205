import {
  getLpuData,
  getDoctorData,
  getCommonData,
  getDraftData,
  getInitUserData,
  getReviewObjectTypes,
  getImagesOptions,
} from 'components/common/ReviewPage/interfaces'

/**
 * Входные данные главного компонента
 * */

const ReviewPageContextProps = {
  props: {
    lpuData: {
      type: Object,
      default: getLpuData,
    },
    doctorData: {
      type: Object,
      default: getDoctorData,
    },
    commonData: {
      type: Object,
      default: getCommonData,
    },
    draftData: {
      type: Object,
      default: getDraftData,
    },
    initUserData: {
      type: Object,
      default: getInitUserData,
    },
    reviewObjectTypes: {
      type: Object,
      default: getReviewObjectTypes,
    },
    reviewImagesOptions: {
      type: Object,
      default: getImagesOptions,
    },
  },
}

export default ReviewPageContextProps
