<template>
  <VStepper
    v-model="currentStep"
    data-qa="review_stepper"
  >
    <div
      v-if="$slots['stepper-intro']"
      class="mx-auto"
      data-qa="review_stepper_intro"
      :style="{ maxWidth: isMobile ? '' : '472px' }"
    >
      <slot name="stepper-intro" />
    </div>
    <VStepperItems>
      <VStepperContent
        v-for="index in maxSteps"
        :key="index"
        :step="index"
        :data-qa="`review_stepper_main_step_${index}`"
      >
        <div
          class="mx-auto mt-4 mb-10"
          data-qa="review_stepper_content"
          :style="{ maxWidth: isMobile ? '' : '472px' }"
          :class="{ 'px-2': isMobile }"
        >
          <slot :name="`step-${index}`" />

          <div
            v-if="currentStep < 3 && isDraftExisting"
            key="draftSaved"
            class="ui-text ui-text_body-2 ui-kit-color-text-info text-right mt-4"
            data-qa="review_stepper_draft_saved_text"
          >
            Черновик сохранён {{ dateModified }} в {{ timeModified }}
          </div>
        </div>
      </VStepperContent>
    </VStepperItems>
    <BottomPanel
      v-show="isBottomPanelVisible"
      :fixed="isBottomPanelFixed"
      data-qa="review_stepper_bottom_panel"
    >
      <template #container>
        <VStepperItems>
          <VStepperContent
            v-for="index in maxSteps"
            :key="index"
            :step="index"
            :data-qa="`review_stepper_footer_step_${index}`"
          >
            <div
              class="d-flex px-2 pt-2 mx-n2"
              data-qa="review_stepper_actions"
              :class="{
                'justify-content-end pb-2': !isMobile,
                'justify-content-between pb-6': isMobile,
              }"
            >
              <VBtn
                v-if="index !== 1"
                text
                depressed
                color="uiKitText"
                class="flex-shrink-1 mx-2"
                data-qa="review_stepper_button_prev"
                :width="isMobile ? '100%' : 144"
                @click="slidePrevWithScroll"
              >
                Назад
              </VBtn>
              <VBtn
                v-if="index !== maxSteps"
                depressed
                color="primary"
                class="flex-shrink-1 mx-2"
                data-qa="review_stepper_button_next"
                :width="isMobile ? '100%' : 144"
                @click="slideNextComputed"
              >
                Далее
              </VBtn>
            </div>
          </VStepperContent>
        </VStepperItems>
      </template>
    </BottomPanel>
  </VStepper>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { PAGE_NEWRATE_YANDEX_GOALS } from 'yandexGoals'
import { sendYaGoal } from 'components/common/ReviewPage/functions'
import BottomPanel from 'components/common/core/BottomPanel/BottomPanel'
import {
  useSteppersStore,
  useUserDataStore,
  useDraftInfoStore,
  useReviewPageDataStore,
} from 'components/common/ReviewPage/stores'

export default {
  name: 'ReviewPageStepper',
  components: {
    BottomPanel,
  },
  data: () => ({
    isMobile: window.MOBILE_VERSION,
  }),
  computed: {
    ...mapState(useSteppersStore, [
      'maxSteps',
      'currentStep',
    ]),
    ...mapState(useReviewPageDataStore, [
      'reviewObjectTypes',
    ]),
    ...mapState(useDraftInfoStore, [
      'dateModified',
      'timeModified',
      'isDraftExisting',
    ]),
    ...mapState(useUserDataStore, [
      'userData',
    ]),
    isBottomPanelFixed() {
      return this.isMobile && this.currentStep === 1
    },
    isBottomPanelVisible() {
      return !this.isBottomPanelFixed || this.userData.isLogged
    },
  },
  watch: {
    currentStep(newValue) {
      if (newValue === 2) {
        sendYaGoal(PAGE_NEWRATE_YANDEX_GOALS[this.reviewObjectTypes.isLpu ? 'commonSeeTwoStepLpu' : 'commonSeeTwoStepDoctor'])

        return
      }

      if (newValue === 3) {
        sendYaGoal(PAGE_NEWRATE_YANDEX_GOALS[this.reviewObjectTypes.isLpu ? 'commonSeeThreeStepLpu' : 'commonSeeThreeStepDoctor'])
      }
    },
  },
  methods: {
    ...mapActions(useSteppersStore, [
      'slidePrevWithScroll',
      'slideNextWithValidationAndScroll',
    ]),
    slideNextComputed() {
      if (this.currentStep === 1) {
        this.slideNextWithValidationAndScroll()
      }
    },
  },
}
</script>
