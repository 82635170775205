<template>
  <VDialog
    v-model="isVisibleDialog"
    scrollable
    width="667"
    content-class="review-protection-program"
    :fullscreen="isMobile"
  >
    <template #activator="props">
      <slot
        name="activator"
        v-bind="props"
      />
    </template>
    <VCard
      tile
      data-qa="review_protection_modal"
    >
      <VCardTitle class="pl-1 pb-2">
        <VBtn
          icon
          width="32"
          height="32"
          class="mr-7"
          data-qa="review_protection_modal_close"
          @click="handleCloseDialog"
        >
          <span class="ui-icon-close-not-a-circle ui-text ui-kit-color-text" />
        </VBtn>
      </VCardTitle>
      <VCardText
        :class="isMobile ? 'p-2' : 'px-6 pb-11'"
      >
        <img
          width="208"
          height="169"
          class="d-block mb-8 mx-auto"
          alt="Логотип программы защиты"
          src="/static/_v1/pd/mascots/review-security.svg"
        >
        <div class="ui-text ui-text_h6 ui-kit-color-text text-center mb-8">
          Программа защиты авторов отзывов
        </div>
        <div
          v-for="(content, contentIndex) in PROTECTION_PROGRAM_CONTENTS"
          :key="`content-${contentIndex}`"
          class="review-protection-program__content"
        >
          <div class="ui-text ui-text_subtitle-1">
            {{ content.title }}
          </div>
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-for="(text, textIndex) in content.texts"
            :key="`text-${textIndex}`"
            class="ui-text ui-text_body-1"
            v-html="text"
          />
          <!-- eslint-enable vue/no-v-html -->
        </div>
        <div class="ui-text ui-text_subtitle-1 ui-kit-color-text mb-6">
          Ответы на важные вопросы
        </div>
        <VExpansionPanels
          multiple
          data-qa="review_protection_modal_expansion_panels"
        >
          <VExpansionPanel
            v-for="(content, index) in PROTECTION_PROGRAM_PANEL_CONTENTS"
            :key="index"
            data-qa="review_protection_modal_expansion_panel"
          >
            <VExpansionPanelHeader
              class="text-left justify-content-between"
              expand-icon="ui-icon-arrow-down"
            >
              <div class="ui-text ui-text_body-1 ui-kit-color-text">
                {{ content.title }}
              </div>
            </VExpansionPanelHeader>
            <VExpansionPanelContent>
              <div class="ui-text ui-text_body-1 ui-kit-color-text">
                {{ content.text }}
              </div>
            </VExpansionPanelContent>
          </VExpansionPanel>
        </VExpansionPanels>
      </VCardText>
      <BottomPanel>
        <template #container>
          <div class="p-2">
            <VBtn
              depressed
              color="primary"
              data-qa="review_protection_modal_button_back"
              :block="isMobile"
              :class="{ 'd-block ml-auto': !isMobile }"
              @click="handleCloseDialog"
            >
              Вернуться к отзыву
            </VBtn>
          </div>
        </template>
      </BottomPanel>
    </VCard>
  </VDialog>
</template>

<script>
import BottomPanel from 'components/common/core/BottomPanel/BottomPanel'

import {
  PROTECTION_PROGRAM_CONTENTS,
  PROTECTION_PROGRAM_PANEL_CONTENTS,
} from 'components/common/ReviewPage/constants'

export default {
  name: 'ReviewProtectionProgram',
  components: {
    BottomPanel,
  },
  data: () => ({
    isMobile: window.MOBILE_VERSION,
    isVisibleDialog: false,
    PROTECTION_PROGRAM_CONTENTS,
    PROTECTION_PROGRAM_PANEL_CONTENTS,
  }),
  methods: {
    handleCloseDialog() {
      this.isVisibleDialog = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.review-protection-program {
  &__content {
    color: $ui-kit-text;
    margin-bottom: 32px;

    & > *:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
</style>
