import { isUsedLocalStorage } from 'utils'
import UserStorage from 'modules/UserStorage'

/**
 * @author Быков Александр(@bykov)
 * */

class BrowserTab {
  constructor(args = {}) {
    /**
         * props - внутренне хранилище экземпляра.
         * Использовать в целях инкапсуляции
         * */
    this.props = {}
    this._createOptions(args)
    this._createPropUsedLocalStorage()

    if (this.options.useStorage && !isUsedLocalStorage()) {
      return
    }

    this._createVisibilityAPI()
    this.attach()

    const { options } = this

    this._createStorage()

    if (options.immediate) {
      const { hidden } = this.visibility
      const { storage } = this
      const method = document[hidden] ? 'hidden' : 'visible'

      options[method]({ data: storage.get() })
    }
  }

  /**
     * Внешние методы
     * */

  attach() {
    this._switchEventListeners(true)
  }

  detach() {
    this._switchEventListeners()
  }

  getInstanceStorage() {
    return this.storage
  }

  /**
     * Обработка событий
     * */

  _eventHidden = () => {
    const { options, storage } = this

    options.hidden({ data: storage.get() })
  }

  _eventVisible = () => {
    const { options, storage } = this

    /**
         * Добавление в историю и создание текущей
         * Должны производиться именно в этом методе(здесь добавление производится корректно)
         * */

    this._pushTabInHistory()
    this._createCurrentTabData()

    options.visible({ data: storage.get() })
  }

  _eventVisibility = e => {
    const v = 'visible'
    const h = 'hidden'
    const eventMap = {
      focus: v,
      focusin: v,
      pageshow: v,
      blur: h,
      focusout: h,
      pagehide: h,
    }
    const event = e || window.event
    const isVisible = event.type in eventMap ? eventMap[event.type] === v : !document[this.visibility.hidden]
    const method = isVisible ? '_eventVisible' : '_eventHidden'

    this[method]()
  }

  /**
     * Общие методы
     * */

  _switchEventListeners(add) {
    const { listener } = this.visibility

    // Для стандартных браузеров
    if (listener && listener !== 'onfocusin') {
      const action = add ? 'addEventListener' : 'removeEventListener'

      document[action](listener, this._eventVisibility)

      // Для IE 9 и ниже:
    } else if (listener === 'onfocusin') {
      const eventHandler = add
        ? this._eventVisibility
        : () => {}

      document.onfocusin = eventHandler
      document.onfocusout = eventHandler

      // Для всех остальных:
    } else {
      const eventHandler = add
        ? this._eventVisibility
        : () => {}

      window.onpageshow = eventHandler
      window.onpagehide = eventHandler
      window.onfocus = eventHandler
      window.onblur = eventHandler
    }
  }

  _createVisibilityAPI() {
    /**
         * Метод добавляет кроссбраузерную поддержку
         * Объект VisibilityAPI
         * */
    this.visibility = {
      hidden: 'hidden',
      listener: 'visibilitychange',
    }

    /**
         * В этом методе нужны проверки именно на undefined
         * Ибо true/false - используются как значения состояния
         * */
    if (document.hidden !== undefined) {
      return
    }

    /**
         * Присваиваю объекту visibility вендоные поля - свойство и слушатель. Они будут использованы в VisibilityAPI.
         * Это кроссбраузерное решение и VisibilityAPI работает лучше, чем сочетание focus/blur
         * */
    const { hidden, listener } = this.visibility
    const prefixes = ['ms', 'moz', 'webkit']
    const hiddenUpCase = hidden[0].toUpperCase() + hidden.slice(1)
    let isCreatedAPI = false

    // eslint-disable-next-line
        for (const prefix of prefixes) {
      const vendorHidden = prefix + hiddenUpCase

      if (document[vendorHidden] !== undefined) {
        this.visibility = {
          hidden: vendorHidden,
          listener: prefix + listener,
        }

        isCreatedAPI = true

        break
      }
    }

    if (!isCreatedAPI) {
      this.visibility.listener = 'onfocusin' in document ? 'onfocusin' : null
    }
  }

  _createOptions(options) {
    this.options = {
      key: 'BrowserTab',
      useStorage: true,
      historyLimit: 100,
      immediate: true,
      hidden: () => {},
      visible: () => {},
      afterCreate: () => {},
      ...options,
    }
  }

  _createPropUsedLocalStorage() {
    const { options } = this

    this.usedStorage = options.useStorage && isUsedLocalStorage()
  }

  /**
     * Работа с хранилищем
     * */

  _createStorage() {
    const { options } = this

    this.storage = new UserStorage({
      key: options.key,
    })

    if (!this.usedStorage) {
      return
    }

    const { storage } = this
    const storageData = storage.get()

    /**
         * Если пустое хранилище
         * Или если значение по ключу было определено ранее. Переопределяю
         * */
    if (!storageData || !storageData.history || !storageData.current) {
      this._createEmptyStorage()
    }

    this._createCurrentTabData()
  }

  _createEmptyStorage() {
    if (!this.usedStorage) {
      return
    }

    const { storage } = this

    storage.set({
      history: [],
      current: {},
    })
  }

  _createCurrentTabData() {
    if (!this.usedStorage) {
      return
    }

    const { options, storage } = this
    const storageData = storage.get()

    if (!storageData) {
      return
    }

    storageData.current = {
      url: window.location.href,
      start: Date.now(),
    }

    options.afterCreate(storageData)
    storage.set(storageData)
  }

  _pushTabInHistory() {
    if (!this.usedStorage) {
      return
    }

    const { options, storage } = this
    const storageData = storage.get()

    if (!storageData) {
      return
    }

    const { current, history } = storageData

    current.end = Date.now()
    current.difference = current.end - current.start

    history.push(current)

    if (history.length > options.historyLimit) {
      const difference = history.length - options.historyLimit

      history.splice(0, difference)
    }

    storage.set(storageData)
  }
}

export default BrowserTab
