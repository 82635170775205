/**
 * Обновляет позицию тултипа с прогрессом заполнения отзыва
 *
 * @param { Object } options
 * @param { Object } options.instances - объект c инстансами tippy (см.: createTippyFeedbackProgress.js)
 * @param { Number } options.timeoutMs - время в мс, в рамках которого будет регулярно
 * обновляться позиция тултипа (это может быть полезно при анимации, например если
 * какой-то элемент анимированно появляется и смещает позицию тултипа)
 * @param { Number } options.intervalMs - время в мс, которое определяет
 * периодичность обновления тултипа в рамках timeoutMs
 * */

const defaultOptions = { instances: {}, timeoutMs: 0, intervalMs: 50 }

function updateTippyPosition(options = defaultOptions) {
  const settings = { ...defaultOptions, ...options }
  const { instances, timeoutMs, intervalMs } = settings

  // Если тултип скрыт, то нет смысла обновлять его позицию
  if (!instances.singletonInstance.state.isVisible) {
    return
  }

  instances.singletonInstance.popperInstance.update()

  // Если задан timeoutMs, значит в рамках этого времени будет производиться регулярное обновление позиции
  if (timeoutMs) {
    const timerId = setInterval(() => {
      if (!instances.singletonInstance.popperInstance) {
        return
      }

      instances.singletonInstance.popperInstance.update()
    }, intervalMs)

    setTimeout(() => clearInterval(timerId), timeoutMs)
  }
}

export default updateTippyPosition
