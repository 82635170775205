import { format } from 'date-fns'
import { debounce } from 'utils'
import { defineStore } from 'pinia'
import UserStorage from 'modules/UserStorage'
import useReviewPageDataStore from 'components/common/ReviewPage/stores/useReviewPageDataStore'
import { STORAGE_KEY as DRAFT_REMINDER_STORAGE_KEY } from 'components/common/DraftReminder/constants'
import { DRAFT_SAVING_DEBOUNCE_DELAY_MS } from 'components/common/ReviewPage/constants'
import requestDraftSaving from 'components/common/ReviewPage/api/requestDraftSaving'
import buildRequestData from 'components/common/ReviewPage/functions/buildRequestData'

/**
 * Содержит информацию о черновике
 */

const useDraftInfoStore = defineStore('draftInfoStore', {
  state: () => ({
    dateModified: '',
    timeModified: '',
  }),
  getters: {
    urlSaveDraft() {
      const { reviewObjectType, reviewObjectId } = useReviewPageDataStore()

      return `/draft/detail/save/${reviewObjectType}/${reviewObjectId}/`
    },
    saveDraftDebounced() {
      return debounce(async () => {
        const requestData = buildRequestData()

        const { data: response } = await requestDraftSaving(this.urlSaveDraft, requestData)

        if (response === 'OK') {
          const nowDate = new Date()
          const {
            reviewObjectType,
            reviewObjectId,
            reviewObjectName,
            reviewObjectImageSrc,
          } = useReviewPageDataStore()

          this.dateModified = format(nowDate, 'dd.LL.yyyy')
          this.timeModified = format(nowDate, 'HH:mm')

          const storageData = {
            reviewObject: {
              type: reviewObjectType,
              name: reviewObjectName,
              id: reviewObjectId,
              avatarUrl: reviewObjectImageSrc,
            },
            createdAt: nowDate.getTime(),
            lastDisplayedAt: 0,
            displaysCount: 0,
          }

          UserStorage.set(DRAFT_REMINDER_STORAGE_KEY, storageData)
        }
      }, DRAFT_SAVING_DEBOUNCE_DELAY_MS)
    },
    isDraftExisting() {
      return Boolean(this.dateModified && this.timeModified)
    },
  },
})

export default useDraftInfoStore
