<template>
  <div
    class="review-info-card"
    data-qa="review_info_card"
    :class="{ 'review-info-card_mobile': isMobile }"
  >
    <img
      v-if="isVisibleImage"
      :alt="title"
      :src="imageSrc"
      :class="{
        'review-info-card__image': true,
        'review-info-card__image_square': reviewObjectTypes.isDoctor,
        'review-info-card__image_rectangular': reviewObjectTypes.isLpu,
        'review-info-card__image_outlined': reviewObjectTypes.isDoctor,
      }"
      data-qa="review_info_card_img"
    >
    <div>
      <div
        class="ui-text ui-text_h6 ui-kit-color-text mb-2"
        data-qa="review_info_card_title"
      >
        {{ title }}
      </div>
      <div
        class="ui-text ui-text_body-2 ui-kit-color-text-secondary"
        data-qa="review_info_card_subtitle"
      >
        {{ subtitle }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { getFirstLetterUpper } from 'utils'
import { useReviewPageDataStore } from 'components/common/ReviewPage/stores'

export default {
  name: 'ReviewPageInfoCard',
  data: () => ({
    isMobile: window.MOBILE_VERSION,
  }),
  computed: {
    ...mapState(useReviewPageDataStore, [
      'lpuData',
      'doctorData',
      'reviewObjectTypes',
    ]),
    title() {
      return this.reviewObjectTypes.isLpu
        ? this.lpuData.name
        : this.doctorData.fullName
    },
    subtitle() {
      return this.reviewObjectTypes.isLpu
        ? this.lpuData.speciality
        : getFirstLetterUpper(this.doctorData.specialtiesList.join(', '))
    },
    imageSrc() {
      return this.reviewObjectTypes.isLpu
        ? this.lpuData.imageSrc
        : this.doctorData.imageSrc
    },
    isVisibleImage() {
      // На мобилке у клиники не отображаем логотип
      return !(this.isMobile && this.reviewObjectTypes.isLpu)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.review-info-card {
  $block: &;

  &_mobile {
    display: flex;
    align-items: center;

    #{$block}__image {
      flex: 0 0 auto;
      margin-bottom: 0;
      margin-right: 16px;
    }

    #{$block}__image_square {
      width: 72px;
      height: 72px;
    }
  }

  &__image {
    overflow: hidden;
    margin-bottom: 16px;
    object-fit: scale-down;
    border-radius: $border-radius-sm;

    &_rectangular {
      max-width: 240px;
      height: 80px;
    }

    &_square {
      width: 88px;
      height: 88px;
    }

    &_outlined {
      border: 1px solid $ui-kit-bg-gray-60;
    }
  }
}
</style>
