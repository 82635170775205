var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VSheet",
    {
      directives: [
        {
          name: "ripple",
          rawName: "v-ripple.center",
          modifiers: { center: true },
        },
      ],
      staticClass: "file-uploader-activator",
      class: {
        "file-uploader-activator_focused": _vm.isFocused,
        "file-uploader-activator_disabled": _vm.isDisabled,
      },
      attrs: {
        width: _vm.width,
        height: _vm.height,
        "min-width": _vm.minWidth,
        "min-height": _vm.minHeight,
        "data-qa": "file_uploader_activator",
      },
      on: { click: _vm.handleClickActivator },
    },
    [
      _c("div", { staticClass: "ui-icon-plus file-uploader-activator__icon" }),
      _vm._v(" "),
      _vm.text
        ? _c("div", {
            staticClass: "ui-text ui-text_body-1 text-center mt-3",
            class: _vm.isDisabled
              ? "ui-kit-color-bg-gray-80"
              : "ui-kit-color-text-secondary",
            attrs: { "data-qa": "file_uploader_activator_text" },
            domProps: { textContent: _vm._s(_vm.text) },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }