var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VApp",
    {
      staticClass: "review-page",
      attrs: { id: "review-page-app-desktop", "data-qa": "review_page" },
    },
    [
      _c(
        "div",
        { staticClass: "b-container" },
        [
          _c(
            "h1",
            {
              staticClass: "ui-text ui-text_h5 ui-kit-color-text mb-6",
              attrs: { "data-qa": "review_page_title" },
            },
            [
              _vm._v(
                "\n      Оставить отзыв о " +
                  _vm._s(_vm.reviewObjectTypes.isLpu ? "клинике" : "враче") +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.commonData.isReviewOnModerateOrCanceled
            ? _c("ReviewPageLayout", {
                key: "keyErrorReviewExists",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "main",
                      fn: function () {
                        return [
                          _c("ReviewPageInfoScreen", {
                            attrs: {
                              title: "Вы уже оставили отзыв",
                              subtitle:
                                "Отслеживать статус проверки можно в личном кабинете — МедТочке.",
                              "confirm-button-text": "Посмотреть отзыв",
                              "close-button-text": "Назад",
                            },
                            on: {
                              "screen-info:click-button-confirm": function (
                                $event
                              ) {
                                return _vm.redirectToLink(
                                  _vm.commonData.medtochkaUrlOnReview,
                                  true
                                )
                              },
                              "screen-info:click-button-close": function (
                                $event
                              ) {
                                return _vm.redirectToLink(_vm.reviewObjectLink)
                              },
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1870966372
                ),
              })
            : !_vm.commonData.canSendReview
            ? _c("ReviewPageLayout", {
                key: "keyErrorStaff",
                scopedSlots: _vm._u([
                  {
                    key: "main",
                    fn: function () {
                      return [
                        _c("ReviewPageInfoScreen", {
                          attrs: {
                            title: "Отзыв нельзя оставить с рабочего аккаунта",
                            "confirm-button-text": "Понятно",
                          },
                          on: {
                            "screen-info:click-button-confirm": function (
                              $event
                            ) {
                              return _vm.redirectToLink(_vm.reviewObjectLink)
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              })
            : _vm.isVisibleReviewGetUserEmail
            ? _c("ReviewPageLayout", {
                key: "keyReviewGetUserEmail",
                scopedSlots: _vm._u([
                  {
                    key: "main",
                    fn: function () {
                      return [_c("ReviewGetUserEmail")]
                    },
                    proxy: true,
                  },
                ]),
              })
            : _vm.isVisibleReviewSubmitting
            ? _c("ReviewPageLayout", {
                key: "keyReviewSubmitting",
                scopedSlots: _vm._u([
                  {
                    key: "main",
                    fn: function () {
                      return [_c("ReviewSubmitting")]
                    },
                    proxy: true,
                  },
                ]),
              })
            : _c("ReviewPageLayout", {
                key: "keyReviewPage",
                scopedSlots: _vm._u([
                  {
                    key: "main",
                    fn: function () {
                      return [
                        _c("ReviewPageStepper", {
                          staticClass: "pt-6",
                          scopedSlots: _vm._u(
                            [
                              _vm.commonData.isPdMobile
                                ? {
                                    key: "stepper-intro",
                                    fn: function () {
                                      return [
                                        _c("ReviewPdMobileBanner", {
                                          staticClass: "mx-auto",
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  }
                                : null,
                              {
                                key: "step-1",
                                fn: function () {
                                  return [_c("ReviewStarsRating")]
                                },
                                proxy: true,
                              },
                              {
                                key: "step-2",
                                fn: function () {
                                  return [
                                    _c("ReviewForm", { staticClass: "mt-5" }),
                                    _vm._v(" "),
                                    _c("ReviewSectionImages"),
                                    _vm._v(" "),
                                    _c("ReviewPhoneConfirmation", {
                                      staticClass: "mt-8",
                                    }),
                                    _vm._v(" "),
                                    _c("ReviewModalPersonalHistory"),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "aside",
                    fn: function () {
                      return [_c("ReviewPageInfoCard", { staticClass: "p-8" })]
                    },
                    proxy: true,
                  },
                ]),
              }),
          _vm._v(" "),
          _vm.commonData.canSendReview
            ? _c("ReviewAuthorizationModal")
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }