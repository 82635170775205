var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column align-center justify-center mx-auto",
      style: {
        minHeight: _vm.isMobile ? "" : "664px",
        maxWidth: _vm.isMobile ? "" : "428px",
      },
      attrs: { "data-qa": "review_info_screen" },
    },
    [
      _c(
        "div",
        [
          _c("VImg", {
            staticClass: "mx-auto",
            attrs: {
              contain: "",
              width: "150",
              height: "150",
              alt: "Иконка ошибки",
              src: "/static/_v1/pd/pictures/ui-kit/cancel.svg",
              "data-qa": "review_info_screen_img",
            },
          }),
          _vm._v(" "),
          _vm.title
            ? _c(
                "div",
                {
                  staticClass:
                    "ui-text ui-text_h6 ui-kit-color-text text-center mt-2",
                  attrs: { "data-qa": "review_info_screen_title" },
                },
                [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.subtitle
            ? _c(
                "div",
                {
                  staticClass:
                    "ui-text ui-text_body-1 ui-kit-color-text-secondary text-center mt-2",
                  attrs: { "data-qa": "review_info_screen_subtitle" },
                },
                [_vm._v("\n      " + _vm._s(_vm.subtitle) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isMobile
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column align-center justify-center mt-6",
                  attrs: { "data-qa": "review_info_screen_actions" },
                },
                [
                  _vm.confirmButtonText
                    ? _c(
                        "VBtn",
                        {
                          attrs: {
                            depressed: "",
                            color: "primary",
                            "data-qa": "review_info_screen_btn_confirm",
                          },
                          on: { click: _vm.handleConfirmButtonClick },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.confirmButtonText) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.closeButtonText
                    ? _c(
                        "VBtn",
                        {
                          class: { "mt-2": _vm.confirmButtonText },
                          attrs: {
                            text: "",
                            depressed: "",
                            color: "primary",
                            "data-qa": "review_info_screen_btn_close",
                          },
                          on: { click: _vm.handleCloseButtonClick },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.closeButtonText) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isMobile
            ? _c("BottomPanel", {
                attrs: { fixed: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "container",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "px-2 pb-6",
                              attrs: {
                                "data-qa": "review_info_screen_actions",
                              },
                            },
                            [
                              _vm.confirmButtonText
                                ? _c(
                                    "VBtn",
                                    {
                                      attrs: {
                                        block: "",
                                        depressed: "",
                                        color: "primary",
                                        "data-qa":
                                          "review_info_screen_btn_confirm",
                                      },
                                      on: {
                                        click: _vm.handleConfirmButtonClick,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.confirmButtonText) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.closeButtonText
                                ? _c(
                                    "VBtn",
                                    {
                                      class: { "mt-2": _vm.confirmButtonText },
                                      attrs: {
                                        text: "",
                                        block: "",
                                        depressed: "",
                                        color: "primary",
                                        "data-qa":
                                          "review_info_screen_btn_close",
                                      },
                                      on: { click: _vm.handleCloseButtonClick },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.closeButtonText) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3454664302
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }