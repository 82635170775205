/**
 * @description
 *
 * Проверяет и возвращает булевое значение, если строка содержится в массиве
 *
 * @param { String } name - искомое
 * @param { Array<String> } includeFields - массив, который нужно проверить
 *
 * @return { Boolean }
 * */

const isIncludeField = (name, includeFields) => name && includeFields.includes(name)

export default isIncludeField
