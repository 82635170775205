import { getQueryParam } from 'utils'
import { format, parseISO } from 'date-fns'

/**
 * @description Возвращает дату посещения в виде строки на основе GET-параметра visitDate.
 * Дата должна быть не меньше текущей, иначе она считается некорректной
 *
 * @return { string } - Дата посещения в формате 'yyyy-MM',
 * либо пустая строка, если значение GET-параметра некорректно
 * */

function getVisitDate() {
  const visitDateFromUrl = getQueryParam('visitDate')
  const parsedVisitDate = parseISO(visitDateFromUrl)
  const todayDate = new Date()

  const isVisitDateCorrect = todayDate >= parsedVisitDate

  if (isVisitDateCorrect && visitDateFromUrl) {
    return format(parsedVisitDate, 'yyyy-MM')
  }

  return ''
}

export default getVisitDate
