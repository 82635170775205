import { axiosClient, getCSRFToken } from 'utils'

function getUserEmail() {
  return new Promise((resolve, reject) => {
    axiosClient({
      url: '/api/patient/user/',
      method: 'GET',
      headers: {
        'X-CSRFToken': getCSRFToken(),
      },
    })
      .then(response => resolve(response.data.email))
      .catch(({ response }) => reject(response))
  })
}

export default getUserEmail
