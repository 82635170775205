import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import AlertCookie from 'components/common/AlertCookie/AlertCookie'
import { MOUNT_ALERT_COOKIE_CLASS } from 'components/common/AlertCookie/constants'

function initAlertCookie() {
  const alertCookieNode = document.querySelector(`.${MOUNT_ALERT_COOKIE_CLASS}`)

  if (alertCookieNode) {
    new Vue({
      vuetify,
      el: alertCookieNode,
      render: h => h(AlertCookie),
    })
  }
}

export default initAlertCookie
