/**
 * enum FILE_EXTENSIONS = {
 *     png = '.png',
 *     svg = '.svg',
 *     ...
 * }
 *
 * type TFileExtensions = Array<FILE_EXTENSIONS>
 * */
const TFileExtensions = () => []

/**
 * type TValidationFunction = (file: File) => boolean
 *
 * type TValidationRule = {
 *     validation: TValidationFunction,
 *     validationError: IError,
 * }
 *
 * type TFileValidationRules = Array<TValidationRule>
 * */
const TFileValidationRules = () => []

/**
 * type TImageValidationFunction = (image: Image) => boolean
 *
 * type TImageValidationRule = {
 *     validation: TImageValidationFunction,
 *     validationError: IError,
 * }
 *
 * type TImageValidationRules = Array<TImageValidationRule>
 * */
const TImageValidationRules = () => []

export {
  TFileExtensions,
  TFileValidationRules,
  TImageValidationRules,
}
