var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "get-user-email", attrs: { "data-qa": "get_user_email" } },
    [
      _c(
        "VForm",
        {
          ref: "email-form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.handleSubmitEmail.apply(null, arguments)
            },
          },
          model: {
            value: _vm.emailField.isValid,
            callback: function ($$v) {
              _vm.$set(_vm.emailField, "isValid", $$v)
            },
            expression: "emailField.isValid",
          },
        },
        [
          _c(
            "div",
            { staticClass: "get-user-email__field" },
            [
              _c("VTextField", {
                ref: "email-field-input",
                attrs: {
                  outlined: "",
                  label: "Email",
                  "data-qa": "get_user_email_email_input",
                  loading: _vm.emailField.isLoading,
                  rules: _vm.emailField.rules,
                  "error-messages": _vm.emailField.errorMessage,
                  disabled: _vm.codeField.isVisible || _vm.emailField.isLoading,
                },
                on: {
                  input: _vm.handleInputEmail,
                  focus: _vm.handleFocusEmail,
                  blur: _vm.handleBlurEmail,
                },
                model: {
                  value: _vm.emailField.inputValue,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.emailField,
                      "inputValue",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "emailField.inputValue",
                },
              }),
              _vm._v(" "),
              _vm.codeField.isVisible && _vm.emailField.isEditIconVisible
                ? _c("div", {
                    staticClass: "get-user-email__field-append ui-icon-pen",
                    attrs: { "data-qa": "get_user_email_edit_email_btn" },
                    on: { click: _vm.handleClickEditEmail },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "VForm",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "VExpandTransition",
            [
              _vm.codeField.isVisible
                ? _c("VTextField", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: _vm.codeField.mask,
                        expression: "codeField.mask",
                      },
                    ],
                    attrs: {
                      outlined: "",
                      label: "Код из письма",
                      "data-qa": "get_user_email_code_input",
                      disabled: _vm.codeField.isLoading,
                      loading: _vm.codeField.isLoading,
                      "error-messages": _vm.codeField.errorMessage,
                    },
                    nativeOn: {
                      input: function ($event) {
                        return _vm.handleInputCode.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.codeField.inputValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.codeField, "inputValue", $$v)
                      },
                      expression: "codeField.inputValue",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.confirmButtonState.isHidden &&
      !_vm.confirmButtonState.isAttemptsExceeded
        ? _c(
            "VBtn",
            _vm._b(
              {
                staticClass: "mb-4",
                attrs: {
                  block: "",
                  depressed: "",
                  color: "primary",
                  "data-qa": "get_user_email_confirmation_btn",
                  loading: _vm.emailField.isLoading,
                },
                on: { click: _vm.handleSubmitEmail },
              },
              "VBtn",
              { ..._vm.confirmButtonState },
              false
            ),
            [
              _vm._v(
                "\n    " + _vm._s(_vm.confirmButtonState.innerText) + " \n    "
              ),
              _c("span", {
                attrs: {
                  id: _vm.expireTime.mountElementId,
                  hidden: !_vm.isVisibleTimer,
                },
              }),
            ]
          )
        : _vm.confirmButtonState.isAttemptsExceeded
        ? _c("div", { staticClass: "ui-text ui-kit-color-text-secondary" }, [
            _vm._v(
              "\n    Слишком много попыток. Подтвердить почту можно через сутки.\n  "
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }