var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.datePickerMenuComponent,
    {
      tag: "component",
      attrs: {
        color: "primary",
        "z-index": "200",
        "max-width": "300",
        "nudge-bottom": "56",
        "retain-focus": false,
        "close-on-content-click": false,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "div",
                { attrs: { "data-qa": _vm.dataQa } },
                [
                  _c(
                    "VTextField",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "date-picker-month-field",
                          attrs: {
                            value: _vm.selectedDateFormatted,
                            readonly: "",
                            outlined: "",
                            "data-qa": `${_vm.dataQa}_input`,
                            "append-icon": "ui-icon-calendar-none",
                            "persistent-placeholder": _vm.isVisibleDatePicker,
                          },
                          on: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleOpenDatePicker($event, on)
                            },
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleOpenDatePicker($event, on)
                            },
                          },
                        },
                        "VTextField",
                        { ...attrs, ..._vm.textFieldProps },
                        false
                      ),
                      on
                    )
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isVisibleDatePicker,
        callback: function ($$v) {
          _vm.isVisibleDatePicker = $$v
        },
        expression: "isVisibleDatePicker",
      },
    },
    [
      _vm._v(" "),
      _c(
        "VDatePicker",
        _vm._b(
          {
            attrs: {
              value: _vm.selectedDate,
              "no-title": "",
              "full-width": "",
              scrollable: "",
              type: "month",
              color: "primary",
              "data-qa": `${_vm.dataQa}_picker`,
            },
            on: { input: _vm.handleSelectedDate },
          },
          "VDatePicker",
          _vm.datePickerProps,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }