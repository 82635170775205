/**
 * @description
 *
 * Возвращает объект с ключами для отслеживания событий рейтинга в зависимости от типа(doctor || lpu)
 *
 * @param { String } type - (doctor || lpu)
 *
 * @return { Object } - объект с ключами по типу: { [id рейтинга(отдельной метрики)]: [{ данные события клика по звезде }] }
 * */

const getRatingFields = type => {
  switch (type) {
    case 'doctor':
      return {
        id_osmotr: [],
        id_recommend: [],
        id_efficiency: [],
        id_friendliness: [],
        id_informativity: [],
      }
    case 'lpu':
      return {
        id_food: [],
        id_leisure: [],
        id_medstaff: [],
        id_building: [],
        id_equipment: [],
      }
  }
}

export default getRatingFields
