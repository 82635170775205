var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "banner-info", class: `p-${_vm.innerAtomIndent}` },
    [
      _c(
        "div",
        {
          staticClass: "banner-info__content p-4",
          class: {
            "banner-info__content_box-shadow": _vm.hasBoxShadow,
          },
          style: { backgroundColor: _vm.background },
        },
        [
          _vm.title
            ? _c(
                "p",
                {
                  staticClass:
                    "banner-info__text ui-text ui-kit-color-text ui-text_subtitle-1",
                  class: {
                    "banner-info__text_indented": _vm.indented,
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.paragraphs, function (paragraph, index) {
            return _c(
              "p",
              {
                key: `paragraph-${index}`,
                staticClass:
                  "banner-info__text ui-text ui-text_body-2 ui-kit-color-text",
                class: {
                  "banner-info__text_indented": _vm.indented,
                  [`mt-${_vm.textOuterAtomIndent}`]: index !== 0 || _vm.title,
                },
              },
              [
                _vm.insertParagraphsLikeHtml
                  ? [_c("span", { domProps: { innerHTML: _vm._s(paragraph) } })]
                  : [_vm._v("\n        " + _vm._s(paragraph) + "\n      ")],
              ],
              2
            )
          }),
          _vm._v(" "),
          _vm._t("default"),
          _vm._v(" "),
          _vm._t("button"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }