<template>
  <input
    :ref="inputRefName"
    :accept="allowedFileExtensions.join(', ') || MIME_TYPES.all"
    :capture="isCaptureInput"
    data-qa="file_picker"
    class="file-picker"
    type="file"
    @change="handleSelectedFile"
    @focus="handleFocus"
    @blur="handleBlur"
    @keydown="handleKeydown"
  >
</template>

<script>
import { createDevNotice } from 'utils'
import { MIME_TYPE_IMAGE_PREFIX, MIME_TYPES } from 'constants/fileTypes'

import {
  TFileExtensions,
  TFileValidationRules,
  TImageValidationRules,
} from 'components/common/FilePicker/interfaces'

export default {
  name: 'FilePicker',
  props: {
    additionalValidationParams: {
      type: Object,
      default: () => ({}),
    },
    allowedFileExtensions: {
      type: Array,
      default: TFileExtensions,
    },
    fileValidationRules: {
      type: Array,
      default: TFileValidationRules,
    },
    imageValidationRules: {
      type: Array,
      default: TImageValidationRules,
    },
    isCaptureInput: {
      type: Boolean,
      default: false,
    },
    isConvertToBase64: {
      type: Boolean,
      default: false,
    },
    loadFileError: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    inputRefName: 'inputGallery',
    MIME_TYPES,
    openFileDialogKeyCodes: ['Space', 'Enter', 'NumpadEnter'],
  }),
  methods: {
    handleKeydown(event) {
      if (this.openFileDialogKeyCodes.includes(event.code)) {
        event.preventDefault()
        this.$emit('file-picker:keypress-open')
      }
    },
    handleFocus() {
      this.$emit('file-picker:focus')
    },
    handleBlur() {
      this.$emit('file-picker:blur')
    },
    resetInput() {
      this.$refs[this.inputRefName].value = ''
    },
    async handleSelectedFile({ target }) {
      const file = target.files[0]

      this.resetInput()

      const validationError = await this.validateFile(file)

      if (validationError) {
        this.$emit('file-picker:error', validationError)
        return
      }

      if (!this.isConvertToBase64) {
        this.$emit('file-picker:input', { file })
        return
      }

      const { base64File, fileError } = await this.readFileAsDataURL(file)

      if (fileError) {
        this.$emit('file-picker:error', fileError)
        return
      }

      this.$emit('file-picker:input', { file, base64File })
    },
    async validateFile(file) {
      try {
        const failedValidation = this.fileValidationRules.find(
          rule => !rule.validation(file, this.additionalValidationParams),
        )

        if (failedValidation) {
          return failedValidation.validationError
        }

        // Дополнительно проверяем относится ли файл к изображениям (имеет MIME-тип 'image/*'),
        // если нет, то getImageBySrc упадёт с ошибкой.
        if (!this.imageValidationRules.length || file.type.indexOf(MIME_TYPE_IMAGE_PREFIX) !== 0) {
          return
        }

        const objectUrl = URL.createObjectURL(file)
        const image = await this.getImageBySrc(objectUrl)
          .finally(() => {
            URL.revokeObjectURL(objectUrl)
          })

        const failedImageValidation = this.imageValidationRules.find(
          rule => !rule.validation(image, this.additionalValidationParams),
        )

        if (failedImageValidation) {
          return failedImageValidation.validationError
        }
      } catch (error) {
        if (error !== this.loadFileError) {
          createDevNotice({
            module: 'FilePicker',
            method: 'validateFile',
            description: `Ошибка при валидации файла - ${error}. MIME-тип файла - ${file.type}`,
          })
        }

        return this.loadFileError
      }
    },
    async getImageBySrc(imageSrc) {
      const { loadFileError } = this

      return new Promise((resolve, reject) => {
        const image = new Image()
        const onLoad = () => {
          // eslint-disable-next-line no-use-before-define
          image.removeEventListener('error', onError)
          resolve(image)
        }
        const onError = () => {
          image.removeEventListener('load', onLoad)
          reject(loadFileError)
        }

        image.addEventListener('load', onLoad, { once: true })
        image.addEventListener('error', onError, { once: true })
        image.src = imageSrc
      })
    },
    async readFileAsDataURL(file) {
      const { loadFileError } = this

      try {
        return await new Promise((resolve, reject) => {
          const reader = new FileReader()
          const onLoad = () => {
            if (typeof reader.result !== 'string') {
              const fileError = { fileError: loadFileError }

              return reject(fileError)
            }

            resolve({ base64File: reader.result })
          }

          reader.addEventListener('load', onLoad, { once: true })
          reader.readAsDataURL(file)
        })
      } catch (error) {
        if (error !== this.loadFileError) {
          createDevNotice({
            module: 'FilePicker',
            method: 'readFileAsDataURL',
            description: `Ошибка при преобразовании файла - ${error}. MIME-тип файла - ${file.type}`,
          })
        }

        return { fileError: this.loadFileError }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.file-picker {
  inset: 0;
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
</style>
