import Vue from 'components'
import { defineStore } from 'pinia'
import { REVIEW_RATING_FEATURE_LISTS, REVIEW_RATING_FEATURES } from 'components/common/ReviewPage/constants'

/**
 * Содержит данные для работы со звёздным рейтингом
 * */

const useStarsRatingStore = defineStore('starsRatingStore', {
  state: () => ({
    ratingList: [],
    isErrorsVisible: false,
    starRatingRefs: [],
  }),
  getters: {
    isValidStarsRating() {
      return !this.ratingList.some(rating => rating.value === 0 && (!rating.isRateAbsentAvailable
          || (!rating.isRateAbsent && rating.isRateAbsentAvailable)))
    },
    invalidStarRatingRef() {
      return this.starRatingRefs.find(starRatingRef => starRatingRef.value === 0)
    },
  },
  actions: {
    initRatingList({ reviewObjectTypes, ratingValues, lpuData }) {
      const featureList = reviewObjectTypes.isLpu
        ? REVIEW_RATING_FEATURE_LISTS[lpuData.isLpuTypeHospital ? 'lpuHospital' : 'lpu']
        : REVIEW_RATING_FEATURE_LISTS.doctor

      this.ratingList = featureList.map(featureName => ({
        value: ratingValues[featureName] || 0,
        hoveredValue: 0,
        key: REVIEW_RATING_FEATURES[featureName].key,
        title: REVIEW_RATING_FEATURES[featureName].title,
        isRateAbsentAvailable: REVIEW_RATING_FEATURES[featureName].isRateAbsentAvailable,
        isRateAbsent: false,
      }))
    },
    updateRating(newRating) {
      const currentRatingIndex = this.ratingList.findIndex(rating => rating.key === newRating.key)

      Vue.set(this.ratingList, currentRatingIndex, newRating)
    },
    updateStarRatingRefs(ratingRefs) {
      this.starRatingRefs = ratingRefs
    },
    validateStarsRating() {
      this.isErrorsVisible = true
    },
  },
})

export default useStarsRatingStore
