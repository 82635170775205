var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VStepper",
    {
      attrs: { "data-qa": "review_stepper" },
      model: {
        value: _vm.currentStep,
        callback: function ($$v) {
          _vm.currentStep = $$v
        },
        expression: "currentStep",
      },
    },
    [
      _vm.$slots["stepper-intro"]
        ? _c(
            "div",
            {
              staticClass: "mx-auto",
              style: { maxWidth: _vm.isMobile ? "" : "472px" },
              attrs: { "data-qa": "review_stepper_intro" },
            },
            [_vm._t("stepper-intro")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "VStepperItems",
        _vm._l(_vm.maxSteps, function (index) {
          return _c(
            "VStepperContent",
            {
              key: index,
              attrs: {
                step: index,
                "data-qa": `review_stepper_main_step_${index}`,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "mx-auto mt-4 mb-10",
                  class: { "px-2": _vm.isMobile },
                  style: { maxWidth: _vm.isMobile ? "" : "472px" },
                  attrs: { "data-qa": "review_stepper_content" },
                },
                [
                  _vm._t(`step-${index}`),
                  _vm._v(" "),
                  _vm.currentStep < 3 && _vm.isDraftExisting
                    ? _c(
                        "div",
                        {
                          key: "draftSaved",
                          staticClass:
                            "ui-text ui-text_body-2 ui-kit-color-text-info text-right mt-4",
                          attrs: {
                            "data-qa": "review_stepper_draft_saved_text",
                          },
                        },
                        [
                          _vm._v(
                            "\n          Черновик сохранён " +
                              _vm._s(_vm.dateModified) +
                              " в " +
                              _vm._s(_vm.timeModified) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("BottomPanel", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isBottomPanelVisible,
            expression: "isBottomPanelVisible",
          },
        ],
        attrs: {
          fixed: _vm.isBottomPanelFixed,
          "data-qa": "review_stepper_bottom_panel",
        },
        scopedSlots: _vm._u([
          {
            key: "container",
            fn: function () {
              return [
                _c(
                  "VStepperItems",
                  _vm._l(_vm.maxSteps, function (index) {
                    return _c(
                      "VStepperContent",
                      {
                        key: index,
                        attrs: {
                          step: index,
                          "data-qa": `review_stepper_footer_step_${index}`,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex px-2 pt-2 mx-n2",
                            class: {
                              "justify-content-end pb-2": !_vm.isMobile,
                              "justify-content-between pb-6": _vm.isMobile,
                            },
                            attrs: { "data-qa": "review_stepper_actions" },
                          },
                          [
                            index !== 1
                              ? _c(
                                  "VBtn",
                                  {
                                    staticClass: "flex-shrink-1 mx-2",
                                    attrs: {
                                      text: "",
                                      depressed: "",
                                      color: "uiKitText",
                                      "data-qa": "review_stepper_button_prev",
                                      width: _vm.isMobile ? "100%" : 144,
                                    },
                                    on: { click: _vm.slidePrevWithScroll },
                                  },
                                  [
                                    _vm._v(
                                      "\n              Назад\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            index !== _vm.maxSteps
                              ? _c(
                                  "VBtn",
                                  {
                                    staticClass: "flex-shrink-1 mx-2",
                                    attrs: {
                                      depressed: "",
                                      color: "primary",
                                      "data-qa": "review_stepper_button_next",
                                      width: _vm.isMobile ? "100%" : 144,
                                    },
                                    on: { click: _vm.slideNextComputed },
                                  },
                                  [
                                    _vm._v(
                                      "\n              Далее\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }