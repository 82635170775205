<template>
  <VApp
    id="review-page-app-desktop"
    data-qa="review_page"
    class="review-page"
  >
    <div class="b-container">
      <h1
        class="ui-text ui-text_h5 ui-kit-color-text mb-6"
        data-qa="review_page_title"
      >
        Оставить отзыв о {{ reviewObjectTypes.isLpu ? 'клинике' : 'враче' }}
      </h1>

      <ReviewPageLayout
        v-if="commonData.isReviewOnModerateOrCanceled"
        key="keyErrorReviewExists"
      >
        <template #main>
          <ReviewPageInfoScreen
            title="Вы уже оставили отзыв"
            subtitle="Отслеживать статус проверки можно в личном кабинете — МедТочке."
            confirm-button-text="Посмотреть отзыв"
            close-button-text="Назад"
            @screen-info:click-button-confirm="redirectToLink(commonData.medtochkaUrlOnReview, true)"
            @screen-info:click-button-close="redirectToLink(reviewObjectLink)"
          />
        </template>
      </ReviewPageLayout>

      <ReviewPageLayout
        v-else-if="!commonData.canSendReview"
        key="keyErrorStaff"
      >
        <template #main>
          <ReviewPageInfoScreen
            title="Отзыв нельзя оставить с рабочего аккаунта"
            confirm-button-text="Понятно"
            @screen-info:click-button-confirm="redirectToLink(reviewObjectLink)"
          />
        </template>
      </ReviewPageLayout>

      <ReviewPageLayout
        v-else-if="isVisibleReviewGetUserEmail"
        key="keyReviewGetUserEmail"
      >
        <template #main>
          <ReviewGetUserEmail />
        </template>
      </ReviewPageLayout>

      <ReviewPageLayout
        v-else-if="isVisibleReviewSubmitting"
        key="keyReviewSubmitting"
      >
        <template #main>
          <ReviewSubmitting />
        </template>
      </ReviewPageLayout>

      <ReviewPageLayout
        v-else
        key="keyReviewPage"
      >
        <template #main>
          <ReviewPageStepper class="pt-6">
            <template
              v-if="commonData.isPdMobile"
              #stepper-intro
            >
              <ReviewPdMobileBanner class="mx-auto" />
            </template>
            <template #step-1>
              <ReviewStarsRating />
            </template>
            <template #step-2>
              <ReviewForm class="mt-5" />
              <ReviewSectionImages />
              <ReviewPhoneConfirmation class="mt-8" />
              <ReviewModalPersonalHistory />
            </template>
          </ReviewPageStepper>
        </template>
        <template #aside>
          <ReviewPageInfoCard class="p-8" />
        </template>
      </ReviewPageLayout>

      <ReviewAuthorizationModal v-if="commonData.canSendReview" />
    </div>
  </VApp>
</template>

<script>
import { mapState } from 'pinia'
import { redirectToLink } from 'components/common/ReviewPage/functions'
import { useReviewPageDataStore, useReviewFormDataStore } from 'components/common/ReviewPage/stores'

import ReviewForm from 'components/common/ReviewPage/components/common/ReviewForm'
import ReviewSubmitting from 'components/common/ReviewPage/components/common/ReviewSubmitting'
import ReviewPageLayout from 'components/common/ReviewPage/components/desktop/ReviewPageLayout'
import ReviewPageStepper from 'components/common/ReviewPage/components/common/ReviewPageStepper'
import ReviewStarsRating from 'components/common/ReviewPage/components/common/ReviewStarsRating'
import ReviewGetUserEmail from 'components/common/ReviewPage/components/common/ReviewGetUserEmail'
import ReviewPageInfoCard from 'components/common/ReviewPage/components/common/ReviewPageInfoCard'
import ReviewPdMobileBanner from 'components/common/ReviewPage/components/common/ReviewPdMobileBanner'
import ReviewPageInfoScreen from 'components/common/ReviewPage/components/common/ReviewPageInfoScreen'
import ReviewPhoneConfirmation from 'components/common/ReviewPage/components/common/ReviewPhoneConfirmation'
import ReviewAuthorizationModal from 'components/common/ReviewPage/components/common/ReviewAuthorizationModal'
import ReviewModalPersonalHistory from 'components/common/ReviewPage/components/common/ReviewModalPersonalHistory'
import ReviewSectionImages from 'components/common/ReviewPage/components/common/ReviewSectionImages'

export default {
  name: 'ReviewPage',
  components: {
    ReviewSectionImages,
    ReviewForm,
    ReviewSubmitting,
    ReviewPageLayout,
    ReviewPageStepper,
    ReviewStarsRating,
    ReviewGetUserEmail,
    ReviewPageInfoCard,
    ReviewPdMobileBanner,
    ReviewPageInfoScreen,
    ReviewPhoneConfirmation,
    ReviewAuthorizationModal,
    ReviewModalPersonalHistory,
  },
  computed: {
    ...mapState(useReviewPageDataStore, [
      'reviewObjectTypes',
      'reviewObjectLink',
      'commonData',
    ]),
    ...mapState(useReviewFormDataStore, [
      'isVisibleReviewGetUserEmail',
    ]),
    ...mapState(useReviewFormDataStore, [
      'isVisibleReviewSubmitting',
    ]),
  },
  methods: {
    redirectToLink,
  },
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/mixins';

#review-page-app-desktop {
  @include v-app-reset;
}
</style>
