<template>
  <VBottomSheet v-model="isVisibleBottomSheet">
    <template #activator="{ on, attrs }">
      <div :data-qa="dataQa">
        <VSelect
          outlined
          append-icon="ui-icon-arrow-down"
          :disabled="disabled"
          :value="selectedItem"
          :items="items"
          :menu-props="{
            offsetY: true,
            maxWidth: 472,
            maxHeight: 480,
            disabled: useBottomSheet,
            ...selectProps.menuProps,
          }"
          v-bind="{ ...attrs, ...$attrs, ...selectProps }"
          v-on="useBottomSheet ? on : null"
          @change="$emit('select:change', $event)"
        >
          <template
            v-if="!useBottomSheet"
            #item="{ item, attrs: itemAttrs, on: itemOn }"
          >
            <VListItem
              :style="{ minHeight: '48px' }"
              :data-qa="`${dataQa}_item`"
              v-bind="itemAttrs"
              v-on="itemOn"
              @click="clickListItem(item)"
            >
              <VListItemContent>
                <VListItemTitle
                  class="ui-text ui-text_body-1"
                  :data-qa="`${dataQa}_item_title`"
                >
                  {{ item[itemText] }}
                </VListItemTitle>
                <VListItemSubtitle
                  v-if="item[itemTextSecondary]"
                  class="ui-text ui-text_body-2"
                  :data-qa="`${dataQa}_item_subtitle`"
                >
                  {{ item[itemTextSecondary] }}
                </VListItemSubtitle>
              </VListItemContent>
            </VListItem>
          </template>
          <template
            v-if="textSecondaryInPreview"
            #selection
          >
            <div class="w-100">
              <div
                class="ui-text ui-text_body-1 ui-text_truncate"
              >
                {{ selectedItemObject[itemText] }}
              </div>
              <div
                v-if="selectedItemObject[itemTextSecondary]"
                class="ui-text ui-text_body-2 ui-kit-color-icon-secondary"
                :style="{ whiteSpace: 'initial' }"
              >
                {{ selectedItemObject[itemTextSecondary] }}
              </div>
            </div>
          </template>
        </VSelect>
      </div>
    </template>
    <VList
      v-if="useBottomSheet"
      class="overflow-y-auto"
      :data-qa="`${dataQa}_list`"
      v-bind="{
        maxHeight: 480,
        ...bottomSheetListProps,
      }"
    >
      <VListItem
        v-for="(item, index) in items"
        :key="index"
        :data-qa="`${dataQa}_item`"
        :style="!wideItems
          ? { maxHeight: '48px' }
          : { minHeight: '56px' }"
        @click="clickListItem(item)"
      >
        <VListItemContent>
          <VListItemTitle
            class="ui-text ui-text_body-1"
            :data-qa="`${dataQa}_item_title`"
          >
            {{ item[itemText] }}
          </VListItemTitle>
          <VListItemSubtitle
            v-if="item[itemTextSecondary]"
            class="ui-text ui-text_body-2"
            :style="{ whiteSpace: 'initial' }"
            :data-qa="`${dataQa}_item_subtitle`"
          >
            {{ item[itemTextSecondary] }}
          </VListItemSubtitle>
        </VListItemContent>
        <VListItemIcon class="align-self-auto">
          <VIcon v-if="!checkIcon">
            ui-icon-arrow-right
          </VIcon>
          <VIcon
            v-else-if="item[itemValue] === selectedItem"
            color="primary"
          >
            ui-icon-check
          </VIcon>
        </VListItemIcon>
      </VListItem>
    </VList>
  </VBottomSheet>
</template>

<script>
export default {
  name: 'SelectBottomSheet',
  model: {
    prop: 'selectedItem',
    event: 'select:change',
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    useBottomSheet: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    selectedItem: {
      type: [String, Number],
      default: null,
    },
    selectProps: {
      type: Object,
      default: () => ({}),
    },
    dataQa: {
      type: String,
      default: 'select_bottom_sheet',
    },
    checkIcon: {
      type: Boolean,
      default: false,
    },
    wideItems: {
      type: Boolean,
      default: false,
    },
    textSecondaryInPreview: {
      type: Boolean,
      default: false,
    },
    bottomSheetListProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isVisibleBottomSheet: false,
  }),
  computed: {
    itemText() {
      return this.selectProps?.itemText || 'text'
    },
    itemValue() {
      return this.selectProps?.itemValue || 'value'
    },
    itemTextSecondary() {
      return this.selectProps?.itemTextSecondary || 'textSecondary'
    },
    selectedItemObject() {
      return this.items.find(item => item[this.itemValue] === this.selectedItem)
    },
  },
  methods: {
    clickListItem(item) {
      this.$emit('select:change', item[this.itemValue])

      this.isVisibleBottomSheet = false
    },
  },
}
</script>
