import { defineStore } from 'pinia'
import { MIME_TYPES } from 'constants/fileTypes'
import {
  FILE_ERROR_TYPES,
  FILE_VALIDATION_TYPES,
  UPLOAD_IMAGES_RULE_TYPES,
} from 'components/common/ReviewPage/constants'
import {
  getAttachedImages,
  getAttachedImagesStatuses,
  getImagesOptions,
} from 'components/common/ReviewPage/interfaces'

/**
 * Содержит данные для работы с загружаемыми изображениями
 * */

const useReviewImagesStore = defineStore('reviewImagesStore', {
  state: () => ({
    attachedImages: getAttachedImages(),
    attachedImagesStatuses: getAttachedImagesStatuses(),
    availableOverallSize: null,
    imagesOptions: getImagesOptions(),
  }),
  getters: {
    validationRules({ allowedFileMimeTypes }) {
      const { maxSizeMb, minResolution, maxImagePixels } = this.imagesOptions

      return {
        [FILE_VALIDATION_TYPES.fileType]: ({ type }) => allowedFileMimeTypes.includes(type),
        [FILE_VALIDATION_TYPES.maxPhotoSize]: ({ size }) => size <= maxSizeMb * 1024 * 1024,
        [FILE_VALIDATION_TYPES.maxOverallFilesSize]: ({ size }) => size <= this.availableOverallSize,
        [FILE_VALIDATION_TYPES.minPhotoResolution]: ({ width, height }) => (
          width >= minResolution.width && height >= minResolution.height
        ),
        [FILE_VALIDATION_TYPES.maxPhotoPixels]: ({ width, height }) => width * height <= maxImagePixels,
      }
    },
    getValidationRules({ validationRules, imagesErrors }) {
      return validationRuleTypes => validationRuleTypes.map(validationType => ({
        validation: validationRules[validationType],
        validationError: imagesErrors[validationType],
      }))
    },
    fileValidationRules({ getValidationRules }) {
      return getValidationRules(UPLOAD_IMAGES_RULE_TYPES.file)
    },
    imageValidationRules({ getValidationRules }) {
      return getValidationRules(UPLOAD_IMAGES_RULE_TYPES.image)
    },
    allowedFileMimeTypes({ imagesOptions }) {
      return imagesOptions.allowedFileTypes.map(fileType => MIME_TYPES[fileType.toLowerCase()])
    },
    allowedFileExtensions({ imagesOptions }) {
      return imagesOptions.allowedFileTypes.map(fileType => `.${fileType}`)
    },
    imagesErrors({ imagesOptions }) {
      const {
        allowedFileTypes,
        maxSizeMb,
        maxOverallSizeMb,
        minResolution,
      } = imagesOptions

      return {
        [FILE_ERROR_TYPES.uploadPhoto]: {
          title: 'Не получилось загрузить фото',
          contentText: 'Попробуйте ещё раз.',
          confirmText: 'Понятно',
        },
        [FILE_ERROR_TYPES.fileType]: {
          title: 'Неподходящий формат',
          contentText: `Подойдёт ${
            allowedFileTypes.length === 2
              ? allowedFileTypes.join(' или ')
              : allowedFileTypes.join(', ')
          }.`,
          confirmText: 'Понятно',
        },
        [FILE_ERROR_TYPES.maxPhotoSize]: {
          title: 'Слишком большое фото',
          contentText: `Загрузите фото до ${maxSizeMb} МБ.`,
          confirmText: 'Понятно',
        },
        [FILE_ERROR_TYPES.maxOverallFilesSize]: {
          title: 'Слишком большой размер файлов',
          contentText: `Общий размер не должен превышать ${maxOverallSizeMb} МБ.`,
          confirmText: 'Понятно',
        },
        [FILE_ERROR_TYPES.minPhotoResolution]: {
          title: 'Слишком маленькое фото',
          contentText: `Загрузите фото от ${minResolution.width}x${minResolution.height} пикселей.`,
          confirmText: 'Понятно',
        },
        [FILE_ERROR_TYPES.maxPhotoPixels]: {
          title: 'Слишком большое разрешение фото',
          contentText: 'Попробуйте загрузить изображение в меньшем качестве.',
          confirmText: 'Понятно',
        },
      }
    },
    getAttachedImageIndex({ attachedImages }) {
      return fileId => attachedImages.findIndex(({ id }) => id === fileId)
    },
    isSomeImageSending({ attachedImages }) {
      return attachedImages.some(({ isSending }) => isSending)
    },
  },
  actions: {
    addAttachedImage({ file, id }) {
      this.availableOverallSize -= file.size
      this.attachedImages.push({
        file,
        id,
        isDocument: false,
        isSending: false,
      })
      this.attachedImagesStatuses.push({})
    },
    deleteAttachedImage({ fileId }) {
      const fileIndex = this.getAttachedImageIndex(fileId)

      if (fileIndex === -1) {
        return
      }

      this.availableOverallSize += this.attachedImages[fileIndex].file.size
      this.attachedImages.splice(fileIndex, 1)
      this.attachedImagesStatuses.splice(fileIndex, 1)
    },
    updateAttachedImage({ fileId, ...payload }) {
      const fileIndex = this.getAttachedImageIndex(fileId)

      if (fileIndex === -1) {
        return
      }

      this.attachedImages.splice(fileIndex, 1, {
        ...this.attachedImages[fileIndex],
        ...payload,
      })
    },
    updateAttachedImagesStatus({ fileId, ...payload }) {
      const fileIndex = this.getAttachedImageIndex(fileId)

      if (fileIndex === -1) {
        return
      }

      this.attachedImagesStatuses.splice(fileIndex, 1, {
        ...this.attachedImagesStatuses[fileIndex],
        ...payload,
      })
    },
    updateAttachedImagesStatuses({ fileId, status }) {
      const fileIndex = this.getAttachedImageIndex(fileId)

      if (fileIndex === -1) {
        return
      }

      this.attachedImagesStatuses.splice(fileIndex, 1, status)
    },
  },
})

export default useReviewImagesStore
