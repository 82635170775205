import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { MOUNT_ID_REVIEW_PAGE } from 'components/common/ReviewPage/constants'
import { mountIfNoRedirect } from 'www/doctors.blocks/common/auto-redirect-to-mt/functions'
import ReviewPage from 'components/common/ReviewPage/components/common/ReviewPage'
import 'components/common/ReviewPage/index.scss'

const mountElement = document.querySelector(`#${MOUNT_ID_REVIEW_PAGE}`)

if (mountElement) {
  mountIfNoRedirect(() => {
    Vue.use(PiniaVuePlugin)

    new Vue({
      vuetify,
      el: mountElement,
      pinia: createPinia(),
      components: {
        ReviewPage,
      },
    })
  })
}
