import { EVENTS as DIALOG_EVENTS } from 'components/common/core/BaseDialog/constants'
import { createDevNotice, dispatchCustomEventGlobally } from 'utils'

function showErrorDialog(options = {}) {
  if (options.sentryModule || options.sentryMethod) {
    createDevNotice({
      module: options.sentryModule,
      method: options.sentryMethod,
      description: options.sentryDescription || `${options.title} ${options.text}`,
    })
  }

  dispatchCustomEventGlobally(DIALOG_EVENTS.open, {
    maxWidth: 304,
    closeText: 'Понятно',
    ...options,
  })
}

export default showErrorDialog
