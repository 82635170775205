<template>
  <VSheet
    v-ripple.center
    :width="width"
    :height="height"
    :min-width="minWidth"
    :min-height="minHeight"
    data-qa="file_uploader_activator"
    class="file-uploader-activator"
    :class="{
      'file-uploader-activator_focused': isFocused,
      'file-uploader-activator_disabled': isDisabled,
    }"
    @click="handleClickActivator"
  >
    <div class="ui-icon-plus file-uploader-activator__icon" />
    <div
      v-if="text"
      data-qa="file_uploader_activator_text"
      class="ui-text ui-text_body-1 text-center mt-3"
      :class="isDisabled ? 'ui-kit-color-bg-gray-80' : 'ui-kit-color-text-secondary'"
      v-text="text"
    />
  </VSheet>
</template>

<script>
export default {
  name: 'FileUploaderActivator',
  props: {
    text: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: '100%',
    },
    height: {
      type: [Number, String],
      default: 'auto',
    },
    minWidth: {
      type: [Number, String],
      default: 'auto',
    },
    minHeight: {
      type: [Number, String],
      default: 'auto',
    },
    isFocused: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClickActivator() {
      this.$emit('file-uploader-activator:click')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.v-sheet.file-uploader-activator {
  cursor: pointer;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed $ui-kit-primary;
  border-radius: $border-radius-md;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: $border-radius-md;
    background-color: $ui-kit-text;
    opacity: 0;
    transition: opacity 0.28s cubic-bezier(0.4, 0, 0.2, 1); // Тип анимации из vuetify
  }

  &:not(&_focused):hover::before {
    opacity: 0.05; // Прозрачность как при hover у v-btn
  }

  &_focused::before {
    opacity: 0.11; // Прозрачность как при focus у v-btn
  }

  &_disabled {
    border-color: $ui-kit-bg-gray-80;
    pointer-events: none;
  }
}

.file-uploader-activator {
  &__icon {
    font-size: 32px;
    color: $ui-kit-primary;
  }

  &_disabled &__icon {
    color: $ui-kit-bg-gray-80;
  }
}
</style>
