<template>
  <div
    class="get-user-email"
    data-qa="get_user_email"
  >
    <VForm
      ref="email-form"
      v-model="emailField.isValid"
      @submit.prevent="handleSubmitEmail"
    >
      <div class="get-user-email__field">
        <VTextField
          ref="email-field-input"
          v-model.trim="emailField.inputValue"
          outlined
          label="Email"
          data-qa="get_user_email_email_input"
          :loading="emailField.isLoading"
          :rules="emailField.rules"
          :error-messages="emailField.errorMessage"
          :disabled="codeField.isVisible || emailField.isLoading"
          @input="handleInputEmail"
          @focus="handleFocusEmail"
          @blur="handleBlurEmail"
        />
        <div
          v-if="codeField.isVisible && emailField.isEditIconVisible"
          class="get-user-email__field-append ui-icon-pen"
          data-qa="get_user_email_edit_email_btn"
          @click="handleClickEditEmail"
        />
      </div>
    </VForm>
    <VForm @submit.prevent>
      <VExpandTransition>
        <VTextField
          v-if="codeField.isVisible"
          v-model="codeField.inputValue"
          v-mask="codeField.mask"
          outlined
          label="Код из письма"
          data-qa="get_user_email_code_input"
          :disabled="codeField.isLoading"
          :loading="codeField.isLoading"
          :error-messages="codeField.errorMessage"
          @input.native="handleInputCode"
        />
      </VExpandTransition>
    </VForm>
    <VBtn
      v-if="!confirmButtonState.isHidden && !confirmButtonState.isAttemptsExceeded"
      v-bind="{ ...confirmButtonState }"
      block
      depressed
      class="mb-4"
      color="primary"
      data-qa="get_user_email_confirmation_btn"
      :loading="emailField.isLoading"
      @click="handleSubmitEmail"
    >
      {{ confirmButtonState.innerText }}&nbsp;
      <span
        :id="expireTime.mountElementId"
        :hidden="!isVisibleTimer"
      />
    </VBtn>
    <div
      v-else-if="confirmButtonState.isAttemptsExceeded"
      class="ui-text ui-kit-color-text-secondary"
    >
      Слишком много попыток.&nbsp;Подтвердить почту можно через сутки.
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import ExpireTime from 'modules/ExpireTime'
import { getEmailValidation } from 'utils'
import {
  sendCodeOnUserEmail,
  checkValidUserCode,
} from 'components/common/GetUserEmail/api'
import { USER_ACTION_AFTER_AUTH } from 'components/common/ReviewPage/constants'

const {
  regex,
  onlyEn,
  required,
  maxLength,
} = getEmailValidation()

export default {
  name: 'GetUserEmail',
  directives: {
    mask,
  },
  props: {
    errorHandlers: {
      type: Object,
      default: () => ({
        email: {
          attemptsExceeded: () => {},
          authenticationFailed: () => {},
          networkError: () => {},
          featureDisabled: () => {},
        },
        code: {
          authenticationFailed: () => {},
          networkError: () => {},
          featureDisabled: () => {},
        },
        unknownError: () => {},
      }),
    },
  },
  data: () => ({
    emailField: {
      inputValue: '',
      tempInputValue: '',
      errorMessage: '',
      isValid: false,
      isLoading: false,
      isInEditState: false,
      isEditIconVisible: true,
      rules: [],
    },
    codeField: {
      inputValue: '',
      errorMessage: '',
      isVisible: false,
      isLoading: false,
      mask: '######',
    },
    confirmationButton: {
      currentStateName: 'confirm',
      previewStateName: null,
      state: {
        confirm: {
          innerText: 'Подтвердить почту',
        },
        confirmAgain: {
          text: true,
          innerText: 'Отправить код повторно',
        },
        confirmAgainDisabled: {
          text: true,
          disabled: true,
          innerText: 'Отправить код повторно',
        },
        hidden: {
          isHidden: true,
        },
        attemptsExceeded: {
          isAttemptsExceeded: true,
        },
      },
    },
    expireTime: {
      instance: null,
      mountElementId: 'get-user-email-timer',
      waitingTime: 150,
    },
    errors: {
      email: ['already_verified', 'invalid_email'],
      verificationCode: ['wrong_verification_code', 'new_code_required', 'verification_not_initiated'],
    },
  }),
  computed: {
    confirmButtonState() {
      return this.confirmationButton.state[this.confirmButtonStateName]
    },
    confirmButtonStateName() {
      return this.confirmationButton.previewStateName || this.confirmationButton.currentStateName
    },
    isEmailFieldValid() {
      return this.emailField.rules.every(validation => validation(this.emailField.inputValue) === true)
    },
    isVisibleTimer() {
      return this.confirmationButton.currentStateName === 'confirmAgainDisabled'
                && !this.confirmationButton.previewStateName
    },
  },
  methods: {
    handleClickEditEmail() {
      this.codeField.isVisible = false
      this.emailField.isInEditState = true
      this.emailField.tempInputValue = this.emailField.inputValue

      this.$nextTick(() => {
        this.$refs['email-field-input'].focus()
      })
    },
    handleInputEmail() {
      this.emailField.errorMessage = ''

      if (!this.emailField.isInEditState) {
        return
      }

      const isEmailFieldHasChanged = this.emailField.inputValue !== this.emailField.tempInputValue

      if (!isEmailFieldHasChanged) {
        this.confirmationButton.previewStateName = null

        return
      }

      this.confirmationButton.previewStateName = 'confirm'
    },
    handleFocusEmail() {
      this.$emit('get-user-email:focus-input')
    },
    handleBlurEmail() {
      this.$emit('get-user-email:blur-input')

      if (!this.emailField.isInEditState && !this.emailField.tempInputValue) {
        return
      }

      this.emailField.isInEditState = false

      const isEmailFieldHasChanged = this.emailField.inputValue !== this.emailField.tempInputValue

      // Если нет изменений в e-mail или он невалиден, то возвращаем как было
      if (!isEmailFieldHasChanged || !this.isEmailFieldValid) {
        this.codeField.isVisible = true
        this.emailField.inputValue = this.emailField.tempInputValue
        this.confirmationButton.previewStateName = null

        return
      }

      this.codeField.isVisible = false

      this.resetCodeField()
    },
    resetCodeField() {
      this.codeField.inputValue = ''
      this.codeField.errorMessage = ''
    },
    async handleSubmitEmail() {
      const isValid = await this.validateEmailField()

      if (!isValid || this.confirmButtonStateName === 'confirmAgainDisabled') {
        return
      }

      this.$refs['email-field-input'].blur()
      this.resetCodeField()

      try {
        this.emailField.isLoading = true

        const {
          code_creation_lock_seconds: codeCreationLockSeconds,
          codes_left_count: codesLeftCount,
        } = await sendCodeOnUserEmail({ email: this.emailField.inputValue })

        this.emailField.isEditIconVisible = codesLeftCount !== 0
        this.emailField.isLoading = false
        this.codeField.isVisible = true

        if (!codeCreationLockSeconds) {
          this.confirmationButton.currentStateName = 'hidden'
          this.confirmationButton.previewStateName = 'hidden'

          return
        }

        this.expireTime.waitingTime = codeCreationLockSeconds

        this.initTimer()

        this.$emit('get-user-email:email-send-success')
      } catch (error) {
        this.emailField.isLoading = false

        if (!window.navigator.onLine || error.message === 'Network Error') {
          this.errorHandlers.email.networkError()

          return
        }

        const errorData = error.response?.data

        if (!errorData) {
          this.errorHandlers.unknownError()

          return
        }

        if (this.errors.email.includes(errorData.code)) {
          this.emailField.errorMessage = errorData.message

          return
        }

        if (errorData.code === 'attempts_exceeded') {
          this.confirmationButton.currentStateName = 'attemptsExceeded'
          this.confirmationButton.previewStateName = 'attemptsExceeded'

          this.errorHandlers.email.attemptsExceeded()

          return
        }

        const { code_creation_lock_seconds: codeCreationLockSeconds } = errorData.emailVerification || {}

        if (codeCreationLockSeconds) {
          this.expireTime.waitingTime = codeCreationLockSeconds
        }

        // Удалить после удаления флага pd_rate_consume_email
        if (errorData.code === 'feature_disabled') {
          this.errorHandlers.email.featureDisabled()

          return
        }

        if (errorData.code === 'authentication_failed') {
          this.errorHandlers.email.authenticationFailed(USER_ACTION_AFTER_AUTH.sendEmail)

          return
        }

        this.errorHandlers.unknownError()
      }
    },
    async validateEmailField() {
      this.emailField.rules = [required, maxLength, onlyEn, regex]

      // Необходима задержка перед валидацией, чтобы сработали правила выше
      await this.$nextTick()

      this.$refs['email-form'].validate()

      return this.emailField.isValid
    },
    async handleInputCode(event) {
      if (event && !event.isTrusted) {
        return
      }

      this.codeField.errorMessage = ''

      if (this.codeField.inputValue.length !== this.codeField.mask.length) {
        return
      }

      try {
        this.codeField.isLoading = true

        const checkValidUserCodeResponse = await checkValidUserCode({
          email: this.emailField.inputValue,
          code: this.codeField.inputValue,
        })

        if (checkValidUserCodeResponse) {
          const {
            code_creation_lock_seconds: codeCreationLockSeconds,
            email_verification: emailVerification,
          } = checkValidUserCodeResponse
          const { verification_attempts_left_count: verificationAttemptsLeftCount } = emailVerification || {}

          if (!codeCreationLockSeconds || verificationAttemptsLeftCount === 0) {
            this.confirmationButton.currentStateName = 'hidden'
            this.confirmationButton.previewStateName = 'hidden'
          }
        }

        this.codeField.isLoading = false

        this.$emit('get-user-email:email-confirmed', this.emailField.inputValue)
      } catch (error) {
        this.codeField.isLoading = false

        if (!window.navigator.onLine || error.message === 'Network Error') {
          this.errorHandlers.code.networkError({ repeatRequest: this.handleInputCode })

          return
        }

        const errorData = error.response?.data

        if (!errorData) {
          this.errorHandlers.unknownError()

          return
        }

        if (this.errors.verificationCode.includes(errorData.code)) {
          this.codeField.errorMessage = errorData.message

          return
        }

        // Удалить после удаления флага pd_rate_consume_email
        if (errorData.code === 'feature_disabled') {
          this.errorHandlers.code.featureDisabled()

          return
        }

        if (errorData.code === 'authentication_failed') {
          this.errorHandlers.code.authenticationFailed(USER_ACTION_AFTER_AUTH.sendEmailCode)

          return
        }

        if (errorData.code === 'attempts_exceeded') {
          this.confirmationButton.currentStateName = 'attemptsExceeded'
          this.confirmationButton.previewStateName = 'attemptsExceeded'

          return
        }

        this.errorHandlers.unknownError()
      }
    },
    initTimer() {
      if (this.expireTime.instance) {
        this.expireTime.instance.reset()
      }

      this.expireTime.instance = new ExpireTime({
        offsetSeconds: this.expireTime.waitingTime,
        startDate: new Date().toISOString(),
        mountSelector: `#${this.expireTime.mountElementId}`,
        beforeCountdown: () => {
          if (this.confirmationButton.currentStateName === 'hidden'
                        || this.confirmationButton.currentStateName === 'attemptsExceeded') {
            return
          }

          this.confirmationButton.previewStateName = null
          this.confirmationButton.currentStateName = 'confirmAgainDisabled'
        },
        afterCountdown: () => {
          if (this.confirmationButton.currentStateName === 'hidden'
                        || this.confirmationButton.currentStateName === 'attemptsExceeded') {
            return
          }

          this.confirmationButton.currentStateName = 'confirmAgain'
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.get-user-email {
  &__field {
    position: relative;
  }

  &__field-append {
    z-index: 1;
    position: absolute;
    top: 17px;
    right: 12px;
    color: rgba(#000000, 0.54);
    cursor: pointer;
  }
}
</style>
