var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VFadeTransition",
    {
      attrs: {
        duration: _vm.animationDuration,
        "hide-on-leave": _vm.hideOnLeave,
        mode: "out-in",
        origin: "center",
        appear: "",
      },
    },
    [
      _c(
        "VSheet",
        {
          staticClass: "file-uploader-preview",
          attrs: {
            width: _vm.imageWidth,
            "max-width": _vm.imageMaxWidth,
            "data-qa": "file_uploader_preview",
          },
        },
        [
          _c(
            "VSheet",
            {
              staticClass: "file-uploader-preview__wrapper",
              attrs: {
                height: _vm.imageHeight,
                "max-height": _vm.imageMaxHeight,
                "data-qa": "file_uploader_preview_wrapper",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "file-uploader-preview__image-wrapper",
                  attrs: { "data-qa": "file_uploader_preview_image_wrapper" },
                },
                [
                  _vm.src && _vm.isImage
                    ? _c("img", {
                        staticClass: "file-uploader-preview__image",
                        attrs: {
                          src: _vm.imagePreviewSrc,
                          alt: _vm.fileName,
                          "data-qa": "file_uploader_preview_image",
                        },
                      })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "VSheet",
                {
                  staticClass: "file-uploader-preview__button-wrapper",
                  attrs: {
                    "data-qa": "file_uploader_preview_button",
                    color: "transparent",
                    width: "32",
                    height: "32",
                    rounded: "circle",
                  },
                  on: { click: _vm.handleClickButton },
                },
                [
                  _c(
                    "VBtn",
                    {
                      staticClass: "file-uploader-preview__button",
                      attrs: {
                        width: "24",
                        height: "24",
                        color: "grey darken-1",
                        elevation: "1",
                        icon: "",
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "ui-icon-trash ui-icon_fz_smaller",
                      }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "VFadeTransition",
                {
                  attrs: {
                    duration: _vm.animationDuration,
                    mode: "out-in",
                    origin: "center",
                    appear: "",
                  },
                },
                [
                  _vm.statusOptions.icon || _vm.statusOptions.isProgress
                    ? _c(
                        "VSheet",
                        {
                          key: _vm.statusOptions.icon || "progress",
                          staticClass: "file-uploader-preview__status-icon",
                          class: _vm.statusOptions.iconClasses,
                          attrs: {
                            width: _vm.statusOptions.iconWidth,
                            height: _vm.statusOptions.iconHeight,
                            "data-qa": "file_uploader_preview_status_icon",
                            rounded: "circle",
                          },
                          on: { click: _vm.debounceHandleClickStatusButton },
                        },
                        [
                          _vm.statusOptions.isProgress
                            ? _c("VProgressCircular", {
                                attrs: {
                                  color: _vm.statusOptions.iconColor,
                                  indeterminate:
                                    _vm.statusOptions.isProgressIndeterminate,
                                  value: _vm.statusOptions.progressValue || 0,
                                  size: _vm.statusOptions.iconSize,
                                  width: _vm.statusOptions.progressWidth,
                                },
                              })
                            : _c(
                                "VIcon",
                                {
                                  attrs: {
                                    color: _vm.statusOptions.iconColor,
                                    size: _vm.statusOptions.iconSize,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.statusOptions.icon) +
                                      "\n          "
                                  ),
                                ]
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "VFadeTransition",
            {
              attrs: {
                duration: _vm.animationDuration,
                mode: "out-in",
                origin: "center",
                appear: "",
              },
            },
            [
              _vm.statusOptions.description
                ? _c("div", {
                    key: _vm.statusOptions.description,
                    staticClass: "pt-1 ui-text ui-text_caption text-center",
                    style: { color: _vm.descriptionColor },
                    attrs: { "data-qa": "file_uploader_preview_description" },
                    domProps: {
                      textContent: _vm._s(_vm.statusOptions.description),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.statusOptions.descriptionHtml
                ? _c("div", {
                    key: _vm.statusOptions.descriptionHtml,
                    staticClass: "pt-1 ui-text ui-text_caption text-center",
                    style: { color: _vm.descriptionColor },
                    attrs: { "data-qa": "file_uploader_preview_description" },
                    domProps: {
                      innerHTML: _vm._s(_vm.statusOptions.descriptionHtml),
                    },
                  })
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }