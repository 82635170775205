<template>
  <VApp
    id="review-page-app-mobile"
    data-qa="review_page"
  >
    <ReviewPageInfoScreen
      v-if="commonData.isReviewOnModerateOrCanceled"
      class="px-2 pb-16 h-100"
      title="Вы уже оставили отзыв"
      subtitle="Отслеживать статус проверки можно в личном кабинете — МедТочке."
      confirm-button-text="Посмотреть отзыв"
      close-button-text="Назад"
      @screen-info:click-button-confirm="redirectToLink(commonData.medtochkaUrlOnReview, true)"
      @screen-info:click-button-close="redirectToLink(reviewObjectLink)"
    />

    <ReviewPageInfoScreen
      v-else-if="!commonData.canSendReview"
      class="px-2 pb-16 h-100"
      title="Отзыв нельзя оставить с рабочего аккаунта"
      confirm-button-text="Понятно"
      @screen-info:click-button-confirm="redirectToLink(reviewObjectLink)"
    />

    <ReviewGetUserEmail
      v-else-if="isVisibleReviewGetUserEmail"
    />

    <ReviewSubmitting
      v-else-if="isVisibleReviewSubmitting"
    />

    <ReviewPageStepper
      v-else
      class="pt-2"
    >
      <template #stepper-intro>
        <ReviewPdMobileBanner
          v-if="commonData.isPdMobile"
          class="m-2"
        />
        <div class="d-flex justify-content-between">
          <h1
            class="ui-text ui-text_h6 ui-kit-color-text mt-2 mb-6 px-2"
            data-qa="review_page_title"
          >
            Оставить отзыв о {{ reviewObjectTypes.isLpu ? 'клинике' : 'враче' }}
          </h1>
          <VBtn
            text
            icon
            depressed
            class="ml-4"
            color="uiKitIconPrimary"
            @click="handleClickClose"
          >
            <VIcon>
              ui-icon-close-not-a-circle
            </VIcon>
          </VBtn>
        </div>
        <ReviewPageInfoCard class="px-2" />
      </template>
      <template #step-1>
        <ReviewStarsRating />
      </template>
      <template #step-2>
        <ReviewForm class="mt-6" />
        <ReviewSectionImages />
        <ReviewPhoneConfirmation class="mt-6" />
        <ReviewModalPersonalHistory />
      </template>
    </ReviewPageStepper>

    <ReviewAuthorizationModal v-if="commonData.canSendReview" />
  </VApp>
</template>

<script>
import { mapState } from 'pinia'
import { redirectToLink, showErrorDialog } from 'components/common/ReviewPage/functions'

import ReviewForm from 'components/common/ReviewPage/components/common/ReviewForm'
import ReviewSubmitting from 'components/common/ReviewPage/components/common/ReviewSubmitting'
import ReviewPageStepper from 'components/common/ReviewPage/components/common/ReviewPageStepper'
import ReviewStarsRating from 'components/common/ReviewPage/components/common/ReviewStarsRating'
import ReviewGetUserEmail from 'components/common/ReviewPage/components/common/ReviewGetUserEmail'
import ReviewPageInfoCard from 'components/common/ReviewPage/components/common/ReviewPageInfoCard'
import ReviewPdMobileBanner from 'components/common/ReviewPage/components/common/ReviewPdMobileBanner'
import ReviewPageInfoScreen from 'components/common/ReviewPage/components/common/ReviewPageInfoScreen'
import ReviewPhoneConfirmation from 'components/common/ReviewPage/components/common/ReviewPhoneConfirmation'
import ReviewAuthorizationModal from 'components/common/ReviewPage/components/common/ReviewAuthorizationModal'
import ReviewModalPersonalHistory from 'components/common/ReviewPage/components/common/ReviewModalPersonalHistory'
import ReviewSectionImages from 'components/common/ReviewPage/components/common/ReviewSectionImages'

import {
  useSteppersStore,
  useReviewPageDataStore,
  useReviewFormDataStore,
} from 'components/common/ReviewPage/stores'

export default {
  name: 'ReviewPage',
  components: {
    ReviewSectionImages,
    ReviewForm,
    ReviewSubmitting,
    ReviewPageStepper,
    ReviewStarsRating,
    ReviewGetUserEmail,
    ReviewPageInfoCard,
    ReviewPdMobileBanner,
    ReviewPageInfoScreen,
    ReviewPhoneConfirmation,
    ReviewAuthorizationModal,
    ReviewModalPersonalHistory,
  },
  computed: {
    ...mapState(useSteppersStore, [
      'currentStep',
    ]),
    ...mapState(useReviewPageDataStore, [
      'reviewObjectTypes',
      'reviewObjectLink',
      'commonData',
    ]),
    ...mapState(useReviewFormDataStore, [
      'isVisibleReviewGetUserEmail',
      'isVisibleReviewSubmitting',
    ]),
  },
  methods: {
    redirectToLink,
    handleClickClose() {
      showErrorDialog({
        maxWidth: 304,
        title: 'Прервать создание отзыва?',
        closeText: 'Вернуться',
        confirmText: 'Прервать',
        isDanger: true,
        beforeConfirm: () => {
          window.location.assign(this.reviewObjectLink)
        },
      })
    },
  },
}
</script>

<style lang="scss">
#review-page-app-mobile {
  .v-application--wrap {
    min-height: calc(100vh - 64px); // минус высота header
  }
}
</style>
