import { debounce, isTouch } from 'utils'

/**
 * Функция, которая выполняет callback после программного скролла браузера.
 *
 * На мобильных устройствах, или на устройствах с тач-экранами бывает программный скролл к инпуту
 * при фокусе на него, и этот скролл нельзя прервать или отменить, что очень не удобно, т.к.
 * позиция скролла может не всегда соответствовать задаче. Поэтому и была создана эта функция.
 * Она, например, может помочь изменить позицию скролла после того как будет выполнен бразуерный скролл.
 * */

function programmaticallyScrollReady(callback = () => {}) {
  // Если не тач и не мобила — значит нет экранной клавиатуры и браузерного скролла
  if (!isTouch() && !window.MOBILE_VERSION) {
    return
  }

  // Флаг — указывающий на то, был ли произведён браузерный скролл при фокусе на инпут
  let isScrolled = false

  // debouncedHandleScroll — выполнится один раз, в конце браузерного скролла
  const debouncedHandleScroll = debounce(() => {
    // eslint-disable-next-line no-use-before-define
    window.removeEventListener('scroll', handleScroll)

    callback()
  }, 50)

  const handleScroll = () => {
    isScrolled = true

    debouncedHandleScroll()
  }

  window.addEventListener('scroll', handleScroll)

  // setTimeout — проверяет, произошёл ли браузерный скролл в течение 100мс, если нет — выполняется  callback-функция
  setTimeout(() => {
    if (!isScrolled) {
      handleScroll()
    }
  }, 100)
}

export default programmaticallyScrollReady
