/**
 * @description
 *
 * Возвращает базовые опции класса
 *
 * @return { Object } data - объект с параметрами
 * */

const getDefaultOptions = () => ({
  sendPaths: [],
  capture: false,
  uniqueId: null,
  abstractions: {},
  requestConfig: {},
  includeFields: [],
  defaultFieldsData: {},
  submitListener: false,
  validation: {
    maxSessionQuantity: 30,
    minQuantityDatasetEvents: 100,
    minQuantitySessionEvents: 10,
    minQuantityInputSymbols: 100,
  },
  beforeAddedEventData: () => {},
  afterCreatedStartedData: () => {},
  beforeSendData: () => {},
  afterSendingData: () => {},
})

export default getDefaultOptions
