/**
 * @description
 *
 * Функция возвращает посимвольные изменения
 * между предыдущим и текущим значениями текстового поля
 *
 * @param { Object } params
 * @param { string } params.oldTargetValue - предыдущее значение
 * @param { string } params.targetValue - текущее значение
 *
 * @return { { changeSymbols: string, quantityChanges: number } } differenceData -
 * объект с данными о произошедших изменениях типа
 * */

function getSymbolsChanges({ targetValue, oldTargetValue }) {
  const isAddedSymbol = targetValue.length > oldTargetValue.length
  const differenceData = {
    changeSymbols: '',
    quantityChanges: 0,
  }

  /**
     * В зависимости от добавления или удаления меняются местами текущее и предыдущее значения.
     *
     * Чтобы корректно обрабатывать суррогатные пары, создаются массивы на основе строк.
     * */
  const newValue = Array.from(isAddedSymbol ? targetValue : oldTargetValue)
  const oldValue = Array.from(isAddedSymbol ? oldTargetValue : targetValue)
  const maxLength = Math.max(newValue.length, oldValue.length)

  for (let i = 0; i < maxLength; i++) {
    /**
         * В соответствии с добавлением символа/группы используются отдельные счетчики для добавленных/удаленных символов
         * */
    const oldChar = oldValue[i - differenceData.quantityChanges]
    const newChar = newValue[i]

    if (oldChar !== newChar) {
      differenceData.quantityChanges++
      differenceData.changeSymbols += newChar
    }
  }

  return differenceData
}

export default getSymbolsChanges
