<template>
  <VCard
    v-show="!isReviewFieldTooMuchLong"
    class="py-2 px-4"
    elevation="4"
    width="234"
    data-qa="review_tooltip"
  >
    <div
      class="d-flex align-items-center mb-2"
      data-qa="review_tooltip_header"
    >
      <VImg
        contain
        width="24"
        height="24"
        class="flex-grow-0 mr-3"
        :src="currentStep.emoji.src"
        :alt="currentStep.emoji.alt"
        data-qa="review_tooltip_img"
      />
      <div
        class="ui-text ui-text_subtitle-1 ui-kit-color-text"
        data-qa="review_tooltip_title"
      >
        Польза отзыва: {{ currentProgressValue }}%
      </div>
    </div>
    <VProgressLinear
      rounded
      class="mb-2"
      data-qa="review_tooltip_progress"
      :value="currentProgressValue"
      :color="currentStep.colorProgress"
      :background-color="vuetifyColors.uiKitBgGray60"
    />
    <div
      v-for="(statusName, statusKey) in statusNames"
      :key="statusKey"
      class="d-flex align-items-center justify-content-between"
      :data-qa="`review_tooltip_status_${statusKey}`"
    >
      <div class="ui-text ui-text_body-2 ui-kit-color-text-secondary">
        {{ statusName }}
      </div>
      <div
        class="ui-text ml-4"
        :class="{
          'ui-kit-color-success ui-icon-check': isStatusActive(statusKey),
          'ui-kit-color-error ui-icon-close-not-a-circle': !isStatusActive(statusKey),
        }"
      />
    </div>
  </VCard>
</template>

<script>
import { mapState } from 'pinia'
import { useReviewFormDataStore } from 'components/common/ReviewPage/stores'
import { FEEDBACK_PROGRESS_STATUS_NAMES } from 'components/common/ReviewPage/constants'
import getFeedbackProgressCurrentStep from 'components/common/ReviewPage/functions/getFeedbackProgressCurrentStep'
import getFeedbackProgressValue from 'components/common/ReviewPage/functions/getFeedbackProgressValue'

export default {
  name: 'ReviewFeedbackProgress',
  data: vm => ({
    vuetifyColors: vm.$vuetify.theme.themes.light,
    statusNames: FEEDBACK_PROGRESS_STATUS_NAMES,
  }),
  computed: {
    ...mapState(useReviewFormDataStore, [
      'isReviewFieldTooMuchLong',
      'reviewTextLength',
    ]),
    currentStep() {
      return getFeedbackProgressCurrentStep(this.reviewTextLength)
    },
    currentProgressValue() {
      return getFeedbackProgressValue(this.reviewTextLength)
    },
  },
  methods: {
    isStatusActive(statusKey) {
      return this.currentStep.activeStatuses.includes(statusKey)
    },
  },
}
</script>
