var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "review-window-get-email",
      class: { "review-window-get-email_mobile": _vm.isMobile },
      attrs: { "data-qa": "review_window_get_email" },
    },
    [
      _c(
        "VDialog",
        {
          attrs: {
            width: "312",
            persistent: "",
            "no-click-animation": "",
            value: _vm.isWaitingToRepeatRequest,
          },
        },
        [
          _c(
            "VCard",
            { staticClass: "pt-5 pb-4 p-6" },
            [_c("ReviewPreloader")],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isVisibleReviewGetUserEmailSuccess
        ? _c("ReviewGetUserEmailSuccess")
        : _c(
            "div",
            { staticClass: "review-window-get-email__inner text-center" },
            [
              _c(
                "div",
                { staticClass: "my-auto" },
                [
                  _c("VImg", {
                    staticClass: "flex-grow-0 mx-auto mb-4",
                    attrs: {
                      contain: "",
                      width: "224",
                      height: "172",
                      "data-qa": "review_window_get_email_img",
                      alt: "Дай пять - иллюстрация ПроДокторов",
                      src: "/static/_v1/pd/mascots/high-five.svg",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "ui-text ui-kit-color-text mb-2",
                      class: {
                        "ui-text_h6": _vm.isMobile,
                        "ui-text_h5": !_vm.isMobile,
                      },
                      attrs: { "data-qa": "review_window_get_email_title" },
                    },
                    [_vm._v("\n        Подскажите свою почту\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ui-text ui-text_body-1 ui-kit-color-text mb-6",
                      attrs: {
                        "data-qa": "review_window_get_email_description",
                      },
                    },
                    [
                      _vm.commonData.isReviewSourcePrs
                        ? [
                            _vm._v(
                              "\n          Сообщим, когда опубликуем отзыв, и пришлём промокод\n        "
                            ),
                          ]
                        : [
                            _vm._v(
                              "\n          Будем держать вас в курсе того, что происходит с отзывом: опубликован или нет.\n        "
                            ),
                          ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("GetUserEmail", {
                    ref: "get-user-email",
                    attrs: { "error-handlers": _vm.errorHandlers() },
                    on: {
                      "get-user-email:email-send-success":
                        _vm.handleSubmitEmail,
                      "get-user-email:email-confirmed":
                        _vm.handleUserEmailConfirmed,
                      "get-user-email:focus-input": function ($event) {
                        _vm.isFocusedInput = true
                      },
                      "get-user-email:blur-input": function ($event) {
                        _vm.isFocusedInput = false
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isVisibleBackBtnOnMobile
                ? _c("BottomPanel", {
                    attrs: { fixed: "", "no-height": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "container",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "px-2 pt-2 pb-6" },
                                [
                                  _c(
                                    "VBtn",
                                    {
                                      attrs: {
                                        text: "",
                                        depressed: "",
                                        color: "uiKitText",
                                        "data-qa":
                                          "review_window_get_email_cancel",
                                        width: "100%",
                                      },
                                      on: { click: _vm.handleBackButtonClick },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "ui-text ui-text_body-2 ui-kit-color-text-secondary",
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.backButtonText) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      658494275
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isVisibleBackBtnOnDesktop
                ? _c(
                    "VBtn",
                    {
                      attrs: {
                        text: "",
                        depressed: "",
                        color: "uiKitText",
                        "data-qa": "review_window_get_email_cancel",
                        width: "100%",
                      },
                      on: { click: _vm.handleBackButtonClick },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "ui-text ui-text_body-2 ui-kit-color-text-secondary",
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.backButtonText) +
                              "\n      "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }