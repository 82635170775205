var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      attrs: {
        scrollable: "",
        width: "667",
        "content-class": "review-protection-program",
        fullscreen: _vm.isMobile,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (props) {
              return [_vm._t("activator", null, null, props)]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.isVisibleDialog,
        callback: function ($$v) {
          _vm.isVisibleDialog = $$v
        },
        expression: "isVisibleDialog",
      },
    },
    [
      _vm._v(" "),
      _c(
        "VCard",
        { attrs: { tile: "", "data-qa": "review_protection_modal" } },
        [
          _c(
            "VCardTitle",
            { staticClass: "pl-1 pb-2" },
            [
              _c(
                "VBtn",
                {
                  staticClass: "mr-7",
                  attrs: {
                    icon: "",
                    width: "32",
                    height: "32",
                    "data-qa": "review_protection_modal_close",
                  },
                  on: { click: _vm.handleCloseDialog },
                },
                [
                  _c("span", {
                    staticClass:
                      "ui-icon-close-not-a-circle ui-text ui-kit-color-text",
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "VCardText",
            { class: _vm.isMobile ? "p-2" : "px-6 pb-11" },
            [
              _c("img", {
                staticClass: "d-block mb-8 mx-auto",
                attrs: {
                  width: "208",
                  height: "169",
                  alt: "Логотип программы защиты",
                  src: "/static/_v1/pd/mascots/review-security.svg",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "ui-text ui-text_h6 ui-kit-color-text text-center mb-8",
                },
                [_vm._v("\n        Программа защиты авторов отзывов\n      ")]
              ),
              _vm._v(" "),
              _vm._l(
                _vm.PROTECTION_PROGRAM_CONTENTS,
                function (content, contentIndex) {
                  return _c(
                    "div",
                    {
                      key: `content-${contentIndex}`,
                      staticClass: "review-protection-program__content",
                    },
                    [
                      _c("div", { staticClass: "ui-text ui-text_subtitle-1" }, [
                        _vm._v(
                          "\n          " + _vm._s(content.title) + "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(content.texts, function (text, textIndex) {
                        return _c("div", {
                          key: `text-${textIndex}`,
                          staticClass: "ui-text ui-text_body-1",
                          domProps: { innerHTML: _vm._s(text) },
                        })
                      }),
                    ],
                    2
                  )
                }
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "ui-text ui-text_subtitle-1 ui-kit-color-text mb-6",
                },
                [_vm._v("\n        Ответы на важные вопросы\n      ")]
              ),
              _vm._v(" "),
              _c(
                "VExpansionPanels",
                {
                  attrs: {
                    multiple: "",
                    "data-qa": "review_protection_modal_expansion_panels",
                  },
                },
                _vm._l(
                  _vm.PROTECTION_PROGRAM_PANEL_CONTENTS,
                  function (content, index) {
                    return _c(
                      "VExpansionPanel",
                      {
                        key: index,
                        attrs: {
                          "data-qa": "review_protection_modal_expansion_panel",
                        },
                      },
                      [
                        _c(
                          "VExpansionPanelHeader",
                          {
                            staticClass: "text-left justify-content-between",
                            attrs: { "expand-icon": "ui-icon-arrow-down" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ui-text ui-text_body-1 ui-kit-color-text",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(content.title) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("VExpansionPanelContent", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "ui-text ui-text_body-1 ui-kit-color-text",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(content.text) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    )
                  }
                ),
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("BottomPanel", {
            scopedSlots: _vm._u([
              {
                key: "container",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "p-2" },
                      [
                        _c(
                          "VBtn",
                          {
                            class: { "d-block ml-auto": !_vm.isMobile },
                            attrs: {
                              depressed: "",
                              color: "primary",
                              "data-qa": "review_protection_modal_button_back",
                              block: _vm.isMobile,
                            },
                            on: { click: _vm.handleCloseDialog },
                          },
                          [
                            _vm._v(
                              "\n            Вернуться к отзыву\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }