var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VApp",
    { attrs: { id: "review-page-app-mobile", "data-qa": "review_page" } },
    [
      _vm.commonData.isReviewOnModerateOrCanceled
        ? _c("ReviewPageInfoScreen", {
            staticClass: "px-2 pb-16 h-100",
            attrs: {
              title: "Вы уже оставили отзыв",
              subtitle:
                "Отслеживать статус проверки можно в личном кабинете — МедТочке.",
              "confirm-button-text": "Посмотреть отзыв",
              "close-button-text": "Назад",
            },
            on: {
              "screen-info:click-button-confirm": function ($event) {
                return _vm.redirectToLink(
                  _vm.commonData.medtochkaUrlOnReview,
                  true
                )
              },
              "screen-info:click-button-close": function ($event) {
                return _vm.redirectToLink(_vm.reviewObjectLink)
              },
            },
          })
        : !_vm.commonData.canSendReview
        ? _c("ReviewPageInfoScreen", {
            staticClass: "px-2 pb-16 h-100",
            attrs: {
              title: "Отзыв нельзя оставить с рабочего аккаунта",
              "confirm-button-text": "Понятно",
            },
            on: {
              "screen-info:click-button-confirm": function ($event) {
                return _vm.redirectToLink(_vm.reviewObjectLink)
              },
            },
          })
        : _vm.isVisibleReviewGetUserEmail
        ? _c("ReviewGetUserEmail")
        : _vm.isVisibleReviewSubmitting
        ? _c("ReviewSubmitting")
        : _c("ReviewPageStepper", {
            staticClass: "pt-2",
            scopedSlots: _vm._u([
              {
                key: "stepper-intro",
                fn: function () {
                  return [
                    _vm.commonData.isPdMobile
                      ? _c("ReviewPdMobileBanner", { staticClass: "m-2" })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-between" },
                      [
                        _c(
                          "h1",
                          {
                            staticClass:
                              "ui-text ui-text_h6 ui-kit-color-text mt-2 mb-6 px-2",
                            attrs: { "data-qa": "review_page_title" },
                          },
                          [
                            _vm._v(
                              "\n          Оставить отзыв о " +
                                _vm._s(
                                  _vm.reviewObjectTypes.isLpu
                                    ? "клинике"
                                    : "враче"
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "VBtn",
                          {
                            staticClass: "ml-4",
                            attrs: {
                              text: "",
                              icon: "",
                              depressed: "",
                              color: "uiKitIconPrimary",
                            },
                            on: { click: _vm.handleClickClose },
                          },
                          [
                            _c("VIcon", [
                              _vm._v(
                                "\n            ui-icon-close-not-a-circle\n          "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("ReviewPageInfoCard", { staticClass: "px-2" }),
                  ]
                },
                proxy: true,
              },
              {
                key: "step-1",
                fn: function () {
                  return [_c("ReviewStarsRating")]
                },
                proxy: true,
              },
              {
                key: "step-2",
                fn: function () {
                  return [
                    _c("ReviewForm", { staticClass: "mt-6" }),
                    _vm._v(" "),
                    _c("ReviewSectionImages"),
                    _vm._v(" "),
                    _c("ReviewPhoneConfirmation", { staticClass: "mt-6" }),
                    _vm._v(" "),
                    _c("ReviewModalPersonalHistory"),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
      _vm._v(" "),
      _vm.commonData.canSendReview ? _c("ReviewAuthorizationModal") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }