import { axiosClient, getCSRFToken } from 'utils'

/**
 * Отправляет код на указанный пользователем e-mail
 * */

function sendCodeOnUserEmail({ email } = {}) {
  return new Promise((resolve, reject) => {
    axiosClient({
      url: '/api/user/email/verification/',
      method: 'POST',
      headers: {
        'X-CSRFToken': getCSRFToken(),
        'Content-Type': 'application/json',
      },
      data: { email },
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export default sendCodeOnUserEmail
