var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VCard",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isReviewFieldTooMuchLong,
          expression: "!isReviewFieldTooMuchLong",
        },
      ],
      staticClass: "py-2 px-4",
      attrs: { elevation: "4", width: "234", "data-qa": "review_tooltip" },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center mb-2",
          attrs: { "data-qa": "review_tooltip_header" },
        },
        [
          _c("VImg", {
            staticClass: "flex-grow-0 mr-3",
            attrs: {
              contain: "",
              width: "24",
              height: "24",
              src: _vm.currentStep.emoji.src,
              alt: _vm.currentStep.emoji.alt,
              "data-qa": "review_tooltip_img",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "ui-text ui-text_subtitle-1 ui-kit-color-text",
              attrs: { "data-qa": "review_tooltip_title" },
            },
            [
              _vm._v(
                "\n      Польза отзыва: " +
                  _vm._s(_vm.currentProgressValue) +
                  "%\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("VProgressLinear", {
        staticClass: "mb-2",
        attrs: {
          rounded: "",
          "data-qa": "review_tooltip_progress",
          value: _vm.currentProgressValue,
          color: _vm.currentStep.colorProgress,
          "background-color": _vm.vuetifyColors.uiKitBgGray60,
        },
      }),
      _vm._v(" "),
      _vm._l(_vm.statusNames, function (statusName, statusKey) {
        return _c(
          "div",
          {
            key: statusKey,
            staticClass: "d-flex align-items-center justify-content-between",
            attrs: { "data-qa": `review_tooltip_status_${statusKey}` },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "ui-text ui-text_body-2 ui-kit-color-text-secondary",
              },
              [_vm._v("\n      " + _vm._s(statusName) + "\n    ")]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass: "ui-text ml-4",
              class: {
                "ui-kit-color-success ui-icon-check":
                  _vm.isStatusActive(statusKey),
                "ui-kit-color-error ui-icon-close-not-a-circle":
                  !_vm.isStatusActive(statusKey),
              },
            }),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }