<template>
  <div data-qa="review_personal_history">
    <VDialog
      width="312"
      persistent
      no-click-animation
      :value="personalHistoryData.isLoading"
    >
      <VCard
        class="pt-5 pb-4 p-6"
        data-qa="review_personal_history_preloader"
      >
        <ReviewPreloader text="Проверяем искусственным интеллектом..." />
      </VCard>
    </VDialog>
    <VDialog
      persistent
      :width="isMobile ? 304 : 456"
      :value="personalHistoryData.isVisibleModal"
    >
      <VCard>
        <div class="pt-5 pb-4 p-6 mb-2">
          <VImg
            contain
            width="175"
            height="175"
            class="mx-auto mb-4"
            src="/static/_v1/pd/mascots/review-error.svg"
            alt="Плохой отзыв"
            data-qa="review_personal_history_img"
          />
          <div
            class="ui-text ui-text_h6 ui-kit-color-text mb-4"
            data-qa="review_personal_history_title"
          >
            <template v-if="personalHistoryData.isPersonalHistory === false">
              В отзыве нет личной истории
            </template>
            <template v-else-if="personalHistoryData.isPersonalHistory === null">
              Кажется, в отзыве нет личной истории
            </template>
          </div>
          <div
            class="ui-text ui-text_body-1 ui-kit-color-text-secondary"
            data-qa="review_personal_history_subtitle"
          >
            <template v-if="reviewObjectTypes.isLpu">
              <template v-if="personalHistoryData.isPersonalHistory === false">
                Нужно дополнить отзыв, прежде чем отправить. Расскажите:
                <ul>
                  <li>как обратились в клинику</li>
                  <li>как прошёл визит</li>
                  <li>понравился ли сервис и отношение персонала</li>
                </ul>
              </template>
              <template v-else-if="personalHistoryData.isPersonalHistory === null">
                Его могут не опубликовать. Лучше дополнить отзыв перед отправкой. Расскажите:
                <ul>
                  <li>как обратились в клинику</li>
                  <li>как прошёл визит</li>
                  <li>понравился ли сервис и отношение персонала</li>
                </ul>
              </template>
            </template>
            <template v-else>
              <template v-if="personalHistoryData.isPersonalHistory === false">
                Нужно дополнить отзыв, прежде чем отправить. Расскажите:
                <ul>
                  <li>почему обратились к врачу</li>
                  <li>как прошёл приём</li>
                  <li>помогло ли лечение.</li>
                </ul>
              </template>
              <template v-else-if="personalHistoryData.isPersonalHistory === null">
                Его могут не опубликовать. Лучше дополнить отзыв перед отправкой. Расскажите:
                <ul>
                  <li>почему обратились к врачу</li>
                  <li>как прошёл приём</li>
                  <li>помогло ли лечение</li>
                </ul>
              </template>
            </template>
          </div>
        </div>
        <VCardActions data-qa="review_personal_history_actions">
          <VSpacer />
          <VBtn
            v-if="personalHistoryData.isPersonalHistory === null"
            text
            class="px-2"
            color="uiKitText"
            data-qa="review_personal_history_button_next"
            @click="handleClickSkip"
          >
            Отправить
          </VBtn>
          <VBtn
            depressed
            color="primary"
            data-qa="review_personal_history_button_edit"
            class="px-2"
            @click="handleClickEditNow"
          >
            Исправить
          </VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { scrollToElement, isFeatureFlagEnabledHTML } from 'utils'
import { sendYaGoal } from 'components/common/ReviewPage/functions'
import ReviewPreloader from 'components/common/ReviewPage/components/common/ReviewPreloader'
import { useReviewPageDataStore, useReviewFormDataStore } from 'components/common/ReviewPage/stores'
import { PAGE_NEWRATE_YANDEX_GOALS } from 'yandexGoals'

export default {
  name: 'ReviewModalPersonalHistory',
  components: {
    ReviewPreloader,
  },
  data: () => ({
    isMobile: window.MOBILE_VERSION,
    isEnabledFFPersonalHistoryState: isFeatureFlagEnabledHTML({ dataAttr: 'data-ff-new-personal-history-ab-state' }),
  }),
  computed: {
    ...mapState(useReviewPageDataStore, [
      'reviewObjectTypes',
    ]),
    ...mapState(useReviewFormDataStore, [
      'personalHistoryData',
    ]),
  },
  watch: {
    'personalHistoryData.isVisibleModal': {
      handler(isVisible) {
        if (isVisible) {
          sendYaGoal(PAGE_NEWRATE_YANDEX_GOALS.commonPersonalHistoryModalVisible)

          if (this.personalHistoryData.isPersonalHistory === false) {
            sendYaGoal(PAGE_NEWRATE_YANDEX_GOALS.commonPersonalHistoryModalVisibleFalse)
          }

          if (this.personalHistoryData.isPersonalHistory === null) {
            sendYaGoal(PAGE_NEWRATE_YANDEX_GOALS.commonPersonalHistoryModalVisibleNull)
          }
        }
      },
    },
  },
  methods: {
    ...mapActions(useReviewFormDataStore, [
      'updateIsValidForm',
      'updatePersonalHistoryData',
    ]),
    ...mapActions(useReviewFormDataStore, [
      'sendReviewFormData',
    ]),
    async handleClickSkip() {
      sendYaGoal(PAGE_NEWRATE_YANDEX_GOALS.commonPersonalHistoryModalSkip)

      this.updatePersonalHistoryData({ isVisibleModal: false })
      this.updateIsValidForm(true)
      await this.sendReviewFormData()
    },
    handleClickEditNow() {
      sendYaGoal(PAGE_NEWRATE_YANDEX_GOALS.commonPersonalHistoryModalEditNow)

      this.updatePersonalHistoryData({ isVisibleModal: false })

      const textareaAlertElement = document.querySelector('[data-is-exist-visible-alert]')

      if (textareaAlertElement) {
        scrollToElement(textareaAlertElement)
      }
    },
  },
}
</script>
