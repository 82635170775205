import { getQueryParam } from 'utils'
import { getVisitDate } from 'components/common/ReviewPage/functions'
import {
  useUserDataStore,
  useDraftInfoStore,
  useStarsRatingStore,
  useReviewPageDataStore,
  useReviewFormDataStore,
  useReviewImagesStore,
} from 'components/common/ReviewPage/stores'

/**
 * В данном миксине происходит слияние данных Pinia-хранилища (store)
 * и входных данных (props) главного компонента
 * */

const ReviewPageMergedProps = {
  created() {
    this.mergePropsWithStore()
  },
  methods: {
    mergePropsWithStore() {
      const userDataStore = useUserDataStore()
      const draftInfoStore = useDraftInfoStore()
      const starsRatingStore = useStarsRatingStore()
      const reviewImagesStore = useReviewImagesStore()
      const reviewPageDataStore = useReviewPageDataStore()
      const reviewFormDataStore = useReviewFormDataStore()

      const visitDate = getVisitDate()

      reviewPageDataStore.lpuData = this.lpuData
      reviewPageDataStore.doctorData = this.doctorData
      reviewPageDataStore.commonData = this.commonData
      reviewPageDataStore.reviewObjectTypes = this.reviewObjectTypes

      Object.assign(reviewFormDataStore.inputData, this.draftData.form)

      if (visitDate && !this.draftData.form.selectedDate) {
        reviewFormDataStore.inputData.selectedDate = visitDate
      }

      if (!this.draftData.form.selectedLpuAddressId) {
        if (this.reviewObjectTypes.isDoctor) {
          const targetLpuId = Number(getQueryParam('lpu'))
          const currentLpuAddress = this.doctorData.lpuAddresses.length
            ? this.doctorData.lpuAddresses.find(({ lpuId }) => lpuId === targetLpuId)
            : reviewFormDataStore.otherLpuAddressItem

          if (currentLpuAddress) {
            reviewFormDataStore.inputData.selectedLpuAddressId = currentLpuAddress.id
          }
        } else {
          const targetLpuId = this.lpuData.id
          const currentLpuAddress = this.lpuData.lpuAddresses.find(({ lpuId }) => lpuId === targetLpuId)

          if (currentLpuAddress) {
            reviewFormDataStore.inputData.selectedLpuAddressId = currentLpuAddress.id
          }
        }
      }

      starsRatingStore.initRatingList({
        lpuData: this.lpuData,
        ratingValues: this.draftData.rating,
        reviewObjectTypes: this.reviewObjectTypes,
      })

      Object.assign(draftInfoStore, this.draftData.info)

      userDataStore.userData = this.initUserData

      reviewImagesStore.imagesOptions = this.reviewImagesOptions
      reviewImagesStore.availableOverallSize = this.reviewImagesOptions.maxOverallSizeMb * 1024 * 1024
    },
  },
}

export default ReviewPageMergedProps
