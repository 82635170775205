// eslint-disable-next-line import/no-cycle
import useReviewFormDataStore from 'components/common/ReviewPage/stores/useReviewFormDataStore'
import useReviewImagesStore from 'components/common/ReviewPage/stores/useReviewImagesStore'
import useReviewPageDataStore from 'components/common/ReviewPage/stores/useReviewPageDataStore'
import useStarsRatingStore from 'components/common/ReviewPage/stores/useStarsRatingStore'

import {
  PATIENT_TYPE_NAME,
  OTHER_LPU_NAME_AND_ADDRESS_ID,
} from 'components/common/ReviewPage/constants'

/**
 * @description Формирует объект с данными формы в формате, который принимает backend
 *
 * @param { Object } options
 * @param { Boolean } options.asRequestForReview - вернуть объект с дополнительными полями для сохранения отзыва
 *
 * @returns { FormData } - объект FormData
 */

function buildRequestData(options = {}) {
  const starsRatingStore = useStarsRatingStore()
  const reviewImagesStore = useReviewImagesStore()
  const reviewPageDataStore = useReviewPageDataStore()
  const reviewFormDataStore = useReviewFormDataStore()

  const ratingData = {}
  const { inputData } = reviewFormDataStore
  const [rateYear, rateMonth] = inputData.selectedDate.split('-')
  const otherPatient = inputData.selectedPatient === PATIENT_TYPE_NAME.anotherMan ? inputData.otherPatientFromReview : ''
  const otherLpuName = inputData.selectedLpuAddressId === OTHER_LPU_NAME_AND_ADDRESS_ID ? inputData.otherLpuNameAndAddress : ''

  starsRatingStore.ratingList.forEach(ratingItem => {
    if (ratingItem.isRateAbsent) {
      ratingData[ratingItem.key] = ''

      return
    }

    ratingData[ratingItem.key] = ratingItem.value ? ratingItem.value - 3 : ''
  })

  const requestData = {
    who_visited: inputData.selectedPatient,
    another_relation: otherPatient,
    lpuaddr: inputData.selectedLpuAddressId || '',
    lpuaddr_text: otherLpuName,
    rate_year: Number(rateYear) || '',
    rate_month: Number(rateMonth) || '',
    comment: inputData.comment,
    comment_plus: inputData.commentPlus,
    comment_minus: inputData.commentMinus,
    key: reviewPageDataStore.commonData.reviewDataKey,
    ...ratingData,
  }

  const requestFormData = new FormData()

  Object.entries(requestData).forEach(entry => {
    requestFormData.append(...entry)
  })

  if (options.asRequestForReview) {
    reviewImagesStore.attachedImages.forEach(({ file, isDocument }) => {
      requestFormData.append(isDocument ? 'image_documents' : 'image_not_documents', file, file.name)
    })

    requestFormData.append('ignore_bad_words', reviewFormDataStore.fastValidationData.isUserAgreeNonBadWords)
    requestFormData.append('from_prs', reviewPageDataStore.commonData.isReviewSourcePrs)
  }

  return requestFormData
}

export default buildRequestData
