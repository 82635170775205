var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "alert-cookie d-flex flex-direction-column justify-content-between mx-auto",
      class: _vm.isMobile ? "alert-cookie_mobile" : "alert-cookie_desktop",
    },
    [
      _c(
        "div",
        {
          staticClass: "alert-cookie__body text-center m-auto",
          class: { "pt-11": !_vm.isMobile },
        },
        [
          _c("VImg", {
            staticClass: "mx-auto mb-2",
            attrs: {
              alt: _vm.exclamationMark.alt,
              src: _vm.exclamationMark.src,
              width: "150",
              height: "150",
            },
          }),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "ui-text ui-text_h5 ui-kit-color-text mb-2" },
            [_vm._v("\n      Не получилось войти\n    ")]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "ui-text ui-text_body-1 ui-kit-color-text-secondary mb-6",
            },
            [
              _vm._v(
                "\n      Есть несколько способов, чтобы решить эту проблему\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "alert-cookie__list mx-auto mb-6" },
            _vm._l(_vm.tips, function (tip, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: [
                    "alert-cookie__list-item d-flex align-items-center mb-4 mx-2",
                    "ui-text ui-text_body-1 ui-kit-color-text",
                  ],
                },
                [_vm._v("\n        " + _vm._s(tip) + "\n      ")]
              )
            }),
            0
          ),
          _vm._v(" "),
          !_vm.isMobile
            ? _c(
                "VBtn",
                {
                  attrs: {
                    color: _vm.vuetifyColors.uiKitBgPrimary,
                    depressed: "",
                  },
                  on: { click: _vm.reloadPage },
                },
                [
                  _c("span", { staticClass: "ui-text ui-kit-color-primary" }, [
                    _vm._v("\n        Войти ещё раз\n      "),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center mx-2" },
        [
          _vm.isMobile
            ? _c(
                "VBtn",
                {
                  staticClass: "mb-2",
                  attrs: {
                    color: _vm.vuetifyColors.uiKitBgPrimary,
                    depressed: "",
                    block: "",
                  },
                  on: { click: _vm.reloadPage },
                },
                [
                  _c("span", { staticClass: "ui-text ui-kit-color-primary" }, [
                    _vm._v("\n        Войти ещё раз\n      "),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "mb-6 ui-text ui-text_body-2 ui-kit-color-text-info",
            },
            [
              _vm._v("\n      Если возникли вопросы, звоните\n      "),
              _vm.isMobile ? _c("br") : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "ui-text ui-kit-color-text-info",
                  attrs: { href: "tel:+78007073129" },
                },
                [_vm._v("\n        8 (800) 707-31-29\n      ")]
              ),
              _vm._v("\n      — поможем разобраться\n    "),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }