import { render, staticRenderFns } from "./ReviewSubmitting.vue?vue&type=template&id=f4ffb8ae&scoped=true&"
import script from "./ReviewSubmitting.vue?vue&type=script&lang=js&"
export * from "./ReviewSubmitting.vue?vue&type=script&lang=js&"
import style0 from "./ReviewSubmitting.vue?vue&type=style&index=0&id=f4ffb8ae&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4ffb8ae",
  null
  
)

export default component.exports