function redirectToLink(link, isBlank = false) {
  if (isBlank) {
    window.open(link, '_blank')

    return
  }

  window.location.assign(link)
}

export default redirectToLink
