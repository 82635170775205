<template>
  <VSheet
    :width="width"
    :height="height"
    :min-width="minWidth"
    :min-height="minHeight"
    data-qa="file_uploader"
    class="file-uploader"
    :class="{
      'flex-direction-column': isTopPicker,
    }"
  >
    <FilePicker
      v-if="!isExceededAttachedFiles"
      :ref="filePickerRef"
      v-bind="pickerProps"
      :is-capture-input="isCaptureInput"
      @file-picker:input="handleInputFilePicker"
      @file-picker:error="handleErrorFilePicker"
      @file-picker:keypress-open="handleClickActivator"
      @file-picker:focus="handleFocusFilePicker"
      @file-picker:blur="handleBlurFilePicker"
    />
    <slot
      v-if="!attachedFiles.length || isTopPicker"
      name="activator"
      :on="{ click: handleClickActivator }"
      :is-init="!attachedFiles.length"
      :is-focused="isFocusedFileUploaderActivator"
      :is-disabled="isExceededAttachedFiles"
    >
      <FileUploaderActivator
        :text="isExceededAttachedFiles ? activatorDisabledText : activatorText"
        :is-focused="isFocusedFileUploaderActivator"
        :is-disabled="isExceededAttachedFiles"
        :min-height="isTopPicker ? minHeight : 'auto'"
        @file-uploader-activator:click="handleClickActivator"
      />
    </slot>
    <div
      v-if="attachedFiles.length"
      data-qa="file_uploader_content"
      class="file-uploader__content"
      :class="{
        'mt-4': isTopPicker,
      }"
    >
      <template v-for="(attachedFile, index) in attachedFiles">
        <slot
          name="file-preview"
          :attached-file="attachedFile"
          :file-id="attachedFile.id"
          :on="{
            sendAttachedFile,
            deleteAttachedFile,
          }"
        >
          <FileUploaderPreview
            :key="attachedFile.id"
            :file-id="attachedFile.id"
            :file-type="attachedFile.file.type"
            :file-name="attachedFile.file.name"
            :src="attachedFile.src"
            :image-width="previewImageWidth"
            :image-height="previewImageHeight"
            :image-max-width="previewImageMaxWidth"
            :image-max-height="previewImageMaxHeight"
            :status-options="previewStatusesOptions[index]"
            :hide-on-leave="isExceededAttachedFiles"
            :style="previewImageStyle"
            class="file-uploader__preview"
            @file-uploader-preview:click-status-button="sendAttachedFile"
            @file-uploader-preview:click-button="deleteAttachedFile"
          />
        </slot>
      </template>
      <slot
        v-if="!isExceededAttachedFiles && !isTopPicker"
        name="activator"
        :on="{ click: handleClickActivator }"
        :is-init="!attachedFiles.length"
        :is-focused="isFocusedFileUploaderActivator"
        :is-disabled="isExceededAttachedFiles"
      >
        <FileUploaderActivator
          :width="previewImageWidth"
          :height="previewImageHeight"
          :is-focused="isFocusedFileUploaderActivator"
          class="file-uploader__activator-preview"
          @file-uploader-activator:click="handleClickActivator"
        />
      </slot>
    </div>
    <AlertDialog
      :active="alertDialogProps.active"
      :title="alertDialogProps.title"
      :content-text="alertDialogProps.contentText"
      :confirm-text="alertDialogProps.confirmText"
      :close-text="alertDialogProps.closeText"
      data-qa="file_uploader_alert_dialog"
      @close="handleCloseAlert"
      @confirm="handleConfirmAlert"
    />
    <VBottomSheet v-model="isVisibleBottomSheet">
      <VList>
        <VListItem
          class="py-1"
          @click="handleClickLoadType(true)"
        >
          Сделать фото
        </VListItem>
        <VListItem
          class="py-1"
          @click="handleClickLoadType(false)"
        >
          Загрузить из галереи
        </VListItem>
      </VList>
    </VBottomSheet>
  </VSheet>
</template>

<script>
import { throttle } from 'utils'

import AlertDialog from 'components/common/AlertDialog/AlertDialog'

import FileUploaderActivator from 'components/common/FileUploader/components/common/FileUploaderActivator'
import FilePicker from 'components/common/FilePicker/components/common/FilePicker'
import FileUploaderPreview from 'components/common/FileUploader/components/common/FileUploaderPreview'
import {
  IError,
  IPickerProps,
  TPreviewStatusesOptions,
  TInitAttachedFiles,
} from 'components/common/FileUploader/interfaces'

export default {
  name: 'FileUploader',
  components: {
    AlertDialog,
    FileUploaderActivator,
    FilePicker,
    FileUploaderPreview,
  },
  props: {
    activatorText: {
      type: String,
      default: '',
    },
    activatorDisabledText: {
      type: String,
      default: '',
    },
    pickerProps: {
      type: Object,
      default: IPickerProps,
    },
    /**
         * Поле отвечает за минимальное количество превью изображений,
         * которые могут поместиться в одну строку (cм. flex-wrap).
         * Если будет задан previewImageMaxWidth и вычисленная ширина (см. previewImageStyle)
         * начнёт ограничиваться previewImageMaxWidth,
         * то количество превью может поместиться больше, чем указано в minCountPreviewImagesOnLine.
         * */
    minCountPreviewImagesOnLine: {
      type: Number,
      default: 0,
    },
    /**
         * Все свойства: previewImageWidth, previewImageHeight, previewImageMaxWidth, previewImageMaxHeight
         * - могут повлиять на размер превью изображения, т.к. оно рисуется в `canvas`,
         * а там нужны точные размеры (см. метод createImagePreview в FileUploaderPreview.vue).
         * */
    previewImageWidth: {
      type: [Number, String],
      default: 80,
    },
    previewImageHeight: {
      type: [Number, String],
      default: 80,
    },
    previewImageMaxWidth: {
      type: [Number, String],
      default: 'auto',
    },
    previewImageMaxHeight: {
      type: [Number, String],
      default: 'auto',
    },
    previewStatusesOptions: {
      type: Array,
      default: TPreviewStatusesOptions,
    },
    width: {
      type: [Number, String],
      default: '100%',
    },
    height: {
      type: [Number, String],
      default: 'auto',
    },
    minWidth: {
      type: [Number, String],
      default: 'auto',
    },
    minHeight: {
      type: [Number, String],
      default: 'auto',
    },
    maxAttachedFiles: {
      type: Number,
      default: -1,
    },
    uploadHandler: {
      type: Function,
      default: async () => {},
    },
    useLoadFromGallery: {
      type: Boolean,
      default: false,
    },
    useLoadFromCamera: {
      type: Boolean,
      default: false,
    },
    initAttachedFiles: {
      type: Array,
      default: TInitAttachedFiles,
    },
    isTopPicker: {
      type: Boolean,
      default: false,
    },
  },
  data: vm => ({
    alertDialogProps: {
      active: false,
      ...IError(),
    },
    attachedFiles: [],
    filePickerRef: 'file-picker',
    isVisibleBottomSheet: false,
    isCaptureInput: vm.useLoadFromCamera && !vm.useLoadFromGallery,
    lastFileId: 0,
    isFocusedFileUploaderActivator: false,
  }),
  computed: {
    isExceededAttachedFiles() {
      return this.maxAttachedFiles !== -1 && this.maxAttachedFiles <= this.attachedFiles.length
    },
    openFilePicker() {
      return throttle(() => {
        this.$refs[this.filePickerRef].$el.click()
      }, 300, { strictMode: true })
    },
    previewImageStyle() {
      if (!this.minCountPreviewImagesOnLine) {
        return null
      }

      const partPercentage = Math.floor(100 / this.minCountPreviewImagesOnLine)

      return {
        // 24px - размер горизонтальных отступов (padding-left, padding-right, margin-left; см. file-uploader__preview)
        width: `calc(${partPercentage}% - 24px)`,
      }
    },
  },
  beforeMount() {
    this.initAttachedFiles.forEach(({
      file,
      base64File,
      allowedSend = false,
      id,
    }) => {
      this.lastFileId = Math.max(this.lastFileId, id)
      this.attachedFiles.push({
        file,
        src: base64File || URL.createObjectURL(file),
        allowedSend,
        id,
        isDeleted: false,
      })

      if (allowedSend) {
        this.sendAttachedFile(id)
      }
    })
  },
  beforeDestroy() {
    if (!this.pickerProps.isConvertToBase64) {
      this.attachedFiles.forEach(({ src }) => {
        URL.revokeObjectURL(src)
      })
    }
  },
  methods: {
    getAttachedFile(fileId) {
      return this.attachedFiles.find(({ id }) => id === fileId)
    },
    handleInputFilePicker({ file, base64File }) {
      this.$emit('file-uploader:add-file', {
        file,
        base64File,
        id: ++this.lastFileId,
      })
      this.attachedFiles.push({
        file,
        src: this.pickerProps.isConvertToBase64 ? base64File : URL.createObjectURL(file),
        allowSend: true,
        id: this.lastFileId,
        isDeleted: false,
      })
      this.sendAttachedFile(this.lastFileId)
    },
    handleErrorFilePicker(fileError) {
      this.updateAlertDialogProps(fileError)
      this.openAlertDialog()
    },
    handleConfirmAlert() {
      this.closeAlertDialog()
      this.alertDialogProps.confirmHandler(this.handleClickActivator)
    },
    handleCloseAlert() {
      this.closeAlertDialog()
      this.alertDialogProps.closeHandler(this.handleClickActivator)
    },
    handleClickActivator() {
      if (this.isExceededAttachedFiles) {
        return
      }

      if (this.useLoadFromGallery && this.useLoadFromCamera) {
        this.openBottomSheet()
        return
      }

      this.openFilePicker()
    },
    handleFocusFilePicker() {
      this.isFocusedFileUploaderActivator = true
    },
    handleBlurFilePicker() {
      this.isFocusedFileUploaderActivator = false
    },
    handleClickLoadType(isLoadFromCamera) {
      this.isCaptureInput = isLoadFromCamera

      // Необходимо подождать, чтобы обновилось значение isCaptureInput
      this.$nextTick(this.openFilePicker)
      this.closeBottomSheet()
    },
    async sendAttachedFile(fileId) {
      const attachedFile = this.getAttachedFile(fileId)

      if (!attachedFile || !attachedFile.allowSend) {
        return
      }

      attachedFile.allowSend = false
      this.$emit('file-uploader:upload-pending', { fileId })

      this.uploadHandler(attachedFile, ({ loaded, total }) => {
        if (attachedFile.isDeleted) {
          return
        }

        this.$emit('file-uploader:upload-progress', {
          fileId,
          progress: (loaded / total) * 100,
        })

        if (loaded === total) {
          this.$emit('file-uploader:upload-complete', { fileId })
        }
      })
        .then(data => {
          if (attachedFile.isDeleted) {
            return
          }

          this.$emit('file-uploader:upload-success', { fileId, data })
        })
        .catch(error => {
          if (attachedFile.isDeleted) {
            return
          }

          this.$emit('file-uploader:upload-error', { fileId, error })
          attachedFile.allowSend = true
        })
    },
    deleteAttachedFile(fileId) {
      const attachedFileIndex = this.attachedFiles.findIndex(({ id }) => id === fileId)
      const attachedFile = this.attachedFiles[attachedFileIndex]

      attachedFile.isDeleted = true

      if (!this.pickerProps.isConvertToBase64) {
        URL.revokeObjectURL(attachedFile.src)
      }

      this.$emit('file-uploader:delete-file', { fileId })
      this.attachedFiles.splice(attachedFileIndex, 1)
    },
    updateAlertDialogProps(payload) {
      this.alertDialogProps = {
        active: this.alertDialogProps.active,
        ...IError(),
        ...payload,
      }
    },
    openAlertDialog() {
      this.alertDialogProps.active = true
    },
    closeAlertDialog() {
      this.alertDialogProps.active = false
    },
    openBottomSheet() {
      this.isVisibleBottomSheet = true
    },
    closeBottomSheet() {
      this.isVisibleBottomSheet = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.file-uploader {
  display: flex;
  position: relative;

  $preview-margin-left: 8px;
  $preview-padding-x: 8px;
  $preview-margin-bottom: 24px;

  &__preview {
    box-sizing: content-box;
    margin-left: $preview-margin-left;
    margin-bottom: $preview-margin-bottom;
    padding-left: $preview-padding-x;
    padding-right: $preview-padding-x;
  }

  &__activator-preview {
    margin-left: $preview-margin-left + $preview-padding-x;
    margin-bottom: $preview-margin-bottom;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + #{$preview-margin-left});
    margin-left: -$preview-margin-left;
    margin-bottom: -$preview-margin-bottom;
    padding-top: 8px;
  }
}
</style>
