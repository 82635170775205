var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-qa": "review_personal_history" } },
    [
      _c(
        "VDialog",
        {
          attrs: {
            width: "312",
            persistent: "",
            "no-click-animation": "",
            value: _vm.personalHistoryData.isLoading,
          },
        },
        [
          _c(
            "VCard",
            {
              staticClass: "pt-5 pb-4 p-6",
              attrs: { "data-qa": "review_personal_history_preloader" },
            },
            [
              _c("ReviewPreloader", {
                attrs: { text: "Проверяем искусственным интеллектом..." },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "VDialog",
        {
          attrs: {
            persistent: "",
            width: _vm.isMobile ? 304 : 456,
            value: _vm.personalHistoryData.isVisibleModal,
          },
        },
        [
          _c(
            "VCard",
            [
              _c(
                "div",
                { staticClass: "pt-5 pb-4 p-6 mb-2" },
                [
                  _c("VImg", {
                    staticClass: "mx-auto mb-4",
                    attrs: {
                      contain: "",
                      width: "175",
                      height: "175",
                      src: "/static/_v1/pd/mascots/review-error.svg",
                      alt: "Плохой отзыв",
                      "data-qa": "review_personal_history_img",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "ui-text ui-text_h6 ui-kit-color-text mb-4",
                      attrs: { "data-qa": "review_personal_history_title" },
                    },
                    [
                      _vm.personalHistoryData.isPersonalHistory === false
                        ? [
                            _vm._v(
                              "\n            В отзыве нет личной истории\n          "
                            ),
                          ]
                        : _vm.personalHistoryData.isPersonalHistory === null
                        ? [
                            _vm._v(
                              "\n            Кажется, в отзыве нет личной истории\n          "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ui-text ui-text_body-1 ui-kit-color-text-secondary",
                      attrs: { "data-qa": "review_personal_history_subtitle" },
                    },
                    [
                      _vm.reviewObjectTypes.isLpu
                        ? [
                            _vm.personalHistoryData.isPersonalHistory === false
                              ? [
                                  _vm._v(
                                    "\n              Нужно дополнить отзыв, прежде чем отправить. Расскажите:\n              "
                                  ),
                                  _c("ul", [
                                    _c("li", [
                                      _vm._v("как обратились в клинику"),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [_vm._v("как прошёл визит")]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _vm._v(
                                        "понравился ли сервис и отношение персонала"
                                      ),
                                    ]),
                                  ]),
                                ]
                              : _vm.personalHistoryData.isPersonalHistory ===
                                null
                              ? [
                                  _vm._v(
                                    "\n              Его могут не опубликовать. Лучше дополнить отзыв перед отправкой. Расскажите:\n              "
                                  ),
                                  _c("ul", [
                                    _c("li", [
                                      _vm._v("как обратились в клинику"),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [_vm._v("как прошёл визит")]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _vm._v(
                                        "понравился ли сервис и отношение персонала"
                                      ),
                                    ]),
                                  ]),
                                ]
                              : _vm._e(),
                          ]
                        : [
                            _vm.personalHistoryData.isPersonalHistory === false
                              ? [
                                  _vm._v(
                                    "\n              Нужно дополнить отзыв, прежде чем отправить. Расскажите:\n              "
                                  ),
                                  _c("ul", [
                                    _c("li", [
                                      _vm._v("почему обратились к врачу"),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [_vm._v("как прошёл приём")]),
                                    _vm._v(" "),
                                    _c("li", [_vm._v("помогло ли лечение.")]),
                                  ]),
                                ]
                              : _vm.personalHistoryData.isPersonalHistory ===
                                null
                              ? [
                                  _vm._v(
                                    "\n              Его могут не опубликовать. Лучше дополнить отзыв перед отправкой. Расскажите:\n              "
                                  ),
                                  _c("ul", [
                                    _c("li", [
                                      _vm._v("почему обратились к врачу"),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [_vm._v("как прошёл приём")]),
                                    _vm._v(" "),
                                    _c("li", [_vm._v("помогло ли лечение")]),
                                  ]),
                                ]
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "VCardActions",
                { attrs: { "data-qa": "review_personal_history_actions" } },
                [
                  _c("VSpacer"),
                  _vm._v(" "),
                  _vm.personalHistoryData.isPersonalHistory === null
                    ? _c(
                        "VBtn",
                        {
                          staticClass: "px-2",
                          attrs: {
                            text: "",
                            color: "uiKitText",
                            "data-qa": "review_personal_history_button_next",
                          },
                          on: { click: _vm.handleClickSkip },
                        },
                        [_vm._v("\n          Отправить\n        ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "VBtn",
                    {
                      staticClass: "px-2",
                      attrs: {
                        depressed: "",
                        color: "primary",
                        "data-qa": "review_personal_history_button_edit",
                      },
                      on: { click: _vm.handleClickEditNow },
                    },
                    [_vm._v("\n          Исправить\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }