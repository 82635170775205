var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VSheet",
    {
      staticClass: "file-uploader",
      class: {
        "flex-direction-column": _vm.isTopPicker,
      },
      attrs: {
        width: _vm.width,
        height: _vm.height,
        "min-width": _vm.minWidth,
        "min-height": _vm.minHeight,
        "data-qa": "file_uploader",
      },
    },
    [
      !_vm.isExceededAttachedFiles
        ? _c(
            "FilePicker",
            _vm._b(
              {
                ref: _vm.filePickerRef,
                attrs: { "is-capture-input": _vm.isCaptureInput },
                on: {
                  "file-picker:input": _vm.handleInputFilePicker,
                  "file-picker:error": _vm.handleErrorFilePicker,
                  "file-picker:keypress-open": _vm.handleClickActivator,
                  "file-picker:focus": _vm.handleFocusFilePicker,
                  "file-picker:blur": _vm.handleBlurFilePicker,
                },
              },
              "FilePicker",
              _vm.pickerProps,
              false
            )
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.attachedFiles.length || _vm.isTopPicker
        ? _vm._t(
            "activator",
            function () {
              return [
                _c("FileUploaderActivator", {
                  attrs: {
                    text: _vm.isExceededAttachedFiles
                      ? _vm.activatorDisabledText
                      : _vm.activatorText,
                    "is-focused": _vm.isFocusedFileUploaderActivator,
                    "is-disabled": _vm.isExceededAttachedFiles,
                    "min-height": _vm.isTopPicker ? _vm.minHeight : "auto",
                  },
                  on: {
                    "file-uploader-activator:click": _vm.handleClickActivator,
                  },
                }),
              ]
            },
            {
              on: { click: _vm.handleClickActivator },
              isInit: !_vm.attachedFiles.length,
              isFocused: _vm.isFocusedFileUploaderActivator,
              isDisabled: _vm.isExceededAttachedFiles,
            }
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.attachedFiles.length
        ? _c(
            "div",
            {
              staticClass: "file-uploader__content",
              class: {
                "mt-4": _vm.isTopPicker,
              },
              attrs: { "data-qa": "file_uploader_content" },
            },
            [
              _vm._l(_vm.attachedFiles, function (attachedFile, index) {
                return [
                  _vm._t(
                    "file-preview",
                    function () {
                      return [
                        _c("FileUploaderPreview", {
                          key: attachedFile.id,
                          staticClass: "file-uploader__preview",
                          style: _vm.previewImageStyle,
                          attrs: {
                            "file-id": attachedFile.id,
                            "file-type": attachedFile.file.type,
                            "file-name": attachedFile.file.name,
                            src: attachedFile.src,
                            "image-width": _vm.previewImageWidth,
                            "image-height": _vm.previewImageHeight,
                            "image-max-width": _vm.previewImageMaxWidth,
                            "image-max-height": _vm.previewImageMaxHeight,
                            "status-options": _vm.previewStatusesOptions[index],
                            "hide-on-leave": _vm.isExceededAttachedFiles,
                          },
                          on: {
                            "file-uploader-preview:click-status-button":
                              _vm.sendAttachedFile,
                            "file-uploader-preview:click-button":
                              _vm.deleteAttachedFile,
                          },
                        }),
                      ]
                    },
                    {
                      attachedFile: attachedFile,
                      fileId: attachedFile.id,
                      on: {
                        sendAttachedFile: _vm.sendAttachedFile,
                        deleteAttachedFile: _vm.deleteAttachedFile,
                      },
                    }
                  ),
                ]
              }),
              _vm._v(" "),
              !_vm.isExceededAttachedFiles && !_vm.isTopPicker
                ? _vm._t(
                    "activator",
                    function () {
                      return [
                        _c("FileUploaderActivator", {
                          staticClass: "file-uploader__activator-preview",
                          attrs: {
                            width: _vm.previewImageWidth,
                            height: _vm.previewImageHeight,
                            "is-focused": _vm.isFocusedFileUploaderActivator,
                          },
                          on: {
                            "file-uploader-activator:click":
                              _vm.handleClickActivator,
                          },
                        }),
                      ]
                    },
                    {
                      on: { click: _vm.handleClickActivator },
                      isInit: !_vm.attachedFiles.length,
                      isFocused: _vm.isFocusedFileUploaderActivator,
                      isDisabled: _vm.isExceededAttachedFiles,
                    }
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("AlertDialog", {
        attrs: {
          active: _vm.alertDialogProps.active,
          title: _vm.alertDialogProps.title,
          "content-text": _vm.alertDialogProps.contentText,
          "confirm-text": _vm.alertDialogProps.confirmText,
          "close-text": _vm.alertDialogProps.closeText,
          "data-qa": "file_uploader_alert_dialog",
        },
        on: { close: _vm.handleCloseAlert, confirm: _vm.handleConfirmAlert },
      }),
      _vm._v(" "),
      _c(
        "VBottomSheet",
        {
          model: {
            value: _vm.isVisibleBottomSheet,
            callback: function ($$v) {
              _vm.isVisibleBottomSheet = $$v
            },
            expression: "isVisibleBottomSheet",
          },
        },
        [
          _c(
            "VList",
            [
              _c(
                "VListItem",
                {
                  staticClass: "py-1",
                  on: {
                    click: function ($event) {
                      return _vm.handleClickLoadType(true)
                    },
                  },
                },
                [_vm._v("\n        Сделать фото\n      ")]
              ),
              _vm._v(" "),
              _c(
                "VListItem",
                {
                  staticClass: "py-1",
                  on: {
                    click: function ($event) {
                      return _vm.handleClickLoadType(false)
                    },
                  },
                },
                [_vm._v("\n        Загрузить из галереи\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }