import { defineStore } from 'pinia'
import { getInitUserData } from 'components/common/ReviewPage/interfaces'

/**
 * Содержит данные пользователя
 * */

const useUserDataStore = defineStore('userDataStore', {
  state: () => ({
    userData: getInitUserData,
    userEmailFormRef: null,
    userAuthorizationRef: null,
    userActionAfterAuthorization: null,
  }),
  actions: {
    updateUserData(payload) {
      this.userData = {
        ...this.userData,
        ...payload,
      }
    },
    updateUserIsLogged(payload) {
      this.userData.isLogged = payload
    },
    updateUserEmailFormRef(payload) {
      this.userEmailFormRef = payload
    },
    updateUserAuthorizationRef(payload) {
      this.userAuthorizationRef = payload
    },

    resetUserAuthorization(actionAfterAuth = null) {
      if (!this.userAuthorizationRef?.resetWithoutPhone) {
        return
      }

      this.userAuthorizationRef.resetWithoutPhone()

      this.userData.isLogged = false
      this.userData.isPhoneConfirmedByCall = false
      this.userActionAfterAuthorization = actionAfterAuth
    },
  },
})

export default useUserDataStore
