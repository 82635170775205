import tippy, { createSingleton } from 'tippy.js'
import createTippyContent from 'components/common/ReviewPage/functions/createTippyContent'

/**
 * @description Создаёт тултип с прогрессом заполнения отзыва на основе Tippy.js
 *
 * @param { string } targetSelector - селектор элементов, к которым будет примонтирован тултип
 *
 * @return { Object } instances - объект c инстансами tippy
 * @return { Array } instances.tippyInstances - массив инстансов tippy (для каждого targetSelector)
 * @return { Object } instances.singletonInstance - инстанс singleton
 *
 * @see https://atomiks.github.io/tippyjs/
 * */

function createTippyFeedbackProgress(targetSelector) {
  const tippyInstances = tippy(targetSelector, {
    content: createTippyContent(),
  })

  const singletonInstance = createSingleton(tippyInstances, {
    arrow: false,
    allowHTML: true,
    hideOnClick: false,
    interactive: !window.MOBILE_VERSION,
    trigger: 'focusin',
    animation: 'fade',
    zIndex: 1,
    placement: window.MOBILE_VERSION ? 'bottom-end' : 'right-start',
    offset: window.MOBILE_VERSION ? [-2, 0] : [0, 2],
    moveTransition: 'transform 0.2s ease-out',
    appendTo: () => document.body,
    popperOptions: {
      modifiers: [{ name: 'flip', enabled: false }],
    },
    onClickOutside: (instance, e) => {
      if (window.MOBILE_VERSION) {
        return
      }

      const isClickOnScrollbar = e.offsetX > e.target.clientWidth || e.offsetY > e.target.clientHeight

      if (isClickOnScrollbar) {
        return
      }

      /* Скрывается тултип при опции `interactive: true` */
      instance.hide()
    },
  })

  return {
    tippyInstances,
    singletonInstance,
  }
}

export default createTippyFeedbackProgress
