import { CUSTOM_EVENTS, TIMEOUT_DELAY } from 'www/doctors.blocks/common/auto-redirect-to-mt/constants'

export default function mountIfNoRedirect(mountCallback) {
  let isMounted = false

  const mount = () => {
    if (!isMounted) {
      mountCallback()
    }

    isMounted = true
  }

  window.addEventListener(CUSTOM_EVENTS.noRedirect, mount)
  setTimeout(mount, TIMEOUT_DELAY)
}
