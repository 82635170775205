import { axiosClient, getCSRFToken } from 'utils'

/**
 * @description Отправляет запрос на сохранение черновика
 *
 * @param url { string } - URL для запроса на сохранение черновика
 * @param data { string } - данные формы в формате FormData
 * @returns { Promise<unknown> } response
 */
function requestDraftSaving(url, data) {
  return new Promise((resolve, reject) => {
    axiosClient({
      url,
      data,
      method: 'POST',
      headers: {
        'X-CSRFToken': getCSRFToken(),
      },
    })
      .then(response => resolve(response))
      .catch(({ response }) => reject(response))
  })
}

export default requestDraftSaving
