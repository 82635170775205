import { differenceInDays } from 'date-fns'

import { isUsedLocalStorage, dispatchCustomEventGlobally } from 'utils'
import UserStorage from 'modules/UserStorage'
import { CUSTOM_EVENTS, STORAGE_KEY } from 'www/doctors.blocks/common/auto-redirect-to-mt/constants';

(() => {
  if (!isUsedLocalStorage()) {
    dispatchCustomEventGlobally(CUSTOM_EVENTS.noRedirect)
    return
  }

  const redirectToMtLinkElement = document.getElementById('redirect-to-mt-link')

  if (!redirectToMtLinkElement) {
    dispatchCustomEventGlobally(CUSTOM_EVENTS.noRedirect)
    return
  }

  const redirectToMtLink = redirectToMtLinkElement.getAttribute('data-link')

  if (!redirectToMtLink) {
    dispatchCustomEventGlobally(CUSTOM_EVENTS.noRedirect)
    return
  }

  const now = Date.now()
  let redirectToMtDate = UserStorage.get(STORAGE_KEY) || 0

  if (Number.isInteger(Number(redirectToMtDate))) {
    redirectToMtDate = Number(redirectToMtDate)
  }

  redirectToMtDate = new Date(redirectToMtDate)

  if (
    !redirectToMtDate
        || differenceInDays(now, redirectToMtDate) >= 1
  ) {
    UserStorage.set(STORAGE_KEY, now)
    window.location.assign(redirectToMtLink)

    return
  }

  dispatchCustomEventGlobally(CUSTOM_EVENTS.noRedirect)
})()
