<template>
  <div data-qa="review_preloader">
    <VProgressCircular
      size="41"
      width="2"
      indeterminate
      data-qa="review_preloader_circular"
      class="ui-text ui-kit-color-primary d-block mx-auto"
      :class="{ 'mb-6': text }"
    />
    <div
      v-if="text"
      class="ui-text ui-text_body-1 ui-kit-color-text text-center"
      data-qa="review_preloader_text"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewPreloader',
  props: {
    text: {
      type: String,
      default: null,
    },
  },
}
</script>
