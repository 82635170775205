import {
  createDevNotice,
  getCSRFToken,
  getQueryParam,
  axiosClient,
} from 'utils'

const readQueryMedtochkaNotification = async () => {
  const mtNotificationId = getQueryParam('medtochka_notification_id')

  if (mtNotificationId) {
    try {
      const CSRFToken = getCSRFToken()

      if (!CSRFToken) {
        return
      }

      await axiosClient.patch(`/api/medtochka/notifications/${mtNotificationId}/`, {}, {
        headers: {
          'X-CSRFToken': CSRFToken,
        },
      })
    } catch (e) {
      const { response = {} } = e

      if (Number(response?.status) === 403) { // слишком много событий в Sentry сыпется
        return
      }

      createDevNotice({
        mode: 'error',
        module: 'medtochkaNotifications',
        method: 'readQueryMedtochkaNotification',
        description: `При прочтении уведомления медточки произошла ошибка ${JSON.stringify(response?.data)}, статус ${response?.status}`,
        sentryPayload: response,
      })
    }
  }
}

export default readQueryMedtochkaNotification
