var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-qa": "review_preloader" } },
    [
      _c("VProgressCircular", {
        staticClass: "ui-text ui-kit-color-primary d-block mx-auto",
        class: { "mb-6": _vm.text },
        attrs: {
          size: "41",
          width: "2",
          indeterminate: "",
          "data-qa": "review_preloader_circular",
        },
      }),
      _vm._v(" "),
      _vm.text
        ? _c(
            "div",
            {
              staticClass:
                "ui-text ui-text_body-1 ui-kit-color-text text-center",
              attrs: { "data-qa": "review_preloader_text" },
            },
            [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }