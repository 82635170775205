import initAlertCookie from 'components/common/AlertCookie'
import { createAlertCookieNodeAfter } from 'www/global.js.blocks/common/alert-cookie/functions'
import { NODE_TO_HIDE_CLASS } from 'components/common/AlertCookie/constants'

if (!navigator.cookieEnabled) {
  const nodeToHide = document.querySelector(`.${NODE_TO_HIDE_CLASS}`)

  if (nodeToHide) {
    nodeToHide.hidden = true

    createAlertCookieNodeAfter(nodeToHide)
    initAlertCookie()
  }
}
