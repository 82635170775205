import { MOUNT_ALERT_COOKIE_CLASS } from 'components/common/AlertCookie/constants'

function createAlertCookieNodeAfter(node) {
  const alertCookieNode = document.createElement('div')

  node.after(alertCookieNode)
  alertCookieNode.classList.add(MOUNT_ALERT_COOKIE_CLASS)
}

export default createAlertCookieNodeAfter
