import { axiosClient, getCSRFToken } from 'utils'

function requestNewReview(data = {}, {
  reviewObjectId,
  reviewObjectType,
  isOnlyValidate = false,
  isOnlyCheckExistReview = false,
} = {}) {
  let requestUrl = `${window.location.origin}/create/rate/${reviewObjectType}/${reviewObjectId}/`

  if (isOnlyValidate) {
    requestUrl += '?only_validate'
  } else if (isOnlyCheckExistReview) {
    requestUrl += '?check_exist_rate'
  }

  const headers = {
    'X-CSRFToken': getCSRFToken(),
  }

  return new Promise((resolve, reject) => {
    axiosClient({
      method: 'POST',
      url: requestUrl,
      data,
      headers,
    })
      .then(response => resolve(response))
      .catch(response => reject(response))
  })
}

export default requestNewReview
