<template>
  <div
    class="alert-cookie d-flex flex-direction-column justify-content-between mx-auto"
    :class="isMobile ? 'alert-cookie_mobile' : 'alert-cookie_desktop'"
  >
    <div
      class="alert-cookie__body text-center m-auto"
      :class="{ 'pt-11': !isMobile }"
    >
      <VImg
        :alt="exclamationMark.alt"
        :src="exclamationMark.src"
        width="150"
        height="150"
        class="mx-auto mb-2"
      />
      <p class="ui-text ui-text_h5 ui-kit-color-text mb-2">
        Не получилось войти
      </p>
      <p class="ui-text ui-text_body-1 ui-kit-color-text-secondary mb-6">
        Есть несколько способов, чтобы решить эту проблему
      </p>
      <ul class="alert-cookie__list mx-auto mb-6">
        <li
          v-for="(tip, index) in tips"
          :key="index"
          :class="[
            'alert-cookie__list-item d-flex align-items-center mb-4 mx-2',
            'ui-text ui-text_body-1 ui-kit-color-text',
          ]"
        >
          {{ tip }}
        </li>
      </ul>
      <VBtn
        v-if="!isMobile"
        :color="vuetifyColors.uiKitBgPrimary"
        depressed
        @click="reloadPage"
      >
        <span class="ui-text ui-kit-color-primary">
          Войти ещё раз
        </span>
      </VBtn>
    </div>
    <div class="text-center mx-2">
      <VBtn
        v-if="isMobile"
        class="mb-2"
        :color="vuetifyColors.uiKitBgPrimary"
        depressed
        block
        @click="reloadPage"
      >
        <span class="ui-text ui-kit-color-primary">
          Войти ещё раз
        </span>
      </VBtn>
      <p class="mb-6 ui-text ui-text_body-2 ui-kit-color-text-info">
        Если возникли вопросы, звоните
        <br v-if="isMobile">
        <a
          href="tel:+78007073129"
          class="ui-text ui-kit-color-text-info"
        >
          8 (800) 707-31-29
        </a>
        — поможем разобраться
      </p>
    </div>
  </div>
</template>

<script>
import {
  EXCLAMATION_MARK_IMAGE,
  TIPS,
} from 'components/common/AlertCookie/constants'

export default {
  name: 'AlertCookie',
  data: vm => ({
    tips: TIPS,
    isMobile: window.MOBILE_VERSION,
    exclamationMark: EXCLAMATION_MARK_IMAGE,
    vuetifyColors: vm.$vuetify.theme.themes.light,
  }),
  methods: {
    reloadPage() {
      window.location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.alert-cookie {
  background-color: $ui-kit-bg-gray-0;

  &__body {
    max-width: 560px;
  }

  &_mobile {
    height: calc(100vh - 64px); // минус высота header
  }

  &_desktop {
    height: 793px;
    max-width: 1136px;
  }

  &__list {
    text-align: start;
    list-style: none;
  }

  &__list-item::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    margin-right: 12px;
    border-radius: $border-radius-circle;
    background-color: $ui-kit-bg-gray-60;
    flex-shrink: 0;
  }
}
</style>
