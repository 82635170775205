<template>
  <div v-if="historyExamplesList.length">
    <SideMenu
      v-if="!isMobile"
      title="Примеры отзывов"
      side-menu-width="667px"
      :value="isTopDialog(componentName)"
      side-menu-auto-scroll-y
      side-menu-is-block
      data-qa="review_modal_personal_history_list"
      @side-menu:click-close="$emit('review-personal:close')"
    >
      <div
        v-for="(entry, index) in historyExamplesList"
        :key="`${doctorOrLpu}-${index}`"
      >
        <ReviewPersonalHistoryItem
          :entry="entry"
          :is-last-entry="historyExamplesList.length - 1 === index"
        />
      </div>
    </SideMenu>
    <VDialog
      v-else
      fullscreen
      persistent
      hide-overlay
      no-click-animation
      transition="fade-transition"
      data-qa="review_modal_personal_history_list"
      :value="isTopDialog(componentName)"
    >
      <ModalLayout
        close-to
        title="Примеры отзывов"
        limit-title
        visible-single-divider
        @modal:back="$emit('review-personal:close')"
      >
        <div>
          <div
            v-for="(entry, index) in historyExamplesList"
            :key="`${doctorOrLpu}-${index}`"
          >
            <ReviewPersonalHistoryItem
              :entry="entry"
              :is-last-entry="historyExamplesList.length - 1 === index"
            />
          </div>
          <div class="px-2">
            <VBtn
              block
              depressed
              color="primary"
              class="my-6"
              @click="$emit('review-personal:close')"
            >
              Закрыть
            </VBtn>
          </div>
        </div>
      </ModalLayout>
    </VDialog>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { createDevNotice } from 'utils'
import SideMenu from 'components/desktop/core/SideMenu'
import ModalLayout from 'components/mobile/core/ModalLayout'
import DialogManager from 'components/common/mixins/DialogManager'
import ReviewPersonalHistoryItem from 'components/common/ReviewPage/components/common/ReviewPersonalHistoryItem'
import { PATIENT_TYPE_NAME, PERSONAL_HISTORY_EXAMPLE } from 'components/common/ReviewPage/constants'
import { useReviewPageDataStore, useReviewFormDataStore } from 'components/common/ReviewPage/stores'

export default {
  name: 'ReviewPersonalHistoryList',
  components: {
    SideMenu,
    ModalLayout,
    ReviewPersonalHistoryItem,
  },
  mixins: [
    DialogManager,
  ],
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isMobile: window.MOBILE_VERSION,
    componentName: 'ReviewPersonalHistoryList',
  }),
  computed: {
    ...mapState(useReviewPageDataStore, [
      'reviewObjectTypes',
    ]),
    ...mapState(useReviewFormDataStore, [
      'isSelectedPatientPersonally',
    ]),
    doctorOrLpu() {
      return this.reviewObjectTypes.isDoctor ? 'doctor' : 'lpu'
    },
    historyExamplesList() {
      try {
        return PERSONAL_HISTORY_EXAMPLE[this.doctorOrLpu][this.isSelectedPatientPersonally ? PATIENT_TYPE_NAME.personally : 'other']
      } catch {
        createDevNotice({
          module: 'ReviewPersonalHistoryList',
          description: 'Ошибка рендера модального окна',
          sentryPayload: {
            PERSONAL_HISTORY_EXAMPLE,
            doctorOrLpu: this.doctorOrLpu,
            isSelectedPatientPersonally: this.isSelectedPatientPersonally,
          },
        })

        return []
      }
    },
  },
  watch: {
    showDialog(newVal) {
      if (newVal) {
        this.openDialog(this.componentName)
      } else {
        this.closeAllDialogs()
      }
    },
  },
}
</script>
