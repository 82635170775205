import { SYMBOL_ACTIONS } from 'modules/BehavioralFactors/constants'

/**
 * @description
 *
 * Функция ищет действие, применяемое к символу/группе символов, на основе действия инпута
 *
 * @param { string } inputAction - действие инпута
 *
 * @return { string | undefined } - действие, применяемое к символу/группе символов
 * */

function getSymbolAction(inputAction) {
  const actionKey = Object.keys(SYMBOL_ACTIONS).find(key => inputAction.includes(key))

  return SYMBOL_ACTIONS[actionKey]
}

export default getSymbolAction
