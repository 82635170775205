import getFeedbackProgressCurrentStep from 'components/common/ReviewPage/functions/getFeedbackProgressCurrentStep'

/**
 * @description Возвращает текущее значение прогресса тултипа (в процентах)
 * в зависимости от кол-ва текста в отзыве.
 *
 * @param { number } reviewTextLength - длина текста
 *
 * @return { number } - прогресс тултипа в процентах (целое число).
 * */

function getFeedbackProgressValue(reviewTextLength) {
  const currentStep = getFeedbackProgressCurrentStep(reviewTextLength)

  const [percentRangeLeft, percentRangeRight] = currentStep.percentRange
  const [symbolsRangeLeft, symbolsRangeRight] = currentStep.symbolsRange

  const diffPercent = percentRangeRight === Infinity ? 100 : percentRangeRight - percentRangeLeft
  const diffSymbols = symbolsRangeRight - symbolsRangeLeft

  const diffCountSymbols = reviewTextLength - symbolsRangeLeft

  const coefficientPercent = (diffPercent / diffSymbols) * diffCountSymbols

  const progressValue = Math.floor(percentRangeLeft + coefficientPercent)

  return progressValue >= 100 ? 100 : progressValue
}

export default getFeedbackProgressValue
