<template>
  <VFadeTransition
    :duration="animationDuration"
    :hide-on-leave="hideOnLeave"
    mode="out-in"
    origin="center"
    appear
  >
    <VSheet
      :width="imageWidth"
      :max-width="imageMaxWidth"
      data-qa="file_uploader_preview"
      class="file-uploader-preview"
    >
      <VSheet
        :height="imageHeight"
        :max-height="imageMaxHeight"
        data-qa="file_uploader_preview_wrapper"
        class="file-uploader-preview__wrapper"
      >
        <div
          data-qa="file_uploader_preview_image_wrapper"
          class="file-uploader-preview__image-wrapper"
        >
          <img
            v-if="src && isImage"
            :src="imagePreviewSrc"
            :alt="fileName"
            data-qa="file_uploader_preview_image"
            class="file-uploader-preview__image"
          >
        </div>
        <VSheet
          data-qa="file_uploader_preview_button"
          class="file-uploader-preview__button-wrapper"
          color="transparent"
          width="32"
          height="32"
          rounded="circle"
          @click="handleClickButton"
        >
          <VBtn
            class="file-uploader-preview__button"
            width="24"
            height="24"
            color="grey darken-1"
            elevation="1"
            icon
          >
            <span class="ui-icon-trash ui-icon_fz_smaller" />
          </VBtn>
        </VSheet>
        <VFadeTransition
          :duration="animationDuration"
          mode="out-in"
          origin="center"
          appear
        >
          <VSheet
            v-if="statusOptions.icon || statusOptions.isProgress"
            :key="statusOptions.icon || 'progress'"
            :class="statusOptions.iconClasses"
            :width="statusOptions.iconWidth"
            :height="statusOptions.iconHeight"
            data-qa="file_uploader_preview_status_icon"
            class="file-uploader-preview__status-icon"
            rounded="circle"
            @click="debounceHandleClickStatusButton"
          >
            <VProgressCircular
              v-if="statusOptions.isProgress"
              :color="statusOptions.iconColor"
              :indeterminate="statusOptions.isProgressIndeterminate"
              :value="statusOptions.progressValue || 0"
              :size="statusOptions.iconSize"
              :width="statusOptions.progressWidth"
            />
            <VIcon
              v-else
              :color="statusOptions.iconColor"
              :size="statusOptions.iconSize"
            >
              {{ statusOptions.icon }}
            </VIcon>
          </VSheet>
        </VFadeTransition>
      </VSheet>
      <VFadeTransition
        :duration="animationDuration"
        mode="out-in"
        origin="center"
        appear
      >
        <div
          v-if="statusOptions.description"
          :key="statusOptions.description"
          :style="{ color: descriptionColor }"
          data-qa="file_uploader_preview_description"
          class="pt-1 ui-text ui-text_caption text-center"
          v-text="statusOptions.description"
        />
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="statusOptions.descriptionHtml"
          :key="statusOptions.descriptionHtml"
          :style="{ color: descriptionColor }"
          data-qa="file_uploader_preview_description"
          class="pt-1 ui-text ui-text_caption text-center"
          v-html="statusOptions.descriptionHtml"
        />
        <!-- eslint-enable vue/no-v-html -->
      </VFadeTransition>
    </VSheet>
  </VFadeTransition>
</template>

<script>
import { throttle } from 'utils'
import { MIME_TYPE_IMAGE_PREFIX } from 'constants/fileTypes'

import { IPreviewStatusOptions } from 'components/common/FileUploader/interfaces'

export default {
  name: 'FileUploaderPreview',
  props: {
    fileId: {
      type: Number,
      default: 0,
    },
    fileType: {
      type: String,
      default: '',
    },
    fileName: {
      type: String,
      default: '',
    },
    imageWidth: {
      type: [Number, String],
      default: 80,
    },
    imageHeight: {
      type: [Number, String],
      default: 80,
    },
    imageMaxWidth: {
      type: [Number, String],
      default: 'auto',
    },
    imageMaxHeight: {
      type: [Number, String],
      default: 'auto',
    },
    src: {
      type: String,
      default: '',
    },
    statusOptions: {
      type: Object,
      default: IPreviewStatusOptions,
    },
    hideOnLeave: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    animationDuration: 200,
    imagePreviewSrc: '',
    imagePreviewDefaultSize: 80,
  }),
  computed: {
    descriptionColor() {
      return this.$vuetify.theme.themes.light[this.statusOptions.descriptionColor]
    },
    debounceHandleClickStatusButton() {
      return throttle(() => {
        this.$emit('file-uploader-preview:click-status-button', this.fileId)
      }, this.animationDuration * 2, { strictMode: true })
    },
    isImage() {
      return this.fileType.indexOf(MIME_TYPE_IMAGE_PREFIX) !== -1
    },
  },
  mounted() {
    if (this.isImage && this.src) {
      this.createImagePreview()
    }
  },
  methods: {
    handleClickButton() {
      this.$emit('file-uploader-preview:click-button', this.fileId)
    },
    createImagePreview() {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      const image = document.createElement('img')
      const previewWidth = Number(this.imageWidth)
                || Number(this.imageMaxWidth)
                || this.imagePreviewDefaultSize
      const previewHeight = Number(this.imageHeight)
                || Number(this.imageMaxHeight)
                || this.imagePreviewDefaultSize

      canvas.width = previewWidth
      canvas.height = previewHeight

      // Ставим белый фон пока создаётся превью
      this.imagePreviewSrc = canvas.toDataURL()

      image.addEventListener('load', () => {
        const { width, height } = image
        const imageRatio = width / height

        if (width > height) {
          const proportionalHeight = previewWidth / imageRatio
          const offsetY = (previewHeight - proportionalHeight) / 2

          context.drawImage(image, 0, offsetY, previewWidth, proportionalHeight)
        } else {
          const proportionalWidth = previewHeight * imageRatio
          const offsetX = (previewWidth - proportionalWidth) / 2

          context.drawImage(image, offsetX, 0, proportionalWidth, previewHeight)
        }

        this.imagePreviewSrc = canvas.toDataURL()
      }, { once: true })

      image.src = this.src
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';
@import '~www/themes/doctors/common/mixins';

.file-uploader-preview {
  &__wrapper {
    position: relative;
    width: 100%;
  }

  &__image-wrapper {
    width: 100%;
    height: 100%;
    border: 1px solid $ui-kit-bg-gray-60;
    border-radius: $border-radius-md;
    overflow: hidden;
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__button-wrapper {
    position: absolute;
    transform: translate(50%, -50%);
    top: 4px;
    right: 4px;
    padding: 4px;
  }

  &__button {
    background-color: $ui-kit-bg-gray-0;
  }

  &__status-icon {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
