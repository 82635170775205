import { defineStore } from 'pinia'
import {
  getLpuData,
  getDoctorData,
  getCommonData,
  getReviewObjectTypes,
} from 'components/common/ReviewPage/interfaces'
import { REVIEW_OBJECT_TYPES } from 'components/common/ReviewPage/constants'

/**
 * Содержит общие данные страницы
 * */

const useReviewPageDataStore = defineStore('reviewPageDataStore', {
  state: () => ({
    lpuData: getLpuData(),
    doctorData: getDoctorData(),
    commonData: getCommonData(),
    reviewObjectTypes: getReviewObjectTypes(),
    reviewPageNode: null,
  }),
  getters: {
    reviewObjectType() {
      return this.reviewObjectTypes.isDoctor
        ? REVIEW_OBJECT_TYPES.doctor
        : REVIEW_OBJECT_TYPES.lpu
    },
    reviewObjectId() {
      return this.reviewObjectTypes.isDoctor ? this.doctorData.id : this.lpuData.id
    },
    reviewObjectName() {
      return this.reviewObjectTypes.isDoctor ? this.doctorData.fullName : this.lpuData.name
    },
    reviewObjectImageSrc() {
      return this.reviewObjectTypes.isDoctor ? this.doctorData.imageSrc : this.lpuData.imageSrc
    },
    reviewObjectLink() {
      return this.reviewObjectTypes.isDoctor ? this.doctorData.doctorLink : this.lpuData.lpuLink
    },
  },
  actions: {
    updateReviewPageNode(payload) {
      this.reviewPageNode = payload
    },
    updateMedtochkaUrlOnReview(payload) {
      this.commonData.medtochkaUrlOnReview = payload
    },
    updateIsReviewOnModerateOrCanceled(payload) {
      this.commonData.isReviewOnModerateOrCanceled = payload
    },
  },
})

export default useReviewPageDataStore
