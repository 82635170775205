/* Данные адреса клиники */
export const getLpuAddress = () => ({
  id: null,
  lpuId: null,
  lpuName: null,
  address: '',
  townId: '',
  townName: '',
  townTranslit: '',
})

/* Данные о клинике */
export const getLpuData = () => ({
  id: null,
  name: '',
  imageSrc: '',
  speciality: '', // 'Стоматология'
  townName: '',
  townTranslit: '',
  address: '',
  lpuAddresses: [], // [getLpuAddress, getLpuAddress, ...]
  lpuLink: '',
  isLpuTypeHospital: false,
})

/* Данные о враче */
export const getDoctorData = () => ({
  id: null,
  fullName: '',
  imageSrc: '',
  specialtiesList: [], // ['хирург', 'стоматолог']
  townId: '',
  townName: '',
  townTranslit: '',
  lpuAddresses: [], // [getLpuAddress, getLpuAddress, ...]
  doctorLink: '',
})

/* Общие данные страницы */
export const getCommonData = () => ({
  currentYear: '',
  currentMonth: '',
  reviewDataKey: '',
  medtochkaUrlOnReview: '',
  canSendReview: true,
  useCallConfirmation: true,
  isReviewOnModerateOrCanceled: false,
  isReviewSourcePrs: false, // true, значит пользователь перешёл в форму по программе ПРС
  isPdMobile: false, // true, значит пользователь перешёл в форму с планшета ПДМобиля
  prsDiscount: 0,
  isRussiaDomain: true,
})

/**
 * Данные из черновика
 *
 * type Form = {
 *     selectedPatient: string,
 *     otherPatientFromReview: string,
 *     selectedLpuAddressId: number,
 *     otherLpuNameAndAddress: string,
 *     selectedDate: string,
 *     comment: string,
 *     commentPlus: string,
 *     commentMinus: string,
 * }
 *
 * type LpuRating = {
 *     wait: number,
 *     staff: number,
 *     building: number,
 *     equipment: number,
 *     comfort: number,
 * }
 *
 * type DoctorRating = {
 *     friendly: number,
 *     recommend: number,
 *     inspection: number,
 *     efficiency: number,
 *     informative: number,
 * }
 *
 * type Rating = LpuRating | DoctorRating
 *
 * type Info = {
 *     dateModified: string,
 *     timeModified: string,
 * }
 *
 * type DraftData = {
 *     form: Form,
 *     rating: Rating,
 *     info: Info,
 * }
 */
export const getDraftData = () => ({
  form: {},
  rating: {},
  info: {},
})

/* Данные пользователя */
export const getInitUserData = () => ({
  phone: '',
  email: '',
  isLogged: false,
  isSetEmailFirstTime: false,
  isPhoneConfirmedByCall: false,
})

/* Тип объекта, на который происходит оставление отзыва */
export const getReviewObjectTypes = () => ({
  isLpu: null,
  isDoctor: null,
})

/**
 * Список загружаемых изображений
 *
 * interface IAttachedImage {
 *     file: File,
 *     id: number,
 *     isDocument: boolean,
 *     isSending: boolean,
 * }
 *
 * type getAttachedImages = Array<IAttachedImage>
 * */
export const getAttachedImages = () => []

/**
 * Список статусов загружаемых изображений
 * IPreviewStatusOptions — см `www/vue_components/common/FileUploader/interfaces/index.js`
 *
 * type getAttachedImagesStatuses = Array<IPreviewStatusOptions>
 * */
export const getAttachedImagesStatuses = () => []

/**
 * Настройки (ограничения) загружаемых изображений
 *
 * type TFileType = string
 *
 * type getAttachedImagesStatuses = {
 *    maxAttachedImages: number,
 *    maxSizeMb: number,
 *    maxOverallSizeMb: number,
 *    minResolution: {
 *       width: number,
 *       height: number,
 *    },
 *    allowedFileTypes: Array<TFileType>,
 * }
 * */
export const getImagesOptions = () => ({
  maxAttachedImages: null,
  maxSizeMb: null,
  maxOverallSizeMb: null,
  minResolution: {
    width: null,
    height: null,
  },
  allowedFileTypes: [],
})
