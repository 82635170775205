import BrowserTab from 'modules/BrowserTab'
import UserStorage from 'modules/UserStorage'
import {
  deepMerge,
  callChain,
  axiosClient,
  createDevLog,
  getCSRFToken,
  isEmptyObject,
  getBrowserCore,
  getSymbolAtPos,
  createDevNotice,
  isUsedLocalStorage,
  serializeObject,
} from 'utils'

import {
  MODULE_KEY,
  ERROR_MESSAGES,
  SYMBOL_ACTIONS,
  BROWSER_TABS_DATA,
  OBSERVED_NODE_TYPES,
  UNSUPPORTED_BROWSER_CORES,
} from 'modules/BehavioralFactors/constants'

import {
  getNodeName,
  isIncludeField,
  getSymbolAction,
  getSymbolsChanges,
  getDefaultOptions,
  hasClosestObservedNode,
  getInitObservedNodesProps,
} from 'modules/BehavioralFactors/functions'

/**
 * @author Быков Александр(@bykov)
 * */

class BehavioralFactors {
  _options = {}

  tabs = new BrowserTab({ key: BROWSER_TABS_DATA.inner })

  isDataSent = false

  browser = getBrowserCore()

  usedLocalStorage = isUsedLocalStorage()

  get options() {
    return this._options
  }

  set options(options) {
    this._options = deepMerge(
      getDefaultOptions(),
      options,
    )
  }

  constructor(options) {
    if (UNSUPPORTED_BROWSER_CORES.includes(this.browser)) {
      return
    }

    this.options = options

    callChain({
      context: this,
      chain: [
        [this._createUniqueKey],
        [this._createStorage],
        [this._createData],
        [this._createPropsInputsTracked],
        [this._setPropsInputsTracked],
        [this._createSessionCommon],
        [this._switchEventsListeners, ['add']],
      ],
    })
  }

  send() {
    /**
         * Обязательные условия для работы метода:
         * 1. Данные еще не отправлялись
         * 2. Движок — поддерживаемый
         * */

    if (this.isDataSent || UNSUPPORTED_BROWSER_CORES.includes(this.browser)) {
      return
    }

    this._finishSessionCommon()
    this._updateStorage()

    return this._createRequest()
  }

  /**
     * Обработчики событий
     * */

  _eventClick = event => {
    this._observerEventData(event)
  }

  _eventFocus = event => {
    /**
         * Используется в абстракции.
         * Время проведённое пользователем в одном поле по принципу "blur - focus"
         * */

    this.focusTimeStamp = Date.now()
    this._observerEventData(event)
  }

  _eventBlur = event => {
    this._observerEventData(event)
  }

  _eventSubmit = event => {
    this._observerEventData(event)
    this.send()
  }

  _eventUnload = () => {
    this._finishSessionCommon()
    this._updateStorage()
  }

  _eventSelect = event => {
    this._observerEventData(event)
    this._addSelectionData(event)
  }

  _eventCopy = event => {
    this._observerEventData(event)
    this._addCopiedData(event)
  }

  _eventInput = event => {
    this._observerEventData(event)
    this._addInputData(event)
  }

  /**
     * Данные событий определенного типа
     * */

  _addInputData(event) {
    const {
      target,
      inputType: typeInput = '',
      type: typeEvent,
    } = event

    if (!hasClosestObservedNode(target)) {
      return
    }

    const { includeFields } = this.options
    const targetName = getNodeName(target)

    if (!isIncludeField(targetName, includeFields)) {
      return
    }

    const {
      type: typeField,
      value: targetValue,
      defaultValue: targetDefaultValue,
    } = target

    const { fields } = this.data.session
    const currentField = fields[targetName] || []

    fields[targetName] = currentField

    /**
         * Базовые значения события ввода.
         * Самые общие
         * */

    const baseData = {
      timeStamp: Date.now(),
      targetValue,
    }

    /**
         * Предварительный объект с общими данными ввода.
         * Общие данные всех полей ввода.
         * Эти данные отправляются в более общий массив fields
         * */

    const extendedInputData = {
      ...baseData,
      typeField,
      typeEvent,
      typeInput,
    }

    /**
         * Не у всех полей есть тип ввода.
         * Например, изменения в поле file означают, что дальше собирать нечего
         * */

    if (!typeInput) {
      currentField.push(extendedInputData)
      return
    }

    /**
         * Здесь обрабатывается только текст.
         * Обработка базируется на значении по умолчанию одного поля.
         * Это означает, что значения данных из сессии в сессию взаимосвязаны.
         * Значения истинности данных каждой сессии отдельно здесь не вычисляется
         * */

    const currentFieldLast = currentField.length ? currentField[currentField.length - 1] : null
    const indexLast = target.selectionStart
    const indexPrev = currentFieldLast ? currentFieldLast.indexLast : indexLast
    const oldTargetValue = currentFieldLast ? currentFieldLast.targetValue : targetDefaultValue

    const { quantityChanges, changeSymbols } = getSymbolsChanges({
      targetValue,
      oldTargetValue,
    })

    /**
         * Подсчитывается количество введённых символов в одной сессии.
         * Вычисленное значение потребуется для валидации.
         * Значение может быть отрицательным, если пользователь удалил больше, чем ввёл
         * */

    const changeSymbolAction = getSymbolAction(typeInput.toLowerCase())

    if (Object.values(SYMBOL_ACTIONS).includes(changeSymbolAction)) {
      const sign = Math.sign(targetValue.length - oldTargetValue.length)

      this.data.session.quantityInputSymbols += sign * quantityChanges
    }

    /**
         * Дополняется данными о событии ввода и тексте
         * */

    currentField.push({
      ...extendedInputData,
      oldTargetValue,
      changeSymbolAction,
      changeSymbols,
      selectionSymbol: targetValue.length ? getSymbolAtPos(targetValue, indexLast - 1) : '',
      indexLast,
      indexPrev,
      quantityChanges,
    })
  }

  _addCopiedData({ target }) {
    if (!hasClosestObservedNode(target)) {
      return
    }

    const { copied } = this.data.session
    const { includeFields } = this.options
    const targetName = getNodeName(target)

    if (!isIncludeField(targetName, includeFields)) {
      return
    }

    copied[targetName] = copied[targetName] || []
    copied[targetName].push({
      timeStamp: Date.now(),
      copiedData: window.getSelection().toString(),
    })
  }

  _addSelectionData({ target }) {
    if (!hasClosestObservedNode(target)) {
      return
    }

    const { selection } = this.data.session
    const { includeFields } = this.options
    const {
      selectionEnd,
      selectionStart,
      selectionDirection,
      value,
    } = target
    const targetName = getNodeName(target)

    if (!isIncludeField(targetName, includeFields)) {
      return
    }

    selection[targetName] = selection[targetName] || []
    selection[targetName].push({
      timeStamp: Date.now(),
      selectionText: value.slice(selectionStart, selectionEnd),
      selectionDirection,
    })
  }

  /**
     * Общие данные о событиях
     * */

  /**
     * Метод прослушивает, собирает и добавляет общие данные о событии.
     * Предназначен для всех типов событий
     * */

  _observerEventData(event) {
    this._createEventData(event)
    this._addEventData(event)
  }

  _createEventData({ type, target }) {
    /**
         * Особенности кроссбраузерности FireFox
         * */
    if (!target.closest) {
      return
    }

    const timeStamp = Date.now()

    this.eventData = {
      abstraction: '',
      eventType: type,
      timeForField: type === 'blur' ? timeStamp - this.focusTimeStamp : null,
      timeStamp,
    }

    /**
         * Проверка, что был не клик, чтобы отправка не дублировалась.
         * Например, когда произошел focus и click одновременно
         * */

    const input = target.closest(OBSERVED_NODE_TYPES.input)

    if (input && type !== 'click') {
      this.eventData.abstraction = OBSERVED_NODE_TYPES.input
      return
    }

    /**
         * Абстракция textarea - клик не учитывается
         * */

    const textarea = target.closest(OBSERVED_NODE_TYPES.textarea)

    if (textarea && type !== 'click') {
      this.eventData.abstraction = OBSERVED_NODE_TYPES.textarea
      return
    }

    if (type === 'focus' || type === 'blur') {
      return
    }

    /**
         * Теперь обрабатывается клик в поле.
         * Он обрабатывается после
         * */

    if ((input || textarea) && type === 'click') {
      return
    }

    const link = target.closest('a')

    if (link && link.href && link.href !== '#') {
      /**
             * Клик по ссылке вызывает фокус.
             * Также пользователь может вызвать тут же blur
             * */

      if (type !== 'focus' || type !== 'blur') {
        this.eventData.abstraction = 'link'
      }

      return
    }

    /**
         * Перебираются те абстракции, которые переданы в параметрах настройки
         * */

    const { abstractions } = this.options
    const [abstractionKey] = Object.entries(abstractions).find(([, value]) => target.closest(value)) || []

    this.eventData.abstraction = abstractionKey || 'other'
  }

  _addEventData(event) {
    /**
         * Обязательно наличие объекта абстракции.
         * Например, данные не добавляются, если произошёл focus или blur.
         * В методе _createEventData видно, когда поле пустое
         * */

    if (!this.eventData?.abstraction) {
      return
    }

    /**
         * Перед добавлением поля в хранилище, можно добавить дополнительные данные.
         * */

    const { session, storage } = this.data

    this.options.beforeAddedEventData({
      storage, session, eventData: this.eventData, event,
    })

    session.events.push(this.eventData)
  }

  /**
     * Отправка данных
     * */

  _createRequest() {
    const requests = []

    if (!this.options.sendPaths.length) {
      return
    }

    /**
         * В зависимости от возможности использования localStorage
         * берутся либо данные из localStorage, либо сессионные данные.
         * Если сессионные, то они приводятся к единому формату — массив с сессионными данными
         * */

    const data = this.usedLocalStorage ? this.data.storage : [this.data.session]

    if (!data.length) {
      return
    }

    /**
         * Последняя сессия имеет данные, собранные другим классом.
         * В эту сессию добавляются данные
         * об истории переходов пользователя по вкладкам на одном домене
         * */

    const sessionLast = data[data.length - 1]
    const tabsStorage = this.tabs.getInstanceStorage().get()

    sessionLast.tabs = tabsStorage ? tabsStorage.history : []
    sessionLast.date = new Date()

    this.options.beforeSendData({ data })
    sessionLast.validation = this._getValidationData()

    const sentSuccessHook = this._sentSuccess.bind(this)
    const serializedData = serializeObject({
      sessions: JSON.stringify(data),
      csrfmiddlewaretoken: getCSRFToken(),
      [sessionLast.common.pageType]: sessionLast.common.pageId,
      key: sessionLast.common.pageKey,
    })

    this.options.sendPaths.forEach(({ path, isManagingProgress }) => {
      if (!path) {
        createDevLog({
          module: 'modules/BehavioralFactors',
          title: '_createRequest',
          message: 'Отсутствует обязательное поле "path"',
        })

        return
      }

      const request = axiosClient.post(path, serializedData, this.options.requestConfig)

      request.then(sentSuccessHook)
        .catch(this._sentError)

      requests.push({ isManagingProgress, request })
    })

    return requests
  }

  _sentSuccess({ data }) {
    /**
         * Удаляются обработчики.
         * Очищаются массив переходов и массив собранных данных.
         * Блокируется возможность делать отправку более 1 раза
         * */

    this.tabs.detach()
    this.tabs.getInstanceStorage().remove()
    this.storage.remove()

    this.isDataSent = true
    this._switchEventsListeners('remove')

    this.options.afterSendingData({ response: data })
  }

  _sentError({ message }) {
    createDevNotice({
      description: message,
      method: '_sentError',
      module: 'modules/BehavioralFactors',
      sentryPayload: message,
    })
  }

  /**
     * Storage - хранилище пользователя
     * Методы работы с ним
     * */

  _updateStorage() {
    const { session } = this.data
    const { maxSessionQuantity, minQuantitySessionEvents } = this.options.validation

    if (!this.usedLocalStorage) {
      return
    }

    if (session.events.length < minQuantitySessionEvents) {
      return
    }

    if (this.data.storage.length > maxSessionQuantity) {
      return
    }

    this.data.storage.push(session)

    try {
      this.storage.set(this.data.storage)
    } catch ({ message }) {
      this.storage.clear()
    }
  }

  /**
     * Общие методы
     * */

  _switchEventsListeners(method) {
    const action = method === 'add' ? 'addEventListener' : 'removeEventListener'

    window[action]('unload', this._eventUnload)

    document[action]('copy', this._eventCopy)
    document[action]('input', this._eventInput)
    document[action]('select', this._eventSelect)
    document[action]('blur', this._eventBlur, this.options.capture)
    document[action]('focus', this._eventFocus, this.options.capture)
    document[action]('click', this._eventClick, this.options.capture)

    if (!this.options.submitListener) {
      return
    }

    document[action]('submit', this._eventSubmit)
  }

  /**
     * Проверки
     * */

  _getValidationData() {
    const data = this.storage.get()

    let {
      minQuantityDatasetEvents,
      minQuantityInputSymbols,
    } = this.options.validation

    const isValidQuantityInputSymbols = data?.every?.(({ events, quantityInputSymbols }) => {
      /**
             * Значением может быть undefined,
             * если пользователь заходил, когда не было валидации.
             * В таком случае, не получится отследить количество символов.
             * Валидация считается не пройденной
             * */

      if (typeof quantityInputSymbols !== 'number') {
        return false
      }

      minQuantityDatasetEvents -= events.length
      minQuantityInputSymbols -= quantityInputSymbols

      return true
    })

    return isValidQuantityInputSymbols && (
      minQuantityDatasetEvents <= 0
            || minQuantityInputSymbols <= 0
    )
  }

  /**
     * Работа с сессиями
     * */

  _createSessionCommon() {
    this.data.session.common = {
      referrer: document.referrer,
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      date: {
        start: Date.now(),
      },
    }

    /**
         * Вызов коллбека для возможности редактирования объекта common вне класса
         * */

    this.options.afterCreatedStartedData({ session: this.data.session })
  }

  _finishSessionCommon() {
    const { date } = this.data.session.common

    date.end = Date.now()
    date.full = new Date()
    date.duration = date.end - date.start
  }

  /**
     * Отслеживание инпутов
     * */

  /**
     * Собираются все отслеживаемые поля ввода данных.
     * Формируется структура этих данных на основе названия полей
     * */

  _createPropsInputsTracked() {
    const { includeFields } = this.options
    const inputs = Array.from(document.getElementsByTagName(OBSERVED_NODE_TYPES.input))
    const textareas = Array.from(document.getElementsByTagName(OBSERVED_NODE_TYPES.textarea))

    const setFieldsEveryInput = nodes => {
      nodes.forEach(node => {
        const name = getNodeName(node)

        if (isIncludeField(name, includeFields)) {
          this.tracked.copied[name] = []
          this.tracked.fields[name] = []
          this.tracked.selection[name] = []
        }
      })
    }

    /**
         * Объекты session
         * Все отслеживаемые типы ввода данных на странице
         * */

    this.tracked = getInitObservedNodesProps()

    setFieldsEveryInput(inputs)
    setFieldsEveryInput(textareas)

    Object
      .entries(this.options.defaultFieldsData)
      .forEach(([key, value]) => { this.tracked.fields[key] = value })
  }

  /**
     * Выполняется слияние хранилища со сформированной структурой.
     * Также учитывается возможность использования хранилища.
     *
     * Алгоритм работы:
     * 1. Если НЕ используется глобальное хранилище, или оно пустое,
     *    то в сессионный объект добавляется объект с полями
     * 2. Иначе, из последней сессии берётся объект
     * 3. Если объект последней сессии пустой, то в сессионный объект добавляется объект с полями
     * 4. Иначе, в сессионный объект добавляется объект последней сессии
     * */

  _setPropsInputsTracked() {
    const { storage, session } = this.data

    if (!this.usedLocalStorage || !storage.length) {
      Object.assign(session, this.tracked)

      return
    }

    const lastSession = storage[storage.length - 1]

    Object.entries(this.tracked).forEach(([key, value]) => {
      const lastSessionData = lastSession[key] || {}

      session[key] = isEmptyObject(lastSessionData) ? value : lastSessionData
    })
  }

  _createUniqueKey() {
    if (this.options.uniqueId) {
      this.key = `${MODULE_KEY}-${this.options.uniqueId}`
      return
    }

    try {
      new Error(ERROR_MESSAGES.requiredUniqueId)
    } catch ({ message }) {
      createDevLog({
        module: 'BehavioralFactors',
        title: '_createUniqueKey',
        message,
      })
    }
  }

  _createStorage() {
    this.storage = new UserStorage({
      key: this.key,
      data: [],
    })
  }

  _createData() {
    this.data = {
      storage: this.usedLocalStorage ? this.storage.get() : [],
      session: {
        common: {},
        events: [],
        quantityInputSymbols: 0,
        ...getInitObservedNodesProps(),
      },
    }
  }
}

export default BehavioralFactors
