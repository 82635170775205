var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-qa": "review_images" } },
    [
      _c(
        "div",
        {
          staticClass: "ui-text ui-text_subtitle-1 ui-kit-color-text mb-4",
          attrs: { "data-qa": "review_images _title" },
        },
        [_vm._v("\n    Прикрепите к отзыву\n  ")]
      ),
      _vm._v(" "),
      _c(
        "VExpansionPanels",
        { staticClass: "mb-4", attrs: { multiple: "" } },
        [
          _c(
            "VExpansionPanel",
            [
              _c(
                "VExpansionPanelHeader",
                {
                  staticClass: "p-4",
                  attrs: { "expand-icon": "ui-icon-arrow-down" },
                },
                [
                  _c("div", { staticClass: "mr-4" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "ui-text ui-text_subtitle-1 ui-kit-color-text",
                      },
                      [_vm._v("\n            Документы из клиники\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "ui-text ui-text_body-1 ui-kit-color-text-secondary",
                      },
                      [
                        _vm._v(
                          "\n            подтвердят честность отзыва\n          "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "VExpansionPanelContent",
                { staticClass: "mx-n2" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center ui-text ui-text_body-2 ui-kit-color-text mb-3",
                    },
                    [
                      _c("span", {
                        staticClass: "ui-icon-eye-close ui-icon_fz_medium mr-2",
                      }),
                      _vm._v("\n          Увидят только модераторы\n        "),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ui-text ui-text_body-1 ui-kit-color-text mb-3",
                    },
                    [
                      _vm._v(
                        "\n          Заключение, рецепт или другой документ с фамилией врача и печатью.\n          Имя пациента можно прикрыть.\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.commonData.isRussiaDomain
                    ? _c(
                        "BannerInfo",
                        { attrs: { "inner-atom-indent": 0 } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "ui-text ui-text_body-2 ui-kit-color-text",
                            },
                            [
                              _vm._v(
                                "\n            Если врач или клиника решат оспорить отзыв в суде,\n            мы защитим вас и возместим расходы.\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("ReviewProtectionProgram", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "button",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "d-inline b-link b-link_underline b-link_color_primary-blue",
                                              attrs: {
                                                type: "button",
                                                "data-qa":
                                                  "review_documents_button",
                                              },
                                            },
                                            "button",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                О программе защиты\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2274791838
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "VExpansionPanel",
            [
              _c(
                "VExpansionPanelHeader",
                {
                  staticClass: "p-4",
                  attrs: { "expand-icon": "ui-icon-arrow-down" },
                },
                [
                  _c("div", { staticClass: "mr-4" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "ui-text ui-text_subtitle-1 ui-kit-color-text",
                      },
                      [_vm._v("\n            Фото\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "ui-text ui-text_body-1 ui-kit-color-text-secondary",
                      },
                      [
                        _vm._v(
                          "\n            сделают отзыв интереснее\n          "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("VExpansionPanelContent", { staticClass: "mx-n2" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center ui-text ui-text_body-2 ui-kit-color-text mb-3",
                  },
                  [
                    _c("span", {
                      staticClass: "ui-icon-eye-open ui-icon_fz_medium mr-2",
                    }),
                    _vm._v("\n          Увидят все\n        "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ui-text ui-text_body-1 ui-kit-color-text" },
                  [
                    _vm._v(
                      "\n          Результаты лечения, до и после — всё, что проиллюстрирует ваш отзыв.\n        "
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("FileUploader", {
        attrs: {
          "activator-text": "Прикрепить документ или фото",
          "activator-disabled-text": "Нельзя прикрепить больше 10 файлов",
          "picker-props": {
            allowedFileExtensions: _vm.allowedFileExtensions,
            fileValidationRules: _vm.fileValidationRules,
            imageValidationRules: _vm.imageValidationRules,
            loadFileError: _vm.imagesErrors[_vm.FILE_ERROR_TYPES.uploadPhoto],
          },
          "max-attached-files": _vm.imagesOptions.maxAttachedImages,
          "upload-handler": _vm.requestDocumentRecognition,
          "preview-statuses-options": _vm.attachedImagesStatuses,
          "init-attached-files": _vm.attachedImages,
          "use-load-from-camera": _vm.isMobile,
          "min-count-preview-images-on-line": 3,
          "preview-image-max-width": 138,
          "preview-image-max-height": 138,
          "preview-image-width": "auto",
          "preview-image-height": "auto",
          "min-height": "120",
          "use-load-from-gallery": "",
          "is-top-picker": "",
        },
        on: {
          "file-uploader:add-file": _vm.addAttachedImage,
          "file-uploader:delete-file": _vm.deleteAttachedImage,
          "file-uploader:upload-pending": _vm.handleUploadPending,
          "file-uploader:upload-progress": _vm.handleUploadProgress,
          "file-uploader:upload-complete": _vm.handleUploadComplete,
          "file-uploader:upload-success": _vm.handleUploadSuccess,
          "file-uploader:upload-error": _vm.handleUploadError,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }