var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.historyExamplesList.length
    ? _c(
        "div",
        [
          !_vm.isMobile
            ? _c(
                "SideMenu",
                {
                  attrs: {
                    title: "Примеры отзывов",
                    "side-menu-width": "667px",
                    value: _vm.isTopDialog(_vm.componentName),
                    "side-menu-auto-scroll-y": "",
                    "side-menu-is-block": "",
                    "data-qa": "review_modal_personal_history_list",
                  },
                  on: {
                    "side-menu:click-close": function ($event) {
                      return _vm.$emit("review-personal:close")
                    },
                  },
                },
                _vm._l(_vm.historyExamplesList, function (entry, index) {
                  return _c(
                    "div",
                    { key: `${_vm.doctorOrLpu}-${index}` },
                    [
                      _c("ReviewPersonalHistoryItem", {
                        attrs: {
                          entry: entry,
                          "is-last-entry":
                            _vm.historyExamplesList.length - 1 === index,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _c(
                "VDialog",
                {
                  attrs: {
                    fullscreen: "",
                    persistent: "",
                    "hide-overlay": "",
                    "no-click-animation": "",
                    transition: "fade-transition",
                    "data-qa": "review_modal_personal_history_list",
                    value: _vm.isTopDialog(_vm.componentName),
                  },
                },
                [
                  _c(
                    "ModalLayout",
                    {
                      attrs: {
                        "close-to": "",
                        title: "Примеры отзывов",
                        "limit-title": "",
                        "visible-single-divider": "",
                      },
                      on: {
                        "modal:back": function ($event) {
                          return _vm.$emit("review-personal:close")
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _vm._l(
                            _vm.historyExamplesList,
                            function (entry, index) {
                              return _c(
                                "div",
                                { key: `${_vm.doctorOrLpu}-${index}` },
                                [
                                  _c("ReviewPersonalHistoryItem", {
                                    attrs: {
                                      entry: entry,
                                      "is-last-entry":
                                        _vm.historyExamplesList.length - 1 ===
                                        index,
                                    },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "px-2" },
                            [
                              _c(
                                "VBtn",
                                {
                                  staticClass: "my-6",
                                  attrs: {
                                    block: "",
                                    depressed: "",
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("review-personal:close")
                                    },
                                  },
                                },
                                [_vm._v("\n            Закрыть\n          ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                1
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }