import { FEEDBACK_PROGRESS_STEPS } from 'components/common/ReviewPage/constants'

/**
 * @description Возвращает текущее состояние тултипа в зависимости от кол-ва текста в отзыве
 *
 * @param { number } reviewTextLength - длина текста
 *
 * @return { Object } feedbackProgressStep - текущее состояние тултипа
 * */

function getFeedbackProgressCurrentStep(reviewTextLength) {
  const currentStepIndex = FEEDBACK_PROGRESS_STEPS.findIndex(step => {
    const [symbolsRangeLeft, symbolsRangeRight] = step.symbolsRange

    return reviewTextLength >= symbolsRangeLeft && reviewTextLength < symbolsRangeRight
  })

  return FEEDBACK_PROGRESS_STEPS[currentStepIndex]
}

export default getFeedbackProgressCurrentStep
