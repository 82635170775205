import {
  TFileExtensions,
  TFileValidationRules,
  TImageValidationRules,
} from 'components/common/FilePicker/interfaces'

/**
 * type IError = {
 *     title: String,
 *     contentText: String,
 *     confirmText: String,
 *     closeText: String,
 *     confirmHandler: () => void,
 *     closeHandler: () => void,
 * }
 * */

const IError = () => ({
  title: '',
  contentText: '',
  confirmText: '',
  closeText: '',
  confirmHandler: () => {},
  closeHandler: () => {},
})

/**
 * interface IPickerProps {
 *     additionalValidationParams: Object,
 *     allowedFileExtensions: TFileExtensions,
 *     fileValidationRules: TFileValidationRules,
 *     imageValidationRules: TImageValidationRules,
 *     isConvertToBase64: Boolean,
 *     loadFileError: IError,
 * }
 * */
const IPickerProps = () => ({
  additionalValidationParams: {},
  allowedFileExtensions: TFileExtensions(),
  fileValidationRules: TFileValidationRules(),
  imageValidationRules: TImageValidationRules(),
  isConvertToBase64: false,
  loadFileError: IError(),
})

/**
 * interface IPreviewStatusOptions {
 *     description: String,
 *     descriptionColor: String,
 *     icon: String,
 *     iconColor: String,
 *     iconClasses: String | Object,
 *     iconWidth: Number | String,
 *     iconHeight: Number | String,
 *     iconSize: Number | String,
 *     isProgress: Boolean,
 *     isProgressIndeterminate: Boolean,
 *     progressValue: Number | String,
 *     progressWidth: Number | String,
 * }
 * */
const IPreviewStatusOptions = () => ({
  description: '',
  descriptionHtml: '',
  descriptionColor: '',
  icon: '',
  iconColor: '',
  iconClasses: null,
  iconWidth: null,
  iconHeight: null,
  iconSize: null,
  isProgress: false,
  isProgressIndeterminate: true,
  progressValue: 0,
  progressWidth: 4,
})

/**
 * type TPreviewStatusesOptions = Array<IPreviewStatusOptions>,
 * */
const TPreviewStatusesOptions = () => []

/**
 * type TInitAttachedFile = {
 *     file: File,
 *     base64File?: string,
 *     allowSend?: boolean,
 *     id: number,
 * }
 *
 * type TInitAttachedFiles = Array<TInitAttachedFile>
 * */
const TInitAttachedFiles = () => []

export {
  IPickerProps,
  IPreviewStatusOptions,
  IError,
  TPreviewStatusesOptions,
  TInitAttachedFiles,
}
