/**
 * @description
 *
 * Функция возвращаем имя (или id, или имя по умолчанию) для переданного элемента
 *
 * @param { HTMLElement } target
 * @param { string } target.name - имя элемента
 * @param { string } target.id - id элемента
 *
 * @return { string }
 * */

const getNodeName = ({ name, id }) => (name || id || '')

export default getNodeName
