export const EXCLAMATION_MARK_IMAGE = {
  src: '/static/_v1/pd/pictures/ui-kit/point.svg',
  alt: 'Иконка восклицательного знака',
}

export const TIPS = [
  'Войдите через другой браузер (рекомендуем Google Chrome)',
  'Отключите блокировку рекламы в настройках текущего браузера',
  'Разрешите файлы cookie в настройках текущего браузера',
  'Отключите защиту от отслеживания в настройках текущего браузера',
]

export const NODE_TO_HIDE_CLASS = 'js-node-to-hide-for-cookie'

export const MOUNT_ALERT_COOKIE_CLASS = 'alert-cookie'
