var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { class: _vm.isMobile ? "px-2" : "" },
        _vm._l(_vm.entries, function ({ title, text }, index) {
          return _c(
            "div",
            {
              key: `${title}-${index}`,
              attrs: { "data-qa": "review_modal_personal_history_item" },
            },
            [
              _c("div", {
                staticClass:
                  "ui-text ui-text_subtitle-1 ui-kit-color-text mt-4 mb-2",
                domProps: { textContent: _vm._s(title) },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "ui-text ui-body-1 ui-kit-color-text",
                domProps: { textContent: _vm._s(text) },
              }),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      !_vm.isLastEntry ? _c("VDivider", { staticClass: "mt-4" }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }