var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    ref: _vm.inputRefName,
    staticClass: "file-picker",
    attrs: {
      accept: _vm.allowedFileExtensions.join(", ") || _vm.MIME_TYPES.all,
      capture: _vm.isCaptureInput,
      "data-qa": "file_picker",
      type: "file",
    },
    on: {
      change: _vm.handleSelectedFile,
      focus: _vm.handleFocus,
      blur: _vm.handleBlur,
      keydown: _vm.handleKeydown,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }