<template>
  <div
    :data-is-exist-visible-alert="isExistsVisibleAlert"
    data-qa="review_textarea_alerts"
  >
    <VExpandTransition>
      <div v-show="inputData.selectedPatient">
        <BaseAlert
          class="mb-2 py-2"
          type="grey"
          icon="ui-icon-create"
          data-qa="review_alert_who_was_patient"
        >
          <div class="ui-text ui-text_body-2">
            <template v-if="reviewObjectTypes.isDoctor">
              <template v-if="inputData.selectedPatient === PATIENT_TYPE_NAME.personally">
                Пишите от первого лица: «Я обращался...,
                врач внимательно изучил мои анализы...».
              </template>
              <template v-else>
                Укажите в отзыве, кем вам приходится
                пациент: «были на приёме с сыном», «к
                врачу обращалась моя мама».
              </template>
            </template>
            <template v-else>
              <template v-if="inputData.selectedPatient === PATIENT_TYPE_NAME.personally">
                Пишите от первого лица: «Я обратился...,
                мне помогли...».
              </template>
              <template v-else>
                Укажите в отзыве, кем вам приходится
                пациент: «были в клинике с сыном», «в
                клинике проходила лечение моя мама».
              </template>
            </template>
          </div>
        </BaseAlert>
      </div>
    </VExpandTransition>
    <VExpandTransition>
      <div v-show="showPersonalHistoryAlert">
        <BaseAlert
          class="mb-2 py-2"
          type="yellow"
          icon="ui-icon-circle-warning"
          data-qa="review_alert_personal_history"
        >
          <div class="ui-text ui-text_body-2">
            <template v-if="reviewObjectTypes.isDoctor">
              Расскажите, почему обратились к врачу,
              как прошёл приём, помогло ли лечение.
              <div
                class="text-decoration-underline cursor-pointer mt-2"
                @click="isEnabledDialogHistoryExamples = true"
                v-text="'Примеры отзывов с личной историей'"
              />
            </template>
            <template v-else>
              Расскажите, как обратились в клинику, как
              прошёл визит, понравился ли сервис и
              отношение персонала.
              <div
                class="text-decoration-underline cursor-pointer mt-2"
                @click="isEnabledDialogHistoryExamples = true"
                v-text="'Примеры отзывов с личной историей'"
              />
            </template>
          </div>
        </BaseAlert>
      </div>
    </VExpandTransition>
    <VExpandTransition>
      <div v-show="fastValidationData.isFoundBadWords">
        <BaseAlert
          class="mb-2 py-2"
          type="red"
          icon="ui-icon-circle-warning"
          data-qa="review_alert_bad_words"
        >
          <div class="ui-text ui-text_body-2 mb-2">
            Мы нашли ругательства в тексте.
            Удалите или замените их и нажмите «Далее».
            Мы проверяем отзывы перед публикацией.
          </div>
          <button
            type="button"
            class="b-link b-link_underline b-link_color_coral ui-text ui-text_body-2 d-inline"
            data-qa="review_alert_bad_words_button"
            @click="updateFastValidationData({
              isFoundBadWords: false,
              isUserAgreeNonBadWords: true,
            })"
          >
            Всё в порядке, ругательств нет
          </button>
        </BaseAlert>
      </div>
    </VExpandTransition>
    <VExpandTransition>
      <div v-show="isVisibleAlertLengthNeed">
        <BaseAlert
          class="mb-2 py-2"
          type="red"
          icon="ui-icon-circle-warning"
          data-qa="review_alert_error_length"
        >
          <div class="ui-text ui-text_body-2">
            Отзыв слишком короткий.<br>
            Не хватает символов: {{ reviewTextLengthNeed }}
          </div>
        </BaseAlert>
      </div>
    </VExpandTransition>

    <ReviewPersonalHistoryList
      :show-dialog="isEnabledDialogHistoryExamples"
      @review-personal:close="isEnabledDialogHistoryExamples = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import YandexGoals from 'modules/YandexGoals'
import { PAGE_NEWRATE_YANDEX_GOALS } from 'yandexGoals'
import BaseAlert from 'components/common/core/BaseAlert'
import ReviewPersonalHistoryList from 'components/common/ReviewPage/components/common/ReviewPersonalHistoryList'
import { useReviewPageDataStore, useReviewFormDataStore } from 'components/common/ReviewPage/stores'
import { PATIENT_TYPE_NAME } from 'components/common/ReviewPage/constants'

export default {
  name: 'ReviewFormTextareaAlerts',
  components: {
    BaseAlert,
    ReviewPersonalHistoryList,
  },
  data: vm => ({
    PATIENT_TYPE_NAME,
    vuetifyColors: vm.$vuetify.theme.themes.light,
    isEnabledDialogHistoryExamples: false,
    yandexGoalsInstance: new YandexGoals(),
    isGoalsSentData: { // каждая yandex цель должна отправляться только 1 раз, поэтому используются эти флаги-переключатели
      personalHistoryAlert: false,
      personalHistoryModalInfo: false,
    },
  }),
  computed: {
    ...mapState(useReviewPageDataStore, [
      'reviewObjectTypes',
    ]),
    ...mapState(useReviewFormDataStore, [
      'inputData',
      'fastValidationData',
      'personalHistoryData',
      'isBeenValidationAttempted',
      'reviewTextLengthNeed',
      'isVisibleAlertLengthNeed',
    ]),
    isExistsVisibleAlert() {
      return (this.isBeenValidationAttempted && this.personalHistoryData.isPersonalHistory === false)
        || this.fastValidationData.isFoundBadWords
        || this.isVisibleAlertLengthNeed
    },
    showPersonalHistoryAlert() {
      return this.isBeenValidationAttempted
        && (this.personalHistoryData.isPersonalHistory === false
        || this.personalHistoryData.isPersonalHistory === null)
    },
  },
  watch: {
    showPersonalHistoryAlert(newVal) {
      if (newVal && !this.isGoalsSentData.personalHistoryAlert) {
        this.yandexGoalsInstance.send({ name: PAGE_NEWRATE_YANDEX_GOALS.commonPersonalHistoryShowAlert })
      }

      this.isGoalsSentData.personalHistoryAlert = true
    },
    isEnabledDialogHistoryExamples(newVal) {
      if (newVal && !this.isGoalsSentData.personalHistoryModalInfo) {
        this.yandexGoalsInstance.send({ name: PAGE_NEWRATE_YANDEX_GOALS.commonPersonalHistoryShowModalInfo })
      }

      this.isGoalsSentData.personalHistoryModalInfo = true
    },
  },
  methods: {
    ...mapActions(useReviewFormDataStore, [
      'updateFastValidationData',
    ]),
  },
}
</script>
