/**
 * @description
 *
 * Возвращает базовый объект с группами событий/типов, которые будет отслеживать модуль.
 *
 * @return { Object } объект с ключами групп
 * */

const getInitObservedNodesProps = () => ({
  copied: {}, // все, что скопировано на странице
  fields: {}, // все поля(input, textarea) на странице
  selection: {}, // все выделения текста на страницы
})

export default getInitObservedNodesProps
