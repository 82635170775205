<template>
  <BaseAlert
    type="green"
    class="pa-6"
    icon="ui-icon-car-prodoctorov"
    data-qa="review_page_pdmobile_banner"
  >
    <span class="ui-text ui-text_subtitle-1">
      ПроДокторов-мобиль
    </span>
  </BaseAlert>
</template>

<script>
import BaseAlert from 'components/common/core/BaseAlert'

export default {
  name: 'ReviewPdMobileBanner',
  components: {
    BaseAlert,
  },
  data: () => ({
    isNewAlert: false,
  }),
}
</script>
