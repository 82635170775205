<template>
  <component
    :is="ReviewPageComponent"
    v-bind="$attrs"
  />
</template>

<script>
import { mapActions } from 'pinia'
import { PAGE_NEWRATE_YANDEX_GOALS } from 'yandexGoals'
import {
  ReviewPageMergedProps,
  ReviewPageContextProps,
  ReviewPageBehavioralFactors,
} from 'components/common/ReviewPage/mixins'
import { useReviewPageDataStore } from 'components/common/ReviewPage/stores'
import { MOUNT_ID_REVIEW_PAGE } from 'components/common/ReviewPage/constants'
import { sendYaGoal } from 'components/common/ReviewPage/functions'

export default {
  name: 'ReviewPage',
  components: {
    ReviewPageMobile: () => import(/* webpackMode: "eager" */'components/common/ReviewPage/components/mobile/ReviewPage'),
    ReviewPageDesktop: () => import(/* webpackMode: "eager" */'components/common/ReviewPage/components/desktop/ReviewPage'),
  },
  mixins: [
    ReviewPageMergedProps,
    ReviewPageContextProps,
    ReviewPageBehavioralFactors,
  ],
  data: () => ({
    isMobile: window.MOBILE_VERSION,
  }),
  computed: {
    ReviewPageComponent() {
      return this.isMobile ? 'ReviewPageMobile' : 'ReviewPageDesktop'
    },
  },
  created() {
    sendYaGoal(PAGE_NEWRATE_YANDEX_GOALS[this.reviewObjectTypes.isLpu ? 'commonVisitLpu' : 'commonVisitDoctor'])
  },
  mounted() {
    this.updateReviewPageNode(document.getElementById(MOUNT_ID_REVIEW_PAGE))
  },
  methods: {
    ...mapActions(useReviewPageDataStore, [
      'updateReviewPageNode',
    ]),
  },
}
</script>
