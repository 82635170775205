<template>
  <div
    class="banner-info"
    :class="`p-${innerAtomIndent}`"
  >
    <div
      class="banner-info__content p-4"
      :class="{
        'banner-info__content_box-shadow': hasBoxShadow,
      }"
      :style="{ backgroundColor: background }"
    >
      <p
        v-if="title"
        class="banner-info__text ui-text ui-kit-color-text ui-text_subtitle-1"
        :class="{
          'banner-info__text_indented': indented,
        }"
      >
        {{ title }}
      </p>
      <p
        v-for="(paragraph, index) in paragraphs"
        :key="`paragraph-${index}`"
        class="banner-info__text ui-text ui-text_body-2 ui-kit-color-text"
        :class="{
          'banner-info__text_indented': indented,
          [`mt-${textOuterAtomIndent}`]: index !== 0 || title,
        }"
      >
        <template v-if="insertParagraphsLikeHtml">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="paragraph" />
        </template>
        <template v-else>
          {{ paragraph }}
        </template>
      </p>
      <slot />
      <slot name="button" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerInfo',
  props: {
    title: {
      type: String,
      default: '',
    },
    paragraphs: {
      type: Array,
      default: () => [],
    },
    background: {
      type: String,
      default: '#F6F9FF',
    },
    innerAtomIndent: {
      type: Number,
      default: 2,
    },
    indented: {
      type: Boolean,
      default: false,
    },
    textOuterAtomIndent: {
      type: Number,
      default: 5,
    },
    hasBoxShadow: {
      type: Boolean,
      default: false,
    },
    insertParagraphsLikeHtml: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';
@import '~www/themes/doctors/common/mixins';

.banner-info {
  box-sizing: border-box;

  &__content {
    width: 100%;
    overflow: hidden;
    position: relative;
    box-sizing: inherit;
    border-radius: $border-radius-sm;

    @include banner-info-background;

    &_box-shadow {
      @include new-elevation-1;
    }
  }

  &__text {
    margin: 0;
    white-space: normal;
    box-sizing: inherit;
  }

  &__text_indented {
    margin-right: 60px;
  }

  &__btn {
    display: inline-block;
    height: 28px;
    font-size: $font-size-caption;
    text-decoration: none;
    border-radius: $border-radius-sm;
    padding: 6px 12px;

    &_blue {
      background-color: $ui-kit-primary;
      color: $ui-kit-bg-gray-0;
    }
  }
}
</style>
