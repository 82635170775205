import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import ReviewFeedbackProgress from 'components/common/ReviewPage/components/common/ReviewFeedbackProgress'

/**
 * @description Создаёт контент для тултипа с прогрессом заполнения отзыва
 *
 * @return { function(): Element } ReviewFeedbackProgress - реактивный vue-компонент
 * */

function createTippyContent() {
  const VueInstance = new Vue({
    vuetify,
    el: document.createElement('div'),
    render: h => h(ReviewFeedbackProgress),
  })

  return () => VueInstance.$el
}

export default createTippyContent
