<template>
  <div
    class="review-page-layout"
    data-qa="review_page_layout"
  >
    <div
      class="review-page-layout__main"
      data-qa="review_page_layout_main"
    >
      <VCard elevation="1">
        <slot name="main" />
      </VCard>
    </div>
    <div
      v-if="$slots.aside"
      class="review-page-layout__aside"
      data-qa="review_page_layout_aside"
    >
      <VCard elevation="1">
        <slot name="aside" />
      </VCard>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewPageLayout',
}
</script>

<style lang="scss" scoped>
.review-page-layout {
  display: flex;

  &__main {
    flex-grow: 1;
  }

  &__aside {
    flex: 0 0 360px;
    margin-left: 24px;
  }
}
</style>
