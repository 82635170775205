<template>
  <div
    class="review-window-get-email"
    :class="{ 'review-window-get-email_mobile': isMobile }"
    data-qa="review_window_get_email"
  >
    <VDialog
      width="312"
      persistent
      no-click-animation
      :value="isWaitingToRepeatRequest"
    >
      <VCard class="pt-5 pb-4 p-6">
        <ReviewPreloader />
      </VCard>
    </VDialog>
    <ReviewGetUserEmailSuccess
      v-if="isVisibleReviewGetUserEmailSuccess"
    />
    <div
      v-else
      class="review-window-get-email__inner text-center"
    >
      <div class="my-auto">
        <VImg
          contain
          width="224"
          height="172"
          class="flex-grow-0 mx-auto mb-4"
          data-qa="review_window_get_email_img"
          alt="Дай пять - иллюстрация ПроДокторов"
          src="/static/_v1/pd/mascots/high-five.svg"
        />
        <div
          data-qa="review_window_get_email_title"
          class="ui-text ui-kit-color-text mb-2"
          :class="{
            'ui-text_h6': isMobile,
            'ui-text_h5': !isMobile,
          }"
        >
          Подскажите свою почту
        </div>
        <div
          class="ui-text ui-text_body-1 ui-kit-color-text mb-6"
          data-qa="review_window_get_email_description"
        >
          <template v-if="commonData.isReviewSourcePrs">
            Сообщим, когда опубликуем отзыв, и&nbsp;пришлём промокод
          </template>
          <template v-else>
            Будем держать вас в курсе того, что происходит с&nbsp;отзывом: опубликован или&nbsp;нет.
          </template>
        </div>
        <GetUserEmail
          ref="get-user-email"
          :error-handlers="errorHandlers()"
          @get-user-email:email-send-success="handleSubmitEmail"
          @get-user-email:email-confirmed="handleUserEmailConfirmed"
          @get-user-email:focus-input="isFocusedInput = true"
          @get-user-email:blur-input="isFocusedInput = false"
        />
      </div>
      <BottomPanel
        v-if="isVisibleBackBtnOnMobile"
        fixed
        no-height
      >
        <template #container>
          <div class="px-2 pt-2 pb-6">
            <VBtn
              text
              depressed
              color="uiKitText"
              data-qa="review_window_get_email_cancel"
              width="100%"
              @click="handleBackButtonClick"
            >
              <div class="ui-text ui-text_body-2 ui-kit-color-text-secondary">
                {{ backButtonText }}
              </div>
            </VBtn>
          </div>
        </template>
      </BottomPanel>
      <VBtn
        v-if="isVisibleBackBtnOnDesktop"
        text
        depressed
        color="uiKitText"
        data-qa="review_window_get_email_cancel"
        width="100%"
        @click="handleBackButtonClick"
      >
        <div class="ui-text ui-text_body-2 ui-kit-color-text-secondary">
          {{ backButtonText }}
        </div>
      </VBtn>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import GetUserEmail from 'components/common/GetUserEmail/GetUserEmail'
import ReviewGetUserEmailSuccess from 'components/common/ReviewPage/components/common/ReviewGetUserEmailSuccess'
import ReviewPreloader from 'components/common/ReviewPage/components/common/ReviewPreloader'
import BottomPanel from 'components/common/core/BottomPanel/BottomPanel'
import { sendYaGoal, showErrorDialog } from 'components/common/ReviewPage/functions'
import { GET_USER_EMAIL_YA_GOALS } from 'yandexGoals'
import {
  useUserDataStore,
  useReviewPageDataStore,
  useReviewFormDataStore,
} from 'components/common/ReviewPage/stores'

export default {
  name: 'ReviewGetUserEmail',
  components: {
    GetUserEmail,
    ReviewPreloader,
    ReviewGetUserEmailSuccess,
    BottomPanel,
  },
  data: () => ({
    isMobile: window.MOBILE_VERSION,
    isWaitingToRepeatRequest: false,
    isVisibleReviewGetUserEmailSuccess: false,
    isFocusedInput: false,
  }),
  computed: {
    ...mapState(useUserDataStore, [
      'userData',
    ]),
    ...mapState(useReviewPageDataStore, [
      'commonData',
    ]),
    backButtonText() {
      return this.userData.email ? 'Назад' : 'Отказаться'
    },
    isVisibleBackBtnOnMobile() {
      return this.isMobile && !this.isFocusedInput
    },
    isVisibleBackBtnOnDesktop() {
      return !this.isMobile
    },
  },
  mounted() {
    this.updateUserEmailFormRef(this.$refs['get-user-email'])
  },
  methods: {
    ...mapActions(useUserDataStore, [
      'updateUserData',
      'updateUserEmailFormRef',
      'resetUserAuthorization',
    ]),
    ...mapActions(useReviewFormDataStore, [
      'updateIsVisibleReviewGetUserEmail',
    ]),
    errorHandlers() {
      const featureDisabled = () => {
        showErrorDialog({
          title: 'У нас что-то сломалось',
          text: 'Попробуйте ещё раз.',
          beforeClose: () => {
            this.updateIsVisibleReviewGetUserEmail(false)
          },
          sentryMethod: 'errorHandlers',
          persistent: true,
        })
      }

      const authenticationFailed = actionAfterAuth => {
        this.resetUserAuthorization(actionAfterAuth)
      }

      const networkErrorEmail = () => {
        showErrorDialog({
          title: 'Нет соединения с интернетом',
          text: 'Проверьте соединение и попробуйте снова.',
        })
      }

      const networkErrorCode = ({ repeatRequest }) => {
        const waitToRepeatRequestMs = 2000

        showErrorDialog({
          title: 'Нет соединения с интернетом',
          text: 'Проверьте соединение и попробуйте снова.',
          beforeClose: () => {
            this.isWaitingToRepeatRequest = true
            setTimeout(() => {
              this.isWaitingToRepeatRequest = false
              repeatRequest()
            }, waitToRepeatRequestMs)
          },
        })
      }

      const unknownError = () => {
        showErrorDialog({
          title: 'У нас что-то сломалось',
          text: 'Попробуйте ещё раз.',
        })
      }

      return {
        email: {
          attemptsExceeded: () => {},
          authenticationFailed,
          networkError: networkErrorEmail,
          featureDisabled,
        },
        code: {
          authenticationFailed,
          networkError: networkErrorCode,
          featureDisabled,
        },
        unknownError,
      }
    },
    handleSubmitEmail() {
      sendYaGoal(GET_USER_EMAIL_YA_GOALS.codeSentToEmail)
    },
    handleUserEmailConfirmed(email) {
      if (this.userData.email) {
        this.updateUserData({ isSetEmailFirstTime: false })
        this.isVisibleReviewGetUserEmailSuccess = true
      } else {
        this.updateUserData({ isSetEmailFirstTime: true })
        this.hideReviewGetUserEmailComponent()
      }

      this.updateUserData({ email })
      sendYaGoal(GET_USER_EMAIL_YA_GOALS.checkValidUserCodeSuccess)
    },
    hideReviewGetUserEmailComponent() {
      this.updateIsVisibleReviewGetUserEmail(false)
    },
    handleBackButtonClick() {
      this.hideReviewGetUserEmailComponent()

      sendYaGoal(GET_USER_EMAIL_YA_GOALS.clickDeclineButton)
    },
  },
}
</script>

<style lang="scss" scoped>
.review-window-get-email {
  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &_mobile {
    min-height: 100%;
    padding: 8px 8px 24px;
  }

  &:not(&_mobile) {
    padding: 24px 0;
  }

  &:not(&_mobile) &__inner {
    margin: 0 auto;
    max-width: 428px;
    min-height: 664px;
  }
}
</style>
