import { axiosClient, getCSRFToken } from 'utils'

/**
 * Проверяет личную историю в отзыве с помощью искусственного интеллекта.
 *
 * @param { Object } data
 * @param { Number } data.townId - id города
 * @param { String } data.comment - текст из поля `Ваша история`
 * @param { String } data.commentPlus - текст из поля `Понравилось`
 * @param { String } data.commentMinus - текст из поля `Не понравилось`
 *
 * @returns { Promise<{ isPersonalHistory: Boolean }> } - флаг, указывающий на то, имеется ли в отзыве личная история
 * */

function checkPersonalHistory(data) {
  return new Promise((resolve, reject) => {
    axiosClient({
      url: '/api/rates/recognize_personal_history/',
      method: 'POST',
      data: {
        town_id: data.townId,
        comment: data.comment,
        comment_plus: data.commentPlus,
        comment_minus: data.commentMinus,
      },
      headers: {
        'X-CSRFToken': getCSRFToken(),
      },
    })
      .then(response => resolve({
        isPersonalHistory: response.data.is_personal_history,
      }))
      .catch(({ response }) => reject(response))
  })
}

export default checkPersonalHistory
