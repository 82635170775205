import { mapState, mapActions } from 'pinia'
import { isEmptyObject, isProd } from 'utils'
import BrowserTab from 'modules/BrowserTab'
import BehavioralFactors from 'modules/BehavioralFactors'
import { getRatingFields } from 'modules/BehavioralFactors/functions'
import {
  API_PATHS,
  BROWSER_TABS_DATA,
  AUTHORIZATION_TOKEN,
} from 'modules/BehavioralFactors/constants'
import {
  useStarsRatingStore,
  useReviewPageDataStore,
  useReviewFormDataStore,
} from 'components/common/ReviewPage/stores'

const ReviewPageBehavioralFactors = {
  computed: {
    ...mapState(useReviewPageDataStore, {
      /**
       * Произведено переименование свойства `commonData` в `commonDataLocal` для избежания
       * конфликта с уже определённым props'ом `commonData` в миксине `ReviewPageContextProps`,
       * который используется совместно с текущим.
       * */
      commonDataLocal: 'commonData',
    }),
    ...mapState(useStarsRatingStore, [
      'ratingList',
    ]),
    ...mapState(useReviewPageDataStore, [
      'reviewObjectId',
      'reviewObjectType',
    ]),
  },
  mounted() {
    const sendPaths = isProd ? API_PATHS.prod : API_PATHS.stage
    const browserTabInstance = new BrowserTab({ key: BROWSER_TABS_DATA.global })
    const browserTabStorage = browserTabInstance.getInstanceStorage()

    const behavioralFactorsInstance = new BehavioralFactors({
      sendPaths,
      capture: true,
      uniqueId: `${this.reviewObjectType}-${this.reviewObjectId}`,
      requestConfig: {
        headers: {
          Authorization: AUTHORIZATION_TOKEN,
        },
      },
      includeFields: [
        'comment',
        'comment_plus',
        'comment_minus',
      ],
      defaultFieldsData: {
        current_year: [this.commonDataLocal.currentYear],
        current_month: [this.commonDataLocal.currentMonth],
      },
      abstractions: {
        starRate: '.review-rating-stars__star',
        cannotRateButton: '.cannot-rate-button',
      },
      beforeAddedEventData: args => {
        this.createRatingData(args)
      },
      beforeSendData: ({ data }) => {
        const sessionLast = data[data.length - 1]
        const storage = browserTabStorage.get()

        sessionLast.tabsInnerPages = storage ? storage.history : []
      },
      afterSendingData: () => {
        browserTabInstance.detach()
        browserTabStorage.remove()
      },
      afterCreatedStartedData: ({ session }) => {
        session.common.pageId = String(this.reviewObjectId)
        session.common.pageKey = this.commonDataLocal.reviewDataKey
        session.common.pageType = this.reviewObjectType
      },
    })

    this.updateBehavioralFactorsInstance(behavioralFactorsInstance)
  },
  methods: {
    ...mapActions(useReviewFormDataStore, [
      'updateBehavioralFactorsInstance',
    ]),
    createRatingData({ event, session, eventData }) {
      setTimeout(() => { // нужен, чтобы state обновился - иначе добавится предыдущее значение state
        const isOutsideClick = event.type !== 'click' && (eventData.abstraction !== 'starRate'
          || eventData.abstraction !== 'cannotRateButton')

        if (isOutsideClick) {
          return
        }

        session.rating = session.rating || {}

        if (isEmptyObject(session.rating)) {
          session.rating = getRatingFields(this.reviewObjectType)
        }

        const ratingId = event
          .target
          .closest('[data-rating-id]')
          ?.getAttribute('data-rating-id')

        if (!ratingId) {
          return
        }

        const { value, isRateAbsent } = this.ratingList.find(({ key }) => key === ratingId)

        session.rating[`id_${ratingId}`].push({
          position: isRateAbsent ? 0 : Number(value) - 3,
          timeStamp: Date.now(),
        })
      })
    },
  },
}

export default ReviewPageBehavioralFactors
