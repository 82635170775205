var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseAlert",
    {
      staticClass: "pa-6",
      attrs: {
        type: "green",
        icon: "ui-icon-car-prodoctorov",
        "data-qa": "review_page_pdmobile_banner",
      },
    },
    [
      _c("span", { staticClass: "ui-text ui-text_subtitle-1" }, [
        _vm._v("\n    ПроДокторов-мобиль\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }