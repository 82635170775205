import { TRIDENT } from 'constants/browserCores'

const { protocol, hostname } = window.location

export const MODULE_KEY = 'behavioral'

export const UNSUPPORTED_BROWSER_CORES = [
  TRIDENT,
]

export const OBSERVED_NODE_TYPES = {
  input: 'input',
  textarea: 'textarea',
}

export const SYMBOL_ACTIONS = {
  inserttext: 'add',
  delete: 'delete',
  paste: 'paste',
}

export const ERROR_MESSAGES = {
  requiredUniqueId: 'Необходимо передать "uniqueId"',
}

export const BROWSER_TABS_DATA = {
  inner: 'BehavioralTabs',
  global: 'BrowserTabGlobalObserver',
}

export const AUTHORIZATION_TOKEN = 'Token sW819cLE06m14ZTjPLSYsGLpAF6jUNCw3H6CkkgLEduNPdKrhVEHgRCB3eG3'

export const API_PATHS = {
  stage: [
    { path: `${protocol}//rate-checking.prodoctorov.com/frontend/save_interaction`, isManagingProgress: false },
    { path: `${protocol}//${hostname}/api/rates/check/`, isManagingProgress: true },
  ],
  prod: [
    { path: `${protocol}//rate.prodoctorov.ru/frontend/save_interactions/`, isManagingProgress: false },
    { path: `${protocol}//prodoctorov.ru/api/rates/check/`, isManagingProgress: true },
  ],
}
