var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "review-info-card",
      class: { "review-info-card_mobile": _vm.isMobile },
      attrs: { "data-qa": "review_info_card" },
    },
    [
      _vm.isVisibleImage
        ? _c("img", {
            class: {
              "review-info-card__image": true,
              "review-info-card__image_square": _vm.reviewObjectTypes.isDoctor,
              "review-info-card__image_rectangular":
                _vm.reviewObjectTypes.isLpu,
              "review-info-card__image_outlined":
                _vm.reviewObjectTypes.isDoctor,
            },
            attrs: {
              alt: _vm.title,
              src: _vm.imageSrc,
              "data-qa": "review_info_card_img",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          {
            staticClass: "ui-text ui-text_h6 ui-kit-color-text mb-2",
            attrs: { "data-qa": "review_info_card_title" },
          },
          [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "ui-text ui-text_body-2 ui-kit-color-text-secondary",
            attrs: { "data-qa": "review_info_card_subtitle" },
          },
          [_vm._v("\n      " + _vm._s(_vm.subtitle) + "\n    ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }