var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "review-rating-stars",
      attrs: { "data-qa": "review_rating" },
    },
    _vm._l(_vm.ratingList, function (rating) {
      return _c(
        "div",
        {
          key: rating.key,
          staticClass: "review-rating-stars__item",
          attrs: {
            "data-qa": "review_rating_item",
            "data-rating-id": rating.key,
            "data-rating-value": rating.value,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "review-rating-stars__title ui-text ui-text_body-1",
              class: [
                _vm.isErrorsVisible &&
                !rating.value &&
                (!rating.isRateAbsentAvailable ||
                  (rating.isRateAbsentAvailable && !rating.isRateAbsent)) &&
                !rating.hoveredValue
                  ? "ui-kit-color-text-error"
                  : "ui-kit-color-text",
              ],
              attrs: { "data-qa": "review_rating_title" },
            },
            [_vm._v("\n      " + _vm._s(rating.title) + "\n    ")]
          ),
          _vm._v(" "),
          _c("VRating", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !rating.isRateAbsent,
                expression: "!rating.isRateAbsent",
              },
            ],
            ref: "ratingRef",
            refInFor: true,
            staticClass: "mb-3 review-rating-stars__star",
            attrs: {
              size: "32",
              color: "uiKitRating",
              "icon-label": "Рейтинг {0} из {1}",
              "full-icon": "ui-icon-rating-star-filled",
              "empty-icon": "ui-icon-rating-star-outline",
              "data-qa": "review_rating_stars",
              hover: !_vm.isMobile,
              "background-color": rating.value
                ? "uiKitRating"
                : "uiKitTextInfo",
            },
            on: {
              input: function ($event) {
                return _vm.handleUpdateRating(rating)
              },
            },
            model: {
              value: rating.value,
              callback: function ($$v) {
                _vm.$set(rating, "value", $$v)
              },
              expression: "rating.value",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "review-rating-stars__label ui-text ui-text_body-1",
              class: `review-rating-stars__label_color_${_vm.getRatingLabelColor(
                rating
              )}`,
              attrs: { "data-qa": "review_rating_label" },
            },
            [_vm._v("\n      " + _vm._s(_vm.getRatingLabel(rating)) + "\n    ")]
          ),
          _vm._v(" "),
          rating.isRateAbsentAvailable
            ? _c(
                "div",
                {
                  staticClass:
                    "cannot-rate-button text-body-1 primary--text cursor-pointer mt-4",
                  on: {
                    click: function ($event) {
                      return _vm.handleCannotRateClick(rating)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.cannotRateText(rating)) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }