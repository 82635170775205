var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "review-window-success",
      class: { "review-window-success_mobile": _vm.isMobile },
      attrs: { "data-qa": "review_window_success" },
    },
    [
      _vm.submittingReviewData.isLoading
        ? _c("ReviewPreloader", { attrs: { text: "Отправляем..." } })
        : _vm.submittingReviewData.isSuccessSubmit
        ? _c(
            "div",
            { staticClass: "review-window-success__inner text-center" },
            [
              _c(
                "div",
                { class: { "my-auto": _vm.isMobile } },
                [
                  _c("VImg", {
                    staticClass: "flex-grow-0 mx-auto mb-4",
                    attrs: {
                      contain: "",
                      width: "200",
                      height: "200",
                      "data-qa": "review_window_success_img",
                      alt: "Хороший отзыв",
                      src: "/static/_v1/pd/mascots/review-success.svg",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "ui-text ui-kit-color-text mb-4",
                      class: {
                        "ui-text_h6": _vm.isMobile,
                        "ui-text_h5": !_vm.isMobile,
                      },
                      attrs: { "data-qa": "review_window_success_title" },
                    },
                    [_vm._v("\n        Отзыв отправлен\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ui-text ui-text_body-1 ui-kit-color-text mb-4",
                      attrs: { "data-qa": "review_window_success_description" },
                    },
                    [
                      _vm._v(
                        "\n        Проверим в течение 1-3 дней.\n        "
                      ),
                      _vm.userData.email
                        ? [
                            _vm.commonData.isReviewSourcePrs
                              ? [
                                  _c("div", { staticClass: "mb-2" }, [
                                    _vm._v(
                                      "\n              Сообщим о результате и пришлём промокод на почту\n\n              "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "ui-text ui-text_subtitle-1",
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.userData.email) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              : [
                                  _c("div", [
                                    _vm._v(
                                      "\n              О результате сообщим на почту\n            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mb-2 ui-text ui-text_subtitle-1",
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.userData.email) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ],
                            _vm._v(" "),
                            !_vm.userData.isSetEmailFirstTime
                              ? _c(
                                  "VBtn",
                                  {
                                    staticClass: "ui-kit-color-primary",
                                    attrs: { text: "", color: "primary" },
                                    on: { click: _vm.handleChangeEmailButton },
                                  },
                                  [
                                    _vm._v(
                                      "\n            Изменить почту\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : [
                            _vm.commonData.isReviewSourcePrs
                              ? [
                                  _c("div", [
                                    _vm._v(
                                      "\n              Когда опубликуем отзыв,\n            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "\n              пришлём промокод на скидку " +
                                        _vm._s(_vm.commonData.prsDiscount) +
                                        " " +
                                        _vm._s(_vm.currency.symbol) +
                                        ".\n            "
                                    ),
                                  ]),
                                ]
                              : [
                                  _vm._v(
                                    "\n            Отслеживать статус проверки можно в личном кабинете — МедТочке.\n          "
                                  ),
                                ],
                          ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  !_vm.commonData.isPdMobile
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "review-window-success__banner ui-text ui-text_body-1 mb-4",
                          attrs: { "data-qa": "review_window_success_banner" },
                        },
                        [
                          _c("p", { staticClass: "mb-2" }, [
                            _vm._v(
                              "\n          Если вы не были в этой клинике\n          и оставили отзыв по заказу клиники или\n          врача, сообщите нам об этом и получите вознаграждение\n        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/info/rate-fraud-free/",
                                target: "_blank",
                              },
                            },
                            [_vm._v("Подробнее")]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.isMobile
                ? _c(
                    "VBtn",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        depressed: "",
                        color: "primary",
                        target: "_blank",
                        "data-qa": "review_window_success_mtlink",
                        href: _vm.commonData.medtochkaUrlOnReview,
                      },
                    },
                    [_vm._v("\n      Посмотреть отзыв\n    ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "ui-text ui-text_body-2 ui-kit-color-text-secondary mb-4",
                  attrs: { "data-qa": "review_window_success_caption" },
                },
                [
                  _vm._v(
                    "\n      Благодаря вашему мнению пациенты сделают\n      правильный выбор. Спасибо!\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.isMobile
                ? _c("BottomPanel", {
                    attrs: { fixed: "", "no-height": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "container",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "px-2 pt-2 pb-6" },
                                [
                                  _c(
                                    "VBtn",
                                    {
                                      attrs: {
                                        block: "",
                                        depressed: "",
                                        color: "primary",
                                        target: "_blank",
                                        "data-qa":
                                          "review_window_success_mtlink",
                                        href: _vm.commonData
                                          .medtochkaUrlOnReview,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            Посмотреть отзыв\n          "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3328781899
                    ),
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }