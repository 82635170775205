<template>
  <div
    class="d-flex flex-column align-center justify-center mx-auto"
    :style="{
      minHeight: isMobile ? '' : '664px',
      maxWidth: isMobile ? '' : '428px',
    }"
    data-qa="review_info_screen"
  >
    <div>
      <VImg
        contain
        width="150"
        height="150"
        class="mx-auto"
        alt="Иконка ошибки"
        src="/static/_v1/pd/pictures/ui-kit/cancel.svg"
        data-qa="review_info_screen_img"
      />
      <div
        v-if="title"
        class="ui-text ui-text_h6 ui-kit-color-text text-center mt-2"
        data-qa="review_info_screen_title"
      >
        {{ title }}
      </div>
      <div
        v-if="subtitle"
        class="ui-text ui-text_body-1 ui-kit-color-text-secondary text-center mt-2"
        data-qa="review_info_screen_subtitle"
      >
        {{ subtitle }}
      </div>
      <div
        v-if="!isMobile"
        class="d-flex flex-column align-center justify-center mt-6"
        data-qa="review_info_screen_actions"
      >
        <VBtn
          v-if="confirmButtonText"
          depressed
          color="primary"
          data-qa="review_info_screen_btn_confirm"
          @click="handleConfirmButtonClick"
        >
          {{ confirmButtonText }}
        </VBtn>
        <VBtn
          v-if="closeButtonText"
          text
          depressed
          color="primary"
          :class="{ 'mt-2': confirmButtonText }"
          data-qa="review_info_screen_btn_close"
          @click="handleCloseButtonClick"
        >
          {{ closeButtonText }}
        </VBtn>
      </div>
      <BottomPanel
        v-if="isMobile"
        fixed
      >
        <template #container>
          <div
            class="px-2 pb-6"
            data-qa="review_info_screen_actions"
          >
            <VBtn
              v-if="confirmButtonText"
              block
              depressed
              color="primary"
              data-qa="review_info_screen_btn_confirm"
              @click="handleConfirmButtonClick"
            >
              {{ confirmButtonText }}
            </VBtn>
            <VBtn
              v-if="closeButtonText"
              text
              block
              depressed
              color="primary"
              data-qa="review_info_screen_btn_close"
              :class="{ 'mt-2': confirmButtonText }"
              @click="handleCloseButtonClick"
            >
              {{ closeButtonText }}
            </VBtn>
          </div>
        </template>
      </BottomPanel>
    </div>
  </div>
</template>

<script>
import BottomPanel from 'components/common/core/BottomPanel/BottomPanel'

export default {
  name: 'ReviewPageInfoScreen',
  components: {
    BottomPanel,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    closeButtonText: {
      type: String,
      default: '',
    },
    confirmButtonText: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isMobile: window.MOBILE_VERSION,
  }),
  methods: {
    handleConfirmButtonClick() {
      this.$emit('screen-info:click-button-confirm')
    },
    handleCloseButtonClick() {
      this.$emit('screen-info:click-button-close')
    },
  },
}
</script>
