var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      attrs: {
        "data-is-exist-visible-alert": _vm.isExistsVisibleAlert,
        "data-qa": "review_textarea_alerts",
      },
    },
    [
      _c("VExpandTransition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.inputData.selectedPatient,
                expression: "inputData.selectedPatient",
              },
            ],
          },
          [
            _c(
              "BaseAlert",
              {
                staticClass: "mb-2 py-2",
                attrs: {
                  type: "grey",
                  icon: "ui-icon-create",
                  "data-qa": "review_alert_who_was_patient",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "ui-text ui-text_body-2" },
                  [
                    _vm.reviewObjectTypes.isDoctor
                      ? [
                          _vm.inputData.selectedPatient ===
                          _vm.PATIENT_TYPE_NAME.personally
                            ? [
                                _vm._v(
                                  "\n              Пишите от первого лица: «Я обращался...,\n              врач внимательно изучил мои анализы...».\n            "
                                ),
                              ]
                            : [
                                _vm._v(
                                  "\n              Укажите в отзыве, кем вам приходится\n              пациент: «были на приёме с сыном», «к\n              врачу обращалась моя мама».\n            "
                                ),
                              ],
                        ]
                      : [
                          _vm.inputData.selectedPatient ===
                          _vm.PATIENT_TYPE_NAME.personally
                            ? [
                                _vm._v(
                                  "\n              Пишите от первого лица: «Я обратился...,\n              мне помогли...».\n            "
                                ),
                              ]
                            : [
                                _vm._v(
                                  "\n              Укажите в отзыве, кем вам приходится\n              пациент: «были в клинике с сыном», «в\n              клинике проходила лечение моя мама».\n            "
                                ),
                              ],
                        ],
                  ],
                  2
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("VExpandTransition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showPersonalHistoryAlert,
                expression: "showPersonalHistoryAlert",
              },
            ],
          },
          [
            _c(
              "BaseAlert",
              {
                staticClass: "mb-2 py-2",
                attrs: {
                  type: "yellow",
                  icon: "ui-icon-circle-warning",
                  "data-qa": "review_alert_personal_history",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "ui-text ui-text_body-2" },
                  [
                    _vm.reviewObjectTypes.isDoctor
                      ? [
                          _vm._v(
                            "\n            Расскажите, почему обратились к врачу,\n            как прошёл приём, помогло ли лечение.\n            "
                          ),
                          _c("div", {
                            staticClass:
                              "text-decoration-underline cursor-pointer mt-2",
                            domProps: {
                              textContent: _vm._s(
                                "Примеры отзывов с личной историей"
                              ),
                            },
                            on: {
                              click: function ($event) {
                                _vm.isEnabledDialogHistoryExamples = true
                              },
                            },
                          }),
                        ]
                      : [
                          _vm._v(
                            "\n            Расскажите, как обратились в клинику, как\n            прошёл визит, понравился ли сервис и\n            отношение персонала.\n            "
                          ),
                          _c("div", {
                            staticClass:
                              "text-decoration-underline cursor-pointer mt-2",
                            domProps: {
                              textContent: _vm._s(
                                "Примеры отзывов с личной историей"
                              ),
                            },
                            on: {
                              click: function ($event) {
                                _vm.isEnabledDialogHistoryExamples = true
                              },
                            },
                          }),
                        ],
                  ],
                  2
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("VExpandTransition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.fastValidationData.isFoundBadWords,
                expression: "fastValidationData.isFoundBadWords",
              },
            ],
          },
          [
            _c(
              "BaseAlert",
              {
                staticClass: "mb-2 py-2",
                attrs: {
                  type: "red",
                  icon: "ui-icon-circle-warning",
                  "data-qa": "review_alert_bad_words",
                },
              },
              [
                _c("div", { staticClass: "ui-text ui-text_body-2 mb-2" }, [
                  _vm._v(
                    "\n          Мы нашли ругательства в тексте.\n          Удалите или замените их и нажмите «Далее».\n          Мы проверяем отзывы перед публикацией.\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "b-link b-link_underline b-link_color_coral ui-text ui-text_body-2 d-inline",
                    attrs: {
                      type: "button",
                      "data-qa": "review_alert_bad_words_button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.updateFastValidationData({
                          isFoundBadWords: false,
                          isUserAgreeNonBadWords: true,
                        })
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          Всё в порядке, ругательств нет\n        "
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("VExpandTransition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isVisibleAlertLengthNeed,
                expression: "isVisibleAlertLengthNeed",
              },
            ],
          },
          [
            _c(
              "BaseAlert",
              {
                staticClass: "mb-2 py-2",
                attrs: {
                  type: "red",
                  icon: "ui-icon-circle-warning",
                  "data-qa": "review_alert_error_length",
                },
              },
              [
                _c("div", { staticClass: "ui-text ui-text_body-2" }, [
                  _vm._v("\n          Отзыв слишком короткий."),
                  _c("br"),
                  _vm._v(
                    "\n          Не хватает символов: " +
                      _vm._s(_vm.reviewTextLengthNeed) +
                      "\n        "
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("ReviewPersonalHistoryList", {
        attrs: { "show-dialog": _vm.isEnabledDialogHistoryExamples },
        on: {
          "review-personal:close": function ($event) {
            _vm.isEnabledDialogHistoryExamples = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }