import { axiosClient, getCSRFToken } from 'utils'

function requestDocumentRecognition(requestData, onUploadProgress) {
  return axiosClient({
    method: 'POST',
    url: '/api/rates/check/document/',
    data: requestData,
    headers: {
      'X-CSRFToken': getCSRFToken(),
    },
    onUploadProgress,
  })
    .then(({ data }) => data)
}

export default requestDocumentRecognition
