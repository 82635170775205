import { scrollToElement } from 'utils'

/**
 * @description Производит скролл до первого невалидного поля с учётом алертов
 *
 * @param { Object } state - состояние Pinia-модуля storeReviewFormData.js
 * @param { Array<Vue> } state.reviewFormFieldRefs - массив полей
 * @param { Object } state.fastValidationData - данные быстрой валидации
 * */

function scrollToInvalidField({ reviewFormFieldRefs, fastValidationData } = {}) {
  const invalidFieldRef = reviewFormFieldRefs.find(({ valid }) => !valid)

  if (!invalidFieldRef && !fastValidationData.isFoundBadWords) {
    return
  }

  const isTextareaInvalid = invalidFieldRef?.$el.classList.contains('v-textarea') || fastValidationData.isFoundBadWords

  if (isTextareaInvalid) {
    const textareaAlertElement = document.querySelector('[data-is-exist-visible-alert]')

    scrollToElement(textareaAlertElement || invalidFieldRef?.$el)

    return
  }

  scrollToElement(invalidFieldRef?.$el)
}

export default scrollToInvalidField
