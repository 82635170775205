<template>
  <div data-qa="review_images">
    <div
      class="ui-text ui-text_subtitle-1 ui-kit-color-text mb-4"
      data-qa="review_images _title"
    >
      Прикрепите к отзыву
    </div>
    <VExpansionPanels
      class="mb-4"
      multiple
    >
      <VExpansionPanel>
        <VExpansionPanelHeader
          class="p-4"
          expand-icon="ui-icon-arrow-down"
        >
          <div class="mr-4">
            <div class="ui-text ui-text_subtitle-1 ui-kit-color-text">
              Документы из клиники
            </div>
            <div class="ui-text ui-text_body-1 ui-kit-color-text-secondary">
              подтвердят честность отзыва
            </div>
          </div>
        </VExpansionPanelHeader>
        <VExpansionPanelContent class="mx-n2">
          <div class="d-flex align-items-center ui-text ui-text_body-2 ui-kit-color-text mb-3">
            <span class="ui-icon-eye-close ui-icon_fz_medium mr-2" />
            Увидят только модераторы
          </div>
          <div class="ui-text ui-text_body-1 ui-kit-color-text mb-3">
            Заключение, рецепт или другой документ с&nbsp;фамилией врача и печатью.
            Имя пациента можно прикрыть.
          </div>
          <BannerInfo
            v-if="commonData.isRussiaDomain"
            :inner-atom-indent="0"
          >
            <span class="ui-text ui-text_body-2 ui-kit-color-text">
              Если врач или клиника решат оспорить отзыв в&nbsp;суде,
              мы&nbsp;защитим вас и возместим расходы.
            </span>
            <ReviewProtectionProgram>
              <template #activator="{ on, attrs }">
                <button
                  type="button"
                  class="d-inline b-link b-link_underline b-link_color_primary-blue"
                  data-qa="review_documents_button"
                  v-bind="attrs"
                  v-on="on"
                >
                  О программе защиты
                </button>
              </template>
            </ReviewProtectionProgram>
          </BannerInfo>
        </VExpansionPanelContent>
      </VExpansionPanel>
      <VExpansionPanel>
        <VExpansionPanelHeader
          class="p-4"
          expand-icon="ui-icon-arrow-down"
        >
          <div class="mr-4">
            <div class="ui-text ui-text_subtitle-1 ui-kit-color-text">
              Фото
            </div>
            <div class="ui-text ui-text_body-1 ui-kit-color-text-secondary">
              сделают отзыв интереснее
            </div>
          </div>
        </VExpansionPanelHeader>
        <VExpansionPanelContent class="mx-n2">
          <div class="d-flex align-items-center ui-text ui-text_body-2 ui-kit-color-text mb-3">
            <span class="ui-icon-eye-open ui-icon_fz_medium mr-2" />
            Увидят все
          </div>
          <div class="ui-text ui-text_body-1 ui-kit-color-text">
            Результаты лечения, до&nbsp;и&nbsp;после — всё, что&nbsp;проиллюстрирует ваш отзыв.
          </div>
        </VExpansionPanelContent>
      </VExpansionPanel>
    </VExpansionPanels>
    <FileUploader
      activator-text="Прикрепить документ или фото"
      activator-disabled-text="Нельзя прикрепить больше 10 файлов"
      :picker-props="{
        allowedFileExtensions,
        fileValidationRules,
        imageValidationRules,
        loadFileError: imagesErrors[FILE_ERROR_TYPES.uploadPhoto],
      }"
      :max-attached-files="imagesOptions.maxAttachedImages"
      :upload-handler="requestDocumentRecognition"
      :preview-statuses-options="attachedImagesStatuses"
      :init-attached-files="attachedImages"
      :use-load-from-camera="isMobile"
      :min-count-preview-images-on-line="3"
      :preview-image-max-width="138"
      :preview-image-max-height="138"
      preview-image-width="auto"
      preview-image-height="auto"
      min-height="120"
      use-load-from-gallery
      is-top-picker
      @file-uploader:add-file="addAttachedImage"
      @file-uploader:delete-file="deleteAttachedImage"
      @file-uploader:upload-pending="handleUploadPending"
      @file-uploader:upload-progress="handleUploadProgress"
      @file-uploader:upload-complete="handleUploadComplete"
      @file-uploader:upload-success="handleUploadSuccess"
      @file-uploader:upload-error="handleUploadError"
    />
  </div>
</template>

<script>
import { createDevNotice } from 'utils'
import { mapState, mapActions } from 'pinia'
import BannerInfo from 'components/common/core/BannerInfo'
import ReviewProtectionProgram from 'components/common/ReviewPage/components/common/ReviewProtectionProgram'
import FileUploader from 'components/common/FileUploader/components/common/FileUploader'
import { FILE_ERROR_TYPES, REVIEW_IMAGE_STATUSES } from 'components/common/ReviewPage/constants'
import { requestDocumentRecognition } from 'components/common/ReviewPage/api'
import { useReviewPageDataStore, useReviewImagesStore } from 'components/common/ReviewPage/stores'

export default {
  name: 'ReviewSectionImages',
  components: {
    FileUploader,
    ReviewProtectionProgram,
    BannerInfo,
  },
  data: () => ({
    FILE_ERROR_TYPES,
    isMobile: window.MOBILE_VERSION,
  }),
  computed: {
    ...mapState(useReviewPageDataStore, [
      'commonData',
      'reviewObjectType',
    ]),
    ...mapState(useReviewImagesStore, [
      'attachedImages',
      'attachedImagesStatuses',
      'imagesOptions',
      'fileValidationRules',
      'imageValidationRules',
      'allowedFileExtensions',
      'imagesErrors',
    ]),
  },
  methods: {
    ...mapActions(useReviewImagesStore, [
      'addAttachedImage',
      'deleteAttachedImage',
      'updateAttachedImage',
      'updateAttachedImagesStatus',
      'updateAttachedImagesStatuses',
    ]),
    async requestDocumentRecognition({ file }, onUploadProgress) {
      const requestData = new FormData()

      requestData.append('key', this.commonData.reviewDataKey)
      requestData.append('rate_type', this.reviewObjectType)
      requestData.append('image', file, file.name)

      return requestDocumentRecognition(requestData, onUploadProgress)
    },
    handleUploadPending({ fileId }) {
      this.updateAttachedImage({ fileId, isSending: true })
      this.updateAttachedImagesStatuses({
        fileId,
        status: REVIEW_IMAGE_STATUSES.uploadPending,
      })
    },
    handleUploadProgress({ fileId, progress }) {
      this.updateAttachedImagesStatus({
        fileId,
        progressValue: progress,
      })
    },
    handleUploadComplete({ fileId }) {
      this.updateAttachedImagesStatuses({
        fileId,
        status: REVIEW_IMAGE_STATUSES.analyzingDocument,
      })
    },
    handleUploadSuccess({ fileId, data }) {
      const { recognised_as_document: isDocument } = data

      this.updateAttachedImage({
        fileId,
        isDocument: !!isDocument,
        isSending: false,
      })
      this.updateAttachedImagesStatuses({
        fileId,
        status: isDocument
          ? REVIEW_IMAGE_STATUSES.isDocument
          : REVIEW_IMAGE_STATUSES.isNotDocument,
      })
    },
    handleUploadError({ fileId, error }) {
      this.updateAttachedImage({ fileId, isSending: false })
      this.updateAttachedImagesStatuses({
        fileId,
        status: REVIEW_IMAGE_STATUSES.uploadError,
      })

      // Проверяем, что ошибка возникла не из-за отсутствия интернета у пользователя
      if (navigator.onLine) {
        createDevNotice({
          module: 'ReviewSectionImages',
          method: 'handleUploadError',
          description: error.message,
        })
      }
    },
  },
}
</script>
