import { axiosClient, getCSRFToken } from 'utils'

/**
 * Проверяет валидность указанного пользователем кода для подтверждения e-mail
 * */

function checkValidUserCode({ email, code } = {}) {
  return new Promise((resolve, reject) => {
    axiosClient({
      url: '/api/user/email/verify/',
      method: 'POST',
      headers: {
        'X-CSRFToken': getCSRFToken(),
        'Content-Type': 'application/json',
      },
      data: { email, code },
    })
      .then(res => resolve(res.data))
      .catch(error => {
        reject(error)
      })
  })
}

export default checkValidUserCode
