import { OBSERVED_NODE_TYPES } from 'modules/BehavioralFactors/constants'

/**
 * @description
 *
 * Функция проверяет, принадлежит ли целевой элемент наблюдаемым узлам
 *
 * @param { HTMLElement } target
 *
 * @return { boolean }
 * */

function hasClosestObservedNode(target) {
  return Object.values(OBSERVED_NODE_TYPES).some(nodeType => target.closest(nodeType))
}

export default hasClosestObservedNode
